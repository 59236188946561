import { FormikConfig } from 'formik';
import { FormikHelpers } from 'formik/dist/types';

export enum MODE {
  CARE_UNIT,
  CARE_PROVIDER,
}

export enum INHERITANCE_STATE {
  INHERITED_FROM_CARE_PROVIDER,
  SPECIFIC_PENDING,
  SPECIFIC_SAVED,
}

interface OpeningHoursBase {
  openTime: string | null;
  closeTime: string | null;
  closedAllDay: boolean;
}

export interface OpeningHoursRegularDay extends OpeningHoursBase {
  day: string;
}

export interface OpeningHoursSpecificDay extends OpeningHoursBase {
  startDate: string;
  endDate: string;
}

export interface OpeningHoursRegular {
  detailedRegularHours: OpeningHoursRegularDay[];
  regularHoursInheritedByParent: boolean;
}
export interface OpeningHoursSpecial {
  detailedSpecialHourPeriods: OpeningHoursSpecificDay[];
  specialHoursInheritedByParent: boolean;
}

export interface OpeningHoursWrappers {
  detailedRegularHoursWrapper: OpeningHoursRegular;
  detailedSpecialHourPeriodsWrapper: OpeningHoursSpecial;
}

export interface OpeningHoursResponse {
  release: OpeningHoursWrappers;
  candidate: OpeningHoursWrappers | null;
}

export interface OpeningHoursTypeForUpdate {
  detailedRegularHoursWrapper: OpeningHoursRegular | null;
  detailedSpecialHourPeriodsWrapper: OpeningHoursSpecial | null;
}

export type OnSubmitType = <T extends OpeningHoursRegular | OpeningHoursSpecial>(
  values: T,
  formikHelpers: FormikHelpers<T>
) => ReturnType<FormikConfig<T>['onSubmit']>;

export interface CareUnitIdAndName {
  id: string;
  name: string;
}

export interface CareProviderInheritanceStatus {
  careUnitsWithInheritedRegularHours: CareUnitIdAndName[];
  careUnitsWithInheritedSpecialHours: CareUnitIdAndName[];
  careUnitsWithoutInheritance: CareUnitIdAndName[];
}

export interface OpeningHoursForDateMap {
  [key: string]: OpeningHoursForDateApiResponseItem[];
}
export interface OpeningHoursForDateApiResponseItem {
  careUnitId: string;
  careUnitName: string;
  status: OpeningStatus;
  openTime: string | null;
  closeTime: string | null;
  regularHoursInheritedByParent: boolean;
  specialHoursInheritedByParent: boolean;
}

export interface OpeningHoursForDate {
  careUnitId: string;
  careUnitName: string;
  openDate: Date | null;
  closeDate: Date | null;
  status: OpeningStatus;
  isConnected: boolean;
  isSpecial: boolean;
}

export enum OpeningStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

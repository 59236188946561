import {
  BranchesOutlined,
  FolderViewOutlined,
  MobileOutlined,
  PicLeftOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { useAtomValue } from 'jotai';
import { FormattedMessage } from 'react-intl';
import { styled } from 'styled-components';

import { ReactComponent as HealthcareOutlined } from 'assets/svg/HealthcareOutlined.svg';
import { useRootStore } from 'context/RootStoreContext';
import {
  canActivateContent24,
  canActivateCareUnits,
  canActivatePractitioners,
  canActivateOrigins,
  canViewRules,
  canViewCarePathways,
} from 'routes/routeGuards';
import { userPermissionsAtoms } from 'state/userPermissions';
import { isEducationEnv } from 'utils/appUtils';

import { Content24Submenu } from './Content24Submenu';
import { MainNavigationMenuItem } from '../MainNavigationMenuItem';

export const MainMenuPages = () => {
  const isLoaded = useAtomValue(userPermissionsAtoms.isLoaded);

  const { userPermissionsStore } = useRootStore();

  const IS_EDUCATIONAL_ENV = isEducationEnv();

  if (!isLoaded) {
    return null;
  }

  return (
    <>
      {canActivateCareUnits(userPermissionsStore) && !IS_EDUCATIONAL_ENV && (
        <MainNavigationMenuItem
          data-testid="care-providers-menu-item"
          icon={<OrganizationIcon data-testid="main-navigation-care-providers" />}
          linkTo="/my-health-care-organizations"
          tabIndex={0}
          role="menuitem"
          label={<FormattedMessage id="health-care" />}
        />
      )}

      {canActivateOrigins(userPermissionsStore) && !IS_EDUCATIONAL_ENV && (
        <MainNavigationMenuItem
          data-testid="origins-menu-item"
          icon={<MobileOutlined data-testid="main-navigation-origins" />}
          linkTo="/origins"
          tabIndex={0}
          role="menuitem"
          label={<FormattedMessage id="main-navigation.patient-apps" />}
        />
      )}

      {canActivatePractitioners(userPermissionsStore) && (
        <MainNavigationMenuItem
          data-testid="users-menu-item"
          icon={<TeamOutlined data-testid="main-navigation-practitioners" />}
          linkTo="/roles"
          tabIndex={0}
          role="menuitem"
          label={<FormattedMessage id="main-navigation.practitioners" />}
        />
      )}

      {canActivateContent24(userPermissionsStore) && !IS_EDUCATIONAL_ENV && (
        <MainNavigationMenuItem
          data-testid="main-navigation-content24"
          icon={<PicLeftOutlined />}
          antiLabel={<FormattedMessage id="main-navigation.content24" />}
          tabIndex={0}
          role="menuitem"
          subMenu={<Content24Submenu />}
        />
      )}

      {canViewRules(userPermissionsStore) && !IS_EDUCATIONAL_ENV && (
        <MainNavigationMenuItem
          data-testid="rules-menu-item"
          icon={<FolderViewOutlined data-testid="main-navigation-rules" />}
          linkTo="/rules"
          tabIndex={0}
          role="menuitem"
          label={<FormattedMessage id="main-navigation.rules" />}
        />
      )}

      {canViewCarePathways(userPermissionsStore) && !IS_EDUCATIONAL_ENV && (
        <MainNavigationMenuItem
          data-testid="care-pathways-menu-item"
          icon={<BranchesOutlined data-testid="main-navigation-carePathways" />}
          linkTo="/carePathways"
          tabIndex={0}
          role="menuitem"
          label={<FormattedMessage id="main-navigation.carePathways" />}
        />
      )}
    </>
  );
};

const OrganizationIcon = styled(HealthcareOutlined)`
  width: 18px;
  height: 18px;
`;

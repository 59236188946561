import { MobileOutlined } from '@ant-design/icons';
import { ManagePage, useRouterSubmenu } from '@platform24/admin-ui-utils';
import { notification } from 'antd';
import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';
import { WrappedComponentProps, FormattedMessage, useIntl } from 'react-intl';
import { Route, Switch, RouteComponentProps, Redirect } from 'react-router-dom';

import { AppBreadcrumbItem } from 'components/Breadcrumbs';
import { DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT } from 'constants/general';
import { useRootStore } from 'context/RootStoreContext';
import PartnerStatus from 'modules/PartnerStatus/PartnerStatus';

import Alerts from './Alerts';
import Customizations from './Customizations';
import { useSubMenu } from './hooks/useSubMenu';
import OfflineFlow from './OfflineFlow';
import OriginBasicDetails from './OriginBasicDetails';
import RootOriginBasicDetails from './RootOriginBasicDetails';
import Rules from './Rules';
import SearchTerms from './SearchTerms';
import Shutdown from './Shutdown';
import TextContents from './TextContents';

interface Props extends WrappedComponentProps, RouteComponentProps<{ originId: string }> {}

export const Origins = observer(({ match, history }: Props) => {
  const intl = useIntl();

  const originId = match.params.originId;
  const { partnersStore, userPermissionsStore, originStore, customizationsStore } = useRootStore();

  const breadcrumbs = useMemo((): AppBreadcrumbItem[] => {
    const breadcrumbs: AppBreadcrumbItem[] = [
      {
        text: <FormattedMessage id="main-navigation.patient-apps" />,
        icon: <MobileOutlined />,
        link: `/origins?expanded=${originId}`,
      },
      {
        text: originId,
      },
    ];

    return breadcrumbs;
  }, [originId]);

  useEffect(() => {
    const fetchOrigin = async (originId: string) => {
      try {
        await originStore.fetchOrigin(originId);
      } catch (e: any) {
        notification.error({
          placement: 'top',
          duration: DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT,
          message: intl.formatMessage({ id: 'origins.errors.not-found' }, { i: originId }),
        });

        history.push('/origins');
      }
    };

    if (originId) {
      fetchOrigin(originId);
    }
  }, [history, intl, originId, originStore]);

  useEffect(() => {
    return () => {
      customizationsStore.clearOriginCustomizations();
    };
  }, [customizationsStore]);

  const submenu = useSubMenu({ basePath: match.url });

  const subMenuR = useRouterSubmenu({ items: submenu });

  const { currentOrigin } = originStore;

  // Wait until origin data is loaded
  if (!currentOrigin) {
    return null;
  }
  const isRootOrigin = partnersStore.isRootOrigin(match.params.originId);
  const isSuperAdmin =
    userPermissionsStore.isSuperAdmin || userPermissionsStore.isNewRoleSuperAdmin;

  return (
    <>
      <ManagePage
        headerProps={{
          icon: <MobileOutlined />,
          iconBG: '#531DAB',
          title: (
            <span>
              <FormattedMessage id="access-scope.ORIGIN" />: {currentOrigin.name}
            </span>
          ),
          breadcrumbs: { data: breadcrumbs },
          topRight: <PartnerStatus />,
        }}
        subMenu={subMenuR}
      >
        <Switch>
          <Route
            path={`${match.path}/basic-details`}
            component={isRootOrigin ? RootOriginBasicDetails : OriginBasicDetails}
          />
          {isSuperAdmin && (
            <Route path={`${match.path}/customizations`} component={Customizations} />
          )}
          <Route path={`${match.path}/text-content`} component={TextContents} />
          <Route path={`${match.path}/alerts`} component={Alerts} />
          {isSuperAdmin && <Route path={`${match.path}/offline-flow`} component={OfflineFlow} />}
          <Route path={`${match.path}/shutdown-menu`} component={Shutdown} />
          {
            // TODO: enable when backend for https://platform24.atlassian.net/browse/AX-7711 is ready
            /*<Route
            path={`${match.path}/consents`}
            component={Consents}
          />*/
          }
          {isSuperAdmin && <Route path={`${match.path}/rules/:ruleName`} component={Rules} />}
          <Route path={`${match.path}/search-terms`} component={SearchTerms} />
          <Redirect from={match.path} to={`${match.path}/basic-details`} />
        </Switch>
      </ManagePage>
    </>
  );
});

export default Origins;

import { FilterOutlined } from '@ant-design/icons';
import { ManagePage, ManagePageHeader } from '@platform24/admin-ui-utils';
import { Button, Typography, Table, Select } from 'antd';
import { ColumnType } from 'antd/lib/table/interface';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Component, ContextType, Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import withRootStoreProp, { RootStoreProps } from 'components/HOC/withRootStoreProp';
import RootStoreContext from 'context/RootStoreContext';
import PartnerStatus from 'modules/PartnerStatus';

import ApplyFilterModal from './components/ApplyFilterModal';
import styles from './StatisticsList.module.css';
import StatisticsStore, { TranslatedStatistic, Filter } from './stores/StatisticsStore';
import { EXIT_ATTRIBUTES } from '../Condition/constants/code24types';

/**
 * @notExported
 */
interface StatisticsListProps extends RouteComponentProps, WrappedComponentProps, RootStoreProps {}

@observer
class StatisticsList extends Component<StatisticsListProps> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;
  statisticsStore: StatisticsStore;
  @observable isFilterVisible = false;

  constructor(props: StatisticsListProps) {
    super(props);

    this.statisticsStore = new StatisticsStore(props.rootStore, props.intl);
  }

  componentDidMount() {
    this.statisticsStore.initialize();
  }

  @computed
  get columns() {
    const { partnersStore } = this.context;
    const availableExitAttributes =
      partnersStore.partnerCustomizations.get('CODE24_AVAILABLE_EXIT_ATTRIBUTES') || [];
    const exitAttributesColumns: ColumnType<TranslatedStatistic>[] = [
      {
        title: <FormattedMessage id="condition-edit.urgency-label" />,
        dataIndex: EXIT_ATTRIBUTES.URGENCY,
        sorter: true,
      },
      {
        title: <FormattedMessage id="condition-edit.appointmentPriority-label" />,
        dataIndex: EXIT_ATTRIBUTES.APPOINTMENT_PRIORITY,
        align: 'center',
        sorter: true,
      },
      {
        title: <FormattedMessage id="condition-edit.levelOfCare-label" />,
        dataIndex: EXIT_ATTRIBUTES.LEVEL_OF_CARE,
        sorter: true,
      },
      {
        title: <FormattedMessage id="condition-edit.typeOfConsultation-label" />,
        dataIndex: EXIT_ATTRIBUTES.TYPE_OF_CONSULTATION,
        sorter: true,
      },
      {
        title: <FormattedMessage id="condition-edit.resource-label" />,
        dataIndex: EXIT_ATTRIBUTES.RESOURCE,
        sorter: true,
      },
      {
        title: <FormattedMessage id="condition-edit.capability-label" />,
        dataIndex: EXIT_ATTRIBUTES.CAPABILITY,
        sorter: true,
      },
    ];
    const columns: ColumnType<TranslatedStatistic>[] = [
      {
        title: <FormattedMessage id="general.condition" />,
        dataIndex: 'source',
        sorter: true,
      },
      {
        title: <FormattedMessage id="statistics.exit-id" />,
        dataIndex: 'id',
        sorter: true,
      },
      {
        title: <FormattedMessage id="statistics.received-exits" />,
        dataIndex: 'count',
        sorter: true,
        align: 'center',
      },
    ];
    const result = exitAttributesColumns.length
      ? columns.concat(
          exitAttributesColumns.filter(({ dataIndex }) =>
            availableExitAttributes.includes(dataIndex)
          )
        )
      : columns;

    return this.statisticsStore.sortWithDataPresentationOrder(result, 'dataIndex');
  }

  @action
  handleFilterSubmit = async (data: Filter) => {
    this.statisticsStore.handleApplyFilter(data);
    this.isFilterVisible = !this.isFilterVisible;
  };

  @action
  handleFilterToggle = () => {
    this.isFilterVisible = !this.isFilterVisible;
  };

  render() {
    const {
      handleDownloadClick,
      handleSortChange,
      reportOptions,
      isLoading,
      isLoadingReports,
      handleChangeVersion,
      activeReportId,
      sortedStatistics,
      handleAddNewReport,
      filter,
      statistics,
    } = this.statisticsStore;

    return (
      <ManagePage
        headerProps={{
          content: (
            <Fragment>
              <ManagePageHeader.Title>
                <FormattedMessage id="statistics.header" />
              </ManagePageHeader.Title>
              <Typography.Paragraph>
                <FormattedMessage id="statistics.description" />
              </Typography.Paragraph>
            </Fragment>
          ),
          pageActions: (
            <div className={styles.headerActions}>
              <Select
                disabled={isLoadingReports}
                options={reportOptions}
                value={activeReportId}
                dropdownRender={menu => (
                  <Fragment>
                    {menu}
                    <Button
                      type="link"
                      onClick={handleAddNewReport}
                      onMouseDown={e => e.preventDefault()}
                    >
                      + <FormattedMessage id="general.new" />
                    </Button>
                  </Fragment>
                )}
                className={styles.select}
                onChange={handleChangeVersion}
                loading={isLoadingReports}
                placeholder={<FormattedMessage id="condition.select-report" />}
              />
              <Button
                className={styles.headerAction}
                shape="round"
                onClick={this.handleFilterToggle}
                disabled={isLoading || !statistics.length}
              >
                <FilterOutlined className={styles.icon} />
                <FormattedMessage id="statistics.filter" />
              </Button>
              <Button
                className={styles.headerAction}
                shape="round"
                type="primary"
                onClick={handleDownloadClick}
                disabled={isLoading || !sortedStatistics.length}
              >
                <FormattedMessage id="statistics.export" />
              </Button>
            </div>
          ),
          topRight: <PartnerStatus />,
        }}
      >
        <Fragment>
          <Table
            columns={this.columns}
            rowKey="uuid"
            dataSource={sortedStatistics}
            pagination={{ pageSize: 200, showSizeChanger: false }}
            className={styles.table}
            loading={isLoading}
            onChange={handleSortChange}
            tableLayout="fixed"
          />
          <ApplyFilterModal
            isVisible={this.isFilterVisible}
            initialValues={filter}
            onSubmit={this.handleFilterSubmit}
            onClose={this.handleFilterToggle}
          />
        </Fragment>
      </ManagePage>
    );
  }
}

export default withRootStoreProp(injectIntl(StatisticsList));

import { Tabs } from 'antd';
import { useIntl } from 'react-intl';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';

import { getValueOfFeatureFlag } from 'utils/appUtils';

import OpeningHours from './OpeningHours';
import { useCurrentCareProviderId } from './openingHours.hooks';
import { MODE } from './openingHours.types';
import { OpeningHoursOverview } from './OpeningHoursOverview';

const OpeningHoursCareProvider = (props: RouteComponentProps<{ careProviderId: string }>) => {
  const careProviderId = useCurrentCareProviderId();
  const { formatMessage } = useIntl();
  const history = useHistory();
  const location = useLocation();
  const basePath = props.match.path;
  const baseUrl = props.match.url;
  const isOverviewEnabled = getValueOfFeatureFlag('FF_OPENING_HOURS_OVERVIEW_ENABLED');

  if (!careProviderId) return null;

  const items = [
    {
      key: 'edit',
      label: formatMessage({ id: 'Edit opening hours' }),
      path: 'edit',
    },
    {
      key: 'overview',
      label: formatMessage({ id: 'Opening hours overview' }),
      path: 'overview',
    },
  ];

  return (
    <>
      {isOverviewEnabled && (
        <Tabs
          activeKey={location.pathname.split('/').pop()}
          onChange={(key: string) => history.push(`${baseUrl}/${key}`)}
          items={items.map(({ key, label }) => ({
            key,
            label,
          }))}
        />
      )}

      <Switch>
        <Route exact path={basePath} render={() => <Redirect to={`${baseUrl}/edit`} />} />
        <Route
          path={`${basePath}/edit`}
          render={() => <OpeningHours id={careProviderId} mode={MODE.CARE_PROVIDER} />}
        />
        <Route path={`${basePath}/overview`} component={OpeningHoursOverview} />
      </Switch>
    </>
  );
};

export default OpeningHoursCareProvider;

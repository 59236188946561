export const ARTICLE_THUMBNAIL_FILE_PREFIX = 'artcl-thumb_';

export const ARTICLE_THUMBNAIL_SIZE = {
  width: 84,
  height: 166,
};

export const ARTICLE_CONTENT_IMAGE_SIZE = {
  width: 360,
};

export const API_MAX_UPLOAD_IMAGE_SIZE_IN_MB = 5;

import { useAtom } from 'jotai';
import { Observer } from 'mobx-react';
import React, { FunctionComponent, useContext } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import { Config } from 'constants/practitioner';
import RootStoreContext from 'context/RootStoreContext';
import { RoleInCareUnit } from 'modules/Practitioner/stores/PractitionerRolesStore';
import { partnerScopeCustomizationAtom } from 'modules/Roles/components/hooks/partnerScopeCustomizationAtom';
import { PractitionerRoleDefinition } from 'modules/Roles/components/types';
import { InputOption } from 'types/types';
import { filterSelectOptionV2 } from 'utils/formUtils';

import { PractitionerRoleFormModal } from './PractitionerRoleFormModal';
import { useCapabilities } from './useCapabilities';

interface Props {
  initialValues?: PractitionerRoleDefinition;
  isSaving: boolean;
  showRoleLock: boolean;
  onCancel: () => void;
  onSubmit: (data: PractitionerRoleDefinition) => void;
  careUnitsOptions: InputOption[];
  rolesOptions: InputOption[];
  notAvailableCareUnitRoles: RoleInCareUnit[];
}

type FilterFunc<T> = (inputValue: string, option?: T) => boolean;
type FilterOptionType = FilterFunc<Record<string, unknown>[][number]>;

const AddPractitionerRoles: FunctionComponent<Props> = ({
  isSaving,
  onCancel,
  initialValues,
  onSubmit,
  careUnitsOptions,
  rolesOptions,
  notAvailableCareUnitRoles,
  showRoleLock,
}) => {
  const intl = useIntl();
  const { rolesStore, practitionerRolesStore } = useContext(RootStoreContext);
  const [customizations] = useAtom(partnerScopeCustomizationAtom);
  const enableCapabilities = !!customizations[Config.ENABLE_ASSIGNING_CAPABILITIES_IN_MANAGE];
  const {
    data: capabilities,
    isLoading,
    // refetch: fetchCapabilities,
  } = useCapabilities({ enabled: enableCapabilities });

  const isAdministrativeRole =
    initialValues &&
    initialValues.role &&
    rolesStore.administrativeRoles.includes(initialValues?.role);

  const requiredErrorMessage = intl.formatMessage({
    id: 'general.errors.required',
  });
  const validationSchema = Yup.object().shape({
    careUnitIds: Yup.array().of(Yup.string()).required(requiredErrorMessage),
    role: Yup.string().required(requiredErrorMessage),
    capabilities:
      enableCapabilities && !isAdministrativeRole
        ? Yup.array().of(Yup.string()).required(requiredErrorMessage)
        : Yup.mixed().notRequired().nullable(),
  });

  if (!initialValues) {
    return null;
  }

  return (
    <Observer>
      {() => {
        const isAdministrativeRole =
          initialValues &&
          initialValues.role &&
          rolesStore.administrativeRoles.includes(initialValues?.role);
        const mappedExtendedRole = isAdministrativeRole
          ? practitionerRolesStore.extendedAdministrativeRolesMap
          : practitionerRolesStore.extendedPractitionerRolesMap;

        const title = isAdministrativeRole ? (
          <>
            {intl.formatMessage({
              id: 'add-roles-form.add-btn',
            })}
            &nbsp;&ndash;&nbsp;
            {intl.formatMessage({
              id: 'practitioner-roles-form.administrative-roles-header',
            })}
          </>
        ) : (
          <>
            {intl.formatMessage({ id: 'add-roles-form.add-btn' })}&nbsp;&ndash;&nbsp;
            {intl.formatMessage({
              id: 'practitioner-roles-form.practitioner-roles-header',
            })}
          </>
        );

        return (
          <PractitionerRoleFormModal
            title={<span data-testid="add-role-title">{title}</span>}
            formikInitialValues={
              {
                careUnitIds: [],
                role: undefined,
              } as PractitionerRoleDefinition
            }
            onSubmit={onSubmit}
            onCancel={onCancel}
            isSaving={isSaving}
            isLoading={isLoading}
            enableCapabilities={enableCapabilities}
            capabilities={capabilities}
            showRoleLock={showRoleLock}
            validationSchema={validationSchema}
            filterCareUnitOptions={filterSelectOptionV2 as FilterOptionType}
            careUnitsOptions={careUnitsOptions}
            mappedExtendedRole={mappedExtendedRole}
            isAdministrativeRole={isAdministrativeRole}
            notAvailableCareUnitRoles={notAvailableCareUnitRoles}
            rolesOptions={rolesOptions}
          />
        );
      }}
    </Observer>
  );
};

export default React.memo(AddPractitionerRoles);

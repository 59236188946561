import axios from 'axios';
import { format, parse } from 'date-fns';
import qs from 'qs';

import { isConnectedOpeningHours } from 'modules/CareUnits/OpeningHours/openingHours.utils';

import {
  CareProviderInheritanceStatus,
  OpeningHoursForDate,
  OpeningHoursResponse,
  OpeningHoursTypeForUpdate,
  OpeningHoursWrappers,
  OpeningHoursForDateMap,
  OpeningStatus,
} from '../modules/CareUnits/OpeningHours/openingHours.types';

function fixInheritanceStatusesFromCareProvider(data: OpeningHoursResponse) {
  // This is a workaround for the API not returning the correct inheritance status for care providers
  // It's a lot simpler to have those set to false rather than adding additional validation handling
  // to the forms.
  data.release.detailedRegularHoursWrapper.regularHoursInheritedByParent = false;
  data.release.detailedSpecialHourPeriodsWrapper.specialHoursInheritedByParent = false;
  if (data.candidate?.detailedRegularHoursWrapper) {
    data.candidate.detailedRegularHoursWrapper.regularHoursInheritedByParent = false;
  }
  if (data.candidate?.detailedSpecialHourPeriodsWrapper) {
    data.candidate.detailedSpecialHourPeriodsWrapper.specialHoursInheritedByParent = false;
  }
}

interface Ids {
  careUnitId?: string;
  careProviderId: string;
  partnerId: string;
}

function getPath({ careUnitId, careProviderId, partnerId }: Ids): string {
  const careUnitPath = careUnitId ? `careunits/${careUnitId}/` : '';
  return `/rest/admin/partners/${partnerId}/careproviders/${careProviderId}/${careUnitPath}opening-hours/v1`;
}

export const fetchOpeningHours = async (ids: Ids): Promise<OpeningHoursResponse> => {
  const response = await axios.get<OpeningHoursResponse>(getPath(ids));
  if (!ids.careUnitId) {
    fixInheritanceStatusesFromCareProvider(response.data);
  }
  return response.data;
};

export const setOpeningHours = async (ids: Ids, data: OpeningHoursTypeForUpdate) => {
  const response = await axios.put<OpeningHoursWrappers>(getPath(ids), data);
  return response.data;
};

export const fetchOpeningHoursInheritanceStatus = async (
  ids: Omit<Ids, 'careUnitId'>
): Promise<CareProviderInheritanceStatus> => {
  const response = await axios.get<CareProviderInheritanceStatus>(
    `${getPath(ids)}/inheritance-status`
  );
  return response.data;
};

export const fetchOpeningHoursForDate = async (
  ids: Omit<Ids, 'careUnitId'>,
  date: Date
): Promise<OpeningHoursForDate[]> => {
  const dateString = format(date, 'yyyy-MM-dd');
  const response = await axios.get<OpeningHoursForDateMap>(`${getPath(ids)}/care-units`, {
    // it is possible to pass list of dates
    // Axios automatically converts params: { dates: ['2024-02-01', '2024-02-02'] } to ?dates=2024-02-01&dates=2024-02-02
    // which is format that backend expects
    params: { dates: [dateString] },
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
  });

  // response looks like key value pair where key is date and value is array of OpeningHoursForDateApiResponseItem
  const dateData = response.data[dateString];

  if (!dateData) {
    return [];
  }

  return dateData.map(item => {
    return {
      careUnitId: item.careUnitId,
      careUnitName: item.careUnitName,
      openDate: item.openTime ? parse(item.openTime, 'HH:mm:ss', date) : null,
      closeDate: item.closeTime ? parse(item.closeTime, 'HH:mm:ss', date) : null,
      status: item.status === 'OPEN' ? OpeningStatus.OPEN : OpeningStatus.CLOSED,
      isConnected: isConnectedOpeningHours(item),
      isSpecial: false,
      // this is going to be uncommented for using new property returned from API after
      // https://platform24.atlassian.net/browse/AX-54111 is done
      // isSpecial: !!item.specialHoursInheritedByParent,
    };
  });
};

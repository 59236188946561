import { ManagePage, useRouterSubmenu } from '@platform24/admin-ui-utils';
import { observer } from 'mobx-react';
import { ComponentType, useMemo, useEffect, useCallback } from 'react';
import { injectIntl, WrappedComponentProps, FormattedMessage, useIntl } from 'react-intl';
import { RouteComponentProps, Switch, Route, useHistory } from 'react-router-dom';

import { ReactComponent as HospitalOutlined } from 'assets/svg/HospitalOutlined.svg';
import { DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT } from 'constants/general';
import { useRootStore } from 'context/RootStoreContext';
import { CareUnitAnnouncement } from 'modules/Announcements/careUnit/CareUnitAnnouncements';
import PartnerStatus from 'modules/PartnerStatus';
import { notification } from 'services/NotificationService';

import CareUnitBasicDetails from './CareUnitBasicDetails';
import Customizations from './Customizations';
import { useProviderAndUnitBreadcrambs } from './hooks/useProviderAndUnitBreadcrambs';
import { OpeningHoursCareUnit } from './OpeningHours';
import Phrases from './Phrases';

interface Props
  extends WrappedComponentProps,
    RouteComponentProps<{ careProviderId: string; careUnitId: string }> {}

export const CareUnits = observer(({ match }: Props) => {
  const basePath = '/my-health-care-organizations/:careProviderId/care-units/:careUnitId';

  const {
    params: { careProviderId, careUnitId },
  } = match;
  const { userDataStore, partnersStore, userPermissionsStore, careUnitStore, careUnitsStore } =
    useRootStore();
  const { userCareUnits } = userDataStore;
  const intl = useIntl();

  const history = useHistory();

  const routes = useMemo(() => {
    const isUsersCareUnit = userCareUnits.some(({ id }) => careUnitId === id);
    const routes: { testid: string; path: string; component: ComponentType<any> }[] = [
      {
        path: 'basic-details',
        component: CareUnitBasicDetails,
        testid: 'careunit-basic-details',
      },
    ];

    if (userPermissionsStore.isSuperAdmin || userPermissionsStore.isNewRoleSuperAdmin) {
      routes.push({
        path: 'customizations',
        component: Customizations,
        testid: 'careunit-customizations',
      });
    }

    if (isUsersCareUnit || careUnitStore.canBeEditedByMe) {
      if (partnersStore.partnerCustomizations.get('ENABLE_OPENING_HOURS_IN_ADMIN')) {
        routes.push({
          path: 'opening-hours',
          component: OpeningHoursCareUnit,
          testid: 'careunit-opening-hours',
        });
      }
      routes.push(
        {
          path: 'announcements',
          component: CareUnitAnnouncement,
          testid: 'careunit-announcements',
        },
        {
          path: 'phrases',
          component: Phrases,
          testid: 'careunit-phrases',
        }
      );
    }

    return routes;
  }, [
    userCareUnits,
    userPermissionsStore.isSuperAdmin,
    userPermissionsStore.isNewRoleSuperAdmin,
    careUnitStore.canBeEditedByMe,
    careUnitId,
    partnersStore.partnerCustomizations,
  ]);

  const br = useProviderAndUnitBreadcrambs(
    careProviderId,
    careUnitsStore.currentCareProvider?.name ?? careProviderId,
    careUnitStore.careUnit?.name ?? careUnitId
  );
  const handleNotFound = useCallback(() => {
    notification.error({
      placement: 'top',
      duration: DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT,
      message: intl.formatMessage(
        { id: 'care-units.errors.not-found' },
        { id: match.params.careUnitId }
      ),
    });

    history.push('/care-units');
  }, [history, intl, match.params.careUnitId]);

  useEffect(() => {
    careUnitStore.fetchCareUnit(careProviderId, careUnitId).catch(handleNotFound);
  }, [careProviderId, careUnitId, careUnitStore, handleNotFound]);

  useEffect(() => {
    careUnitsStore.fetchCareProvider(careProviderId).catch(handleNotFound);
  }, [careProviderId, careUnitsStore, handleNotFound]);

  useEffect(() => {
    // cleanup, global "dispose" method
    // TODO: move to the useEffect which loads function
    return () => careUnitStore.dispose();
  }, [careUnitStore]);

  const sideMenuLinks = useMemo(() => {
    return routes.map(({ path, testid }) => {
      const fullPath = `/my-health-care-organizations/${careProviderId}/care-units/${careUnitId}/${path}`;

      return {
        url: fullPath,
        id: fullPath,
        'data-testid': testid,
        label: intl.formatMessage({ id: `care-units.${path}` }),
      };
    });
  }, [routes, careProviderId, careUnitId, intl]);

  const subMenu = useRouterSubmenu({ items: sideMenuLinks });

  const { careUnit } = careUnitStore;

  if (careUnit === undefined) {
    return null;
  }

  return (
    <ManagePage
      headerProps={{
        title: (
          <span>
            <FormattedMessage id="care-units.care-unit" />: {careUnit.name}
          </span>
        ),
        breadcrumbs: br, //  { data: breadcrumbs },
        topRight: <PartnerStatus />,
        icon: <HospitalOutlined />,
        iconBG: '#0583A0',
      }}
      subMenu={subMenu}
    >
      <Switch>
        {routes.map(({ path, component }) => (
          <Route key={path} path={`${basePath}/${path}`} component={component} />
        ))}
      </Switch>
    </ManagePage>
  );
});

export default injectIntl(CareUnits);

import { Divider, Modal, Tooltip } from 'antd';
import { Formik } from 'formik';
import { Form, Select, Checkbox } from 'formik-antd';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';

import FormActionButtons from 'components/FormActionButtons';
import { ROLES } from 'constants/roles';
import { PractitionerRoleDefinition } from 'modules/Roles/components/types';
import { InputOption } from 'types/types';

import styles from './AddPractitionerRoles.module.css';

export interface PractitionerRoleFormModalProps {
  title: React.ReactNode;
  formikInitialValues: any;
  validationSchema: Yup.ObjectSchema;
  onSubmit: (data: PractitionerRoleDefinition) => void;
  onCancel: () => void;
  isSaving: boolean;
  isLoading: boolean;
  capabilities?: Array<{ label?: string | undefined; value: string }>;
  showRoleLock?: boolean;
  careUnitsOptions: InputOption[];
  filterCareUnitOptions?: any;
  isAdministrativeRole?: boolean;
  mappedExtendedRole: Record<string, any>;
  notAvailableCareUnitRoles: Array<{ role: string; careUnitId: string }>;
  rolesOptions: InputOption[];
  enableCapabilities?: boolean;
  disableCareUnitSelector?: boolean;
  disableRoleSelector?: boolean;
}

/**
 * Dumb component to represent form itself.
 * Reuses all resources provided
 * Enables having similar interface between adding role and editing role
 * While having different ways of obtaining props
 */
export const PractitionerRoleFormModal = ({
  title,
  formikInitialValues,
  onSubmit,
  validationSchema,
  isSaving,
  isLoading,
  careUnitsOptions,
  filterCareUnitOptions,
  mappedExtendedRole,
  isAdministrativeRole,
  notAvailableCareUnitRoles,
  rolesOptions,
  enableCapabilities,
  capabilities,
  showRoleLock,
  onCancel,
  disableCareUnitSelector,
  disableRoleSelector,
}: PractitionerRoleFormModalProps) => {
  return (
    <Modal open destroyOnClose footer={null} closable={false} title={title}>
      <Formik
        initialValues={formikInitialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ isValid, dirty, values }) => (
          <Form layout="vertical">
            <Form.Item
              name="careUnitIds"
              label={<FormattedMessage id="general.care-unit" />}
              required
            >
              <Tooltip
                title={<FormattedMessage id="general.field-cannot-be-edited" />}
                trigger={disableCareUnitSelector ? ['hover', 'focus'] : []}
              >
                <Select
                  name="careUnitIds"
                  disabled={isSaving || disableCareUnitSelector}
                  mode="multiple"
                  data-testid="careunit-select-input"
                  optionFilterProp="children"
                  showSearch
                  filterOption={filterCareUnitOptions}
                >
                  {careUnitsOptions.map(({ value, label }, index) => {
                    const disabled = isAdministrativeRole
                      ? mappedExtendedRole[`${values.role}:${value}`]
                      : mappedExtendedRole[value];

                    const picked = notAvailableCareUnitRoles.some(
                      ({ role, careUnitId }) => role === values.role && careUnitId === value
                    );

                    return (
                      <Select.Option
                        disabled={disabled || picked}
                        key={`${label}-${index}`}
                        value={value}
                      >
                        {disabled || picked ? (
                          <Tooltip
                            overlayClassName={styles.tooltipFix}
                            title={<FormattedMessage id="roles.role-already-assigned" />}
                          >
                            <div>{label}</div>
                          </Tooltip>
                        ) : (
                          label
                        )}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Tooltip>
            </Form.Item>

            <Form.Item
              name="role"
              label={<FormattedMessage id="practitioner-roles-form.role-label" />}
              required
            >
              <Tooltip
                title={<FormattedMessage id="general.field-cannot-be-edited" />}
                trigger={disableRoleSelector ? ['hover', 'focus'] : []}
              >
                <Select
                  name="role"
                  disabled={isSaving || disableRoleSelector}
                  optionFilterProp="children"
                  data-testid="role-select-input"
                  showSearch
                >
                  {(isAdministrativeRole
                    ? rolesOptions.filter(({ value }) => value !== ROLES.ADMIN)
                    : rolesOptions
                  ).map(({ value, label }, index) => {
                    const picked = notAvailableCareUnitRoles.some(
                      ({ role, careUnitId }) =>
                        role === value && values.careUnitIds.includes(careUnitId)
                    );

                    const disabled = values.careUnitIds.some((careUnitId: string) =>
                      isAdministrativeRole
                        ? mappedExtendedRole[`${value}:${careUnitId}`]
                        : mappedExtendedRole[careUnitId]
                    );

                    return (
                      <Select.Option
                        disabled={disabled || picked}
                        key={`${label}-${index}`}
                        value={value}
                      >
                        {disabled || picked ? (
                          <Tooltip
                            overlayClassName={styles.tooltipFix}
                            title={<FormattedMessage id="roles.role-already-assigned" />}
                          >
                            <div>{label}</div>
                          </Tooltip>
                        ) : (
                          label
                        )}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Tooltip>
            </Form.Item>

            {!isAdministrativeRole && enableCapabilities && (
              <Form.Item
                name="capabilities"
                label={<FormattedMessage id="practitioner-roles-form.capabilities-label" />}
                required
              >
                <Select
                  name="capabilities"
                  disabled={isSaving}
                  data-testid="role-select-input"
                  mode="multiple"
                  optionFilterProp="label"
                  showSearch
                  loading={isLoading}
                >
                  {capabilities?.map(({ value, label }, index: number) => {
                    return (
                      <Select.Option key={`${label}-${index}`} value={value}>
                        {label ?? value}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            )}
            <Divider />
            {showRoleLock && (
              <Form.Item
                name="lockedFromAutoManagement"
                data-testid="lockedFromAutoManagement"
                label={<FormattedMessage id="practitioner-roles-form.exemption-label" />}
              >
                <Checkbox name="lockedFromAutoManagement" />
              </Form.Item>
            )}
            <FormActionButtons
              isSaving={isSaving}
              isValid={isValid && dirty}
              onCancel={onCancel}
              showCancelConfirm={dirty}
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

import { Typography, Layout, Avatar, Flex, Select } from 'antd';
import { styled } from 'styled-components';

import { ReactComponent as HospitalOutlined } from 'assets/svg/HospitalOutlined.svg';
import PageHeader from 'components/PageHeader';

export const HO = styled(HospitalOutlined).attrs({
  width: 14,
  viewBox: '0 0 24 25',
})`
  margin-bottom: -8px;
  margin-right: 0.25rem;
`;

const { Content } = Layout;

export const PageWrap = styled(Layout)`
  background-color: var(--color-background);
`;

export const Content2 = styled(Content)`
  border-radius: ${6 / 16}rem;
  border: 1px solid #eee;
  background: #fff;
  box-sizing: border-box;
  padding: 1.5rem;
  max-width: ${800 / 16}rem;
`;

export const PageHeader2 = styled(PageHeader)`
  padding-left: 2rem;
  padding-top: 1.5rem;
  margin-bottom: 0 !important;
`;

export const Title = styled(Typography.Title).attrs({ level: 2 })`
  font-size: 1.25rem !important;
  line-height: 1.5rem !important;
  font-weight: 600 !important;
`;

export const ItemText = styled(Typography.Text)``;

export const ItemIcon = styled.div`
  margin: 0 0.5rem;
`;

export const RoleWrap = styled.div`
  margin-bottom: 0.5rem;
`;

export const RoleLabel = styled(Typography.Text)`
  color: #333;
  font-size: {12/16}rem;
  `;

export const RoleAt = styled.span`
  color: #999;
  margin: 0 0.5rem;
  font-size: 0.8rem;
`;

export const StyledAvatar = styled(Avatar)`
  background-color: #c41d7f;
`;

export const MyRoles = styled(Flex)`
  margin-left: 80px;
  margin-top: -20px;
`;

export const CustomSelect = styled(Select)`
  width: 300px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

import { Row, Col, Flex } from 'antd';
import { format } from 'date-fns';
import { FormikValues, FormikHelpers } from 'formik';
import { Form, InputNumber, Switch } from 'formik-antd';
import { FunctionComponent, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import DatePicker from 'components/DatePicker';
import {
  DATE_DISPLAY_FORMAT,
  DATE_INPUT_FORMAT,
  transformNumberToDoubleDigit,
  formatDate,
} from 'utils/dateUtils';

import styles from './StartEndDate.module.css';
import { MAX_TIME_VALUES } from '../../stores/AlertsStore';
import { FIELDS } from '../AddEditAlert';

interface Props {
  values: FormikValues;
  setFieldValue: FormikHelpers<FormikValues>['setFieldValue'];
}

const StartEndDate: FunctionComponent<Props> = ({ values, setFieldValue }) => {
  const minEndHour =
    values.enableEndDate && values.startDate === values.endDate ? values.startHour : 0;
  const minEndMinute =
    values.enableEndDate &&
    values.startDate === values.endDate &&
    values.startHour === values.endHour
      ? values.startMinute + 1
      : 0;

  useEffect(() => {
    const { startDate, endDate, startHour, endHour, startMinute, endMinute, enableEndDate } =
      values;

    if (!enableEndDate) return;

    if (startDate > endDate) {
      setFieldValue(FIELDS.END_DATE, startDate);
    }

    if (startDate === endDate) {
      if (startHour > endHour) {
        setFieldValue(FIELDS.END_HOUR, startHour);
      }

      if (startHour === endHour) {
        if (startMinute >= endMinute && startMinute < MAX_TIME_VALUES.MINUTES) {
          setFieldValue(FIELDS.END_MINUTE, startMinute + 1);
        }

        if (startMinute === MAX_TIME_VALUES.MINUTES) {
          setFieldValue(FIELDS.END_MINUTE, 0);

          if (endHour < MAX_TIME_VALUES.HOURS) {
            setFieldValue(FIELDS.END_HOUR, startHour + 1);
          } else {
            setFieldValue(FIELDS.END_HOUR, 0);

            const startingDate = new Date(startDate);
            const nextDay = format(
              new Date(startingDate.setDate(startingDate.getDate() + 1)),
              DATE_INPUT_FORMAT
            );
            setFieldValue(FIELDS.END_DATE, nextDay);
          }
        }
      }
    }
  }, [values, setFieldValue]);

  const handleStartDateChange = (date: Date) => {
    setFieldValue(FIELDS.START_DATE, formatDate(date));
  };

  const handleEndDateChange = (date: Date) => {
    setFieldValue(FIELDS.END_DATE, formatDate(date));
  };

  return (
    <>
      <div className={styles.header}>
        <FormattedMessage id="origin.alerts.schedule" />
      </div>

      <Row gutter={16}>
        <Col span={8}>
          <FormattedMessage id="origin.alerts.startDate" />
        </Col>
        <Col span={16}>
          <Row>
            <Col span={12}>
              <Flex justify="flex-end">
                <Form.Item
                  name={FIELDS.START_DATE}
                  label={<FormattedMessage id="origin.alerts.from" />}
                  layout="horizontal"
                  className={styles.alertItem}
                >
                  <DatePicker
                    allowClear={false}
                    minDate={new Date()}
                    value={values.startDate ? new Date(values.startDate) : new Date()}
                    name={FIELDS.START_DATE}
                    format={DATE_DISPLAY_FORMAT}
                    variant="filled"
                    onChange={date => {
                      handleStartDateChange(date);
                    }}
                  />
                </Form.Item>
              </Flex>
            </Col>
            <Col span={12}>
              <Flex justify="flex-end">
                <Form.Item
                  name={FIELDS.START_HOUR}
                  label={<FormattedMessage id="origin.alerts.at" />}
                  layout="horizontal"
                  className={styles.alertItem}
                >
                  <InputNumber
                    className={styles.input}
                    variant="filled"
                    name={FIELDS.START_HOUR}
                    min={0}
                    max={MAX_TIME_VALUES.HOURS}
                    formatter={transformNumberToDoubleDigit}
                  />
                  <InputNumber
                    className={styles.input}
                    variant="filled"
                    name={FIELDS.START_MINUTE}
                    min={0}
                    max={MAX_TIME_VALUES.MINUTES}
                    formatter={transformNumberToDoubleDigit}
                  />
                </Form.Item>
              </Flex>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            layout="horizontal"
            name={FIELDS.ENABLE_END_DATE}
            label={<FormattedMessage id="origin.alerts.endDate" />}
            className={styles.hiddenSwitch}
          >
            <Switch name={FIELDS.ENABLE_END_DATE} defaultChecked={values.enableEndDate} />
            <span className={styles.switchStatus}>
              {values[FIELDS.ENABLE_END_DATE] ? (
                <FormattedMessage id="origin.alerts.on" />
              ) : (
                <FormattedMessage id="origin.alerts.off" />
              )}
            </span>
          </Form.Item>
        </Col>
        <Col span={16}>
          <Row>
            <Col span={12}>
              <Flex justify="flex-end">
                <Form.Item
                  name={FIELDS.END_DATE}
                  label={<FormattedMessage id="origin.alerts.until" />}
                  layout="horizontal"
                  className={styles.alertItem}
                >
                  <DatePicker
                    allowClear={false}
                    minDate={new Date()}
                    value={values.endDate ? new Date(values.endDate) : new Date()}
                    name={FIELDS.END_DATE}
                    format={DATE_DISPLAY_FORMAT}
                    variant="filled"
                    onChange={date => {
                      handleEndDateChange(date);
                    }}
                    disabled={!values[FIELDS.ENABLE_END_DATE]}
                    disabledDate={current => formatDate(current) < values.startDate}
                  />
                </Form.Item>
              </Flex>
            </Col>
            <Col span={12}>
              <Flex justify="flex-end">
                <Form.Item
                  name={FIELDS.BODY}
                  label={<FormattedMessage id="origin.alerts.at" />}
                  layout="horizontal"
                  className={styles.alertItem}
                >
                  <InputNumber
                    className={styles.input}
                    variant="filled"
                    name={FIELDS.END_HOUR}
                    min={minEndHour}
                    max={MAX_TIME_VALUES.HOURS}
                    formatter={transformNumberToDoubleDigit}
                    disabled={!values[FIELDS.ENABLE_END_DATE]}
                  />
                  <InputNumber
                    className={styles.input}
                    variant="filled"
                    name={FIELDS.END_MINUTE}
                    min={minEndMinute}
                    max={MAX_TIME_VALUES.MINUTES}
                    formatter={transformNumberToDoubleDigit}
                    disabled={!values[FIELDS.ENABLE_END_DATE]}
                  />
                </Form.Item>
              </Flex>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default StartEndDate;

import { endOfDay, parse } from 'date-fns';
import { cloneDeep } from 'lodash';
import groupBy from 'lodash/groupBy';

import {
  OpeningHoursRegular,
  OpeningHoursRegularDay,
  OpeningHoursSpecial,
  OpeningHoursSpecificDay,
  OpeningHoursWrappers,
  OpeningHoursTypeForUpdate,
  OpeningHoursForDateApiResponseItem,
} from './openingHours.types';

export function isSpecialHours(
  values: OpeningHoursRegular | OpeningHoursSpecial
): values is OpeningHoursSpecial {
  return 'detailedSpecialHourPeriods' in values;
}

export function clearDayTimes<Day extends OpeningHoursRegularDay | OpeningHoursSpecificDay>(
  day: Day
): Day {
  return {
    ...day,
    openTime: day.closedAllDay ? null : day.openTime,
    closeTime: day.closedAllDay ? null : day.closeTime,
  };
}

export function getClonedDataForUpdate(data: OpeningHoursWrappers): OpeningHoursTypeForUpdate {
  const clonedData: OpeningHoursTypeForUpdate = cloneDeep(data);
  if (clonedData.detailedRegularHoursWrapper!.regularHoursInheritedByParent) {
    clonedData.detailedRegularHoursWrapper = null;
  } else {
    clonedData.detailedRegularHoursWrapper!.detailedRegularHours =
      clonedData.detailedRegularHoursWrapper!.detailedRegularHours.map(clearDayTimes);
  }

  if (clonedData.detailedSpecialHourPeriodsWrapper!.specialHoursInheritedByParent) {
    clonedData.detailedSpecialHourPeriodsWrapper = null;
  } else {
    clonedData.detailedSpecialHourPeriodsWrapper!.detailedSpecialHourPeriods =
      clonedData.detailedSpecialHourPeriodsWrapper!.detailedSpecialHourPeriods.map(clearDayTimes);
  }

  return clonedData;
}

export function isDayInFuture(day: Pick<OpeningHoursSpecificDay, 'endDate'>) {
  const now = new Date();
  return !day.endDate || endOfDay(parse(day.endDate, 'yyyy-MM-dd', new Date())) > now;
}

export function groupSpecialHours(openingHours: OpeningHoursSpecial) {
  return groupBy(openingHours.detailedSpecialHourPeriods, day => {
    return isDayInFuture(day) ? 'futureDays' : 'pastDays';
  });
}

function isTrueOrNull(value: boolean | null) {
  return value === true || value === null;
}

export function isConnectedOpeningHours(openingHours: OpeningHoursForDateApiResponseItem) {
  return (
    isTrueOrNull(openingHours.regularHoursInheritedByParent) ||
    isTrueOrNull(openingHours.specialHoursInheritedByParent)
  );
}

export function convertHourStringToDate(hourString: string) {
  return parse(hourString, 'HH:mm:ss', new Date());
}

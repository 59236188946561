import { toJS } from 'mobx';
import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import { useRootStore } from 'context/RootStoreContext';
import { PractitionerRolesTreeRoleNode } from 'modules/Practitioner/stores/PractitionerRolesStore';
import { PractitionerRoleDefinition } from 'modules/Roles/components/types';

import { PractitionerRoleFormModal } from './PractitionerRoleFormModal';
import { useCapabilities } from './useCapabilities';

export const EditPractitionerRole = ({
  role,
  onCancel,
  enableCapabilities,
  practitionerId,
}: {
  role: PractitionerRolesTreeRoleNode;
  onCancel: () => void;
  enableCapabilities?: boolean;
  practitionerId: string;
}) => {
  const [isSaving, setIsSaving] = useState(false);

  const { practitionerRolesStore } = useRootStore();

  const isAdministrativeRole = false;
  const intl = useIntl();
  const requiredErrorMessage = intl.formatMessage({
    id: 'general.errors.required',
  });

  const { data: capabilities, isLoading } = useCapabilities({ enabled: enableCapabilities });

  // since at the moment we cannot edit neither care unit nor role
  // actual retriving of care units and roles is skipped here
  // it requires more work to abstract them to be reusable enough
  const fakeCareUnitList = useMemo(
    () => [
      {
        label: role.careUnitName,
        value: role.careUnitId,
      },
    ],
    [role.careUnitId, role.careUnitName]
  );

  const fakeRoleList = useMemo(
    () => [
      {
        label: role.role,
        value: role.role,
      },
    ],
    [role.role]
  );

  const validationSchema = Yup.object().shape({
    careUnitIds: Yup.array().of(Yup.string()).required(requiredErrorMessage),
    role: Yup.string().required(requiredErrorMessage),
    capabilities:
      enableCapabilities && !isAdministrativeRole
        ? Yup.array().of(Yup.string()).required(requiredErrorMessage)
        : Yup.mixed().notRequired().nullable(),
  });

  const onSubmit = useCallback(
    async (data: any) => {
      setIsSaving(true);
      const mergedData = {
        ...role,
        ...data,
      };

      try {
        await practitionerRolesStore.editPractitionerRoleCapabilities(practitionerId, mergedData);
      } finally {
        setIsSaving(false);
      }
      onCancel();
    },
    [onCancel, practitionerId, practitionerRolesStore, role]
  );

  const caps = toJS(role.capabilities);

  return (
    <PractitionerRoleFormModal
      onCancel={onCancel}
      title={
        <span>
          <FormattedMessage id="general.edit" />
          &nbsp;&ndash;&nbsp;
          <FormattedMessage id="practitioner-roles-form.practitioner-roles-header" />
        </span>
      }
      isSaving={isSaving}
      isLoading={isLoading}
      careUnitsOptions={fakeCareUnitList}
      filterCareUnitOptions={() => true}
      mappedExtendedRole={{}}
      notAvailableCareUnitRoles={[]}
      formikInitialValues={
        {
          careUnitIds: [role.careUnitId],
          role: role.role,
          capabilities: caps,
        } as PractitionerRoleDefinition
      }
      validationSchema={validationSchema}
      isAdministrativeRole={isAdministrativeRole}
      rolesOptions={fakeRoleList}
      enableCapabilities={enableCapabilities}
      capabilities={capabilities}
      showRoleLock={false}
      onSubmit={onSubmit}
      disableCareUnitSelector
      disableRoleSelector
    />
  );
};

import { init as initApm } from '@elastic/apm-rum';

import { ENV, getEnv } from 'constants/env';

import { getToken } from './tokenUtils';

const env = getEnv();
const isLocalEnvOrDev = env === ENV.LOCAL || env === ENV.DEV;

const apm = initApm({
  active: !isLocalEnvOrDev || localStorage.getItem('apm') === 'true',
  serviceName: 'admin-ui-bananas',

  apiVersion: 3,

  serverUrl: '/rest',
  serverUrlPrefix: '/elastic-apm-rum-v3',

  serviceVersion: '0.1.0',

  apmRequest: ({ xhr }) => {
    const token = getToken();

    if (!token) {
      return false;
    }

    xhr.setRequestHeader('Authorization', `Bearer ${token}`);

    return true;
  },
});

const getClosestDataTestId = (el: Element) => {
  let currentEl = el;
  while (currentEl) {
    const attr = currentEl.attributes?.getNamedItem('data-testid');
    if (attr) {
      return attr?.value;
    }
    currentEl = currentEl.parentElement as HTMLElement;
  }

  return 'unknown';
};

const filterBtnLikeElement = (el: Element) => {
  return (
    el.role === 'button' ||
    el.parentElement?.role === 'button' ||
    el.tagName === 'BUTTON' ||
    el.parentElement?.tagName === 'BUTTON'
  );
};

apm.addFilter(function (payload) {
  // APM reports errors which says something like BtnClick
  // we want to know which button was clicked
  // so we use classes to find the possible classes (the only thing available here)
  // and then we find the closest data-testid to the element
  payload.errors?.forEach((error: unknown) => {
    if (typeof error === 'object' && error !== null && 'context' in error) {
      const context = error.context as { custom: { classes?: string; oneOfButtons?: string } };

      if (context && 'classes' in context.custom) {
        const classes = context.custom.classes;
        const els = document.querySelectorAll(
          classes!
            .split(' ')
            .map(c => `.${c}`)
            .join('')
        );
        const elsContents = Array.from(els)
          .filter(filterBtnLikeElement)
          .map(el => getClosestDataTestId(el) + ' -> ' + el.textContent)
          .join(' | ');

        context.custom.oneOfButtons = elsContents;
      }
    }
  });

  if (localStorage.getItem('apm') === 'true') {
    console.log('APM RUM trying to sent', payload);
  }

  return payload;
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).__apm = apm;

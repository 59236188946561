import {
  UserOutlined,
  PhoneOutlined,
  MailOutlined,
  ApartmentOutlined,
  MobileOutlined,
} from '@ant-design/icons';
import { ManagePage } from '@platform24/admin-ui-utils';
import { useQuery } from '@tanstack/react-query';
import { Divider, Flex, Typography, Tag, Button, Skeleton, Tooltip } from 'antd';
import { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import { fetchUserScopedRoles } from 'api/permissionsApi';
import { useRootStore } from 'context/RootStoreContext';
import { useIdentityOptions } from 'modules/Practitioner/PractitionerData/useIdentityOptions';
import { useCurrentUserWithRoles } from 'state/useCurrentUser';

import { Language } from './Language';
import {
  Content2,
  Title,
  RoleAt,
  RoleWrap,
  RoleLabel,
  HO,
  StyledAvatar,
  MyRoles,
} from './MyProfile.styled';
import { MyProfileItem } from './ProfileItem';
import { getInitials } from './utils';

const useUserScopedRoles = (id?: string) => {
  return useQuery({
    queryKey: ['user-scoped-roles', id],
    queryFn: () => fetchUserScopedRoles(id!).then(r => r.data),
    enabled: !!id,
  });
};

interface RolesListItemType {
  role: string;
  label: string;
  allRoles: Array<{ role: string; at: string; level: string }>;
}

const useMyProfileScopedRolesMapper = () => {
  const { data: me } = useCurrentUserWithRoles();
  const id = me?.id;

  const { data } = useUserScopedRoles(id);

  const intl = useIntl();

  return useMemo(() => {
    if (data) {
      const careProviders = data[0].scopes.careProviders ?? []; // probably should be something smarter than that
      const originsScope = data[0].scopes.origins ?? [];
      const partnerScope = data[0].partnerScope;

      const originScopeList = originsScope.flatMap(scope => {
        return (scope.roles ?? []).map(role => ({
          role: role.name,
          at: scope.scopeName ?? '',
          level: 'origin',
        }));
      });

      const regionName = partnerScope.scopeName ?? '';

      const careProviderScopeList = careProviders.flatMap(careProviderScope => {
        return careProviderScope.careUnits.flatMap(careUnitScope => {
          const localName =
            careUnitScope.scopeValue === '*'
              ? careProviderScope.scopeName
              : careUnitScope.scopeName;

          const at = careProviderScope.scopeValue === '*' ? regionName : localName;
          return (careUnitScope.roles ?? []).map(role => ({
            role: role.name,
            at: at ?? '',
            level: careProviderScope.scopeValue === '*' ? 'partner' : 'careProvider',
          }));
        });
      });

      // group by role
      const rolesLiist = [...originScopeList, ...careProviderScopeList].reduce((acc, role) => {
        let existingRoleGroup = acc.find(roleGroup => roleGroup.role === roleGroup.role);
        if (!existingRoleGroup) {
          existingRoleGroup = {
            label: intl.formatMessage({ id: `roles.roleid.${role.role}` }),
            role: role.role,
            allRoles: [],
          };
          acc.push(existingRoleGroup);
        }

        existingRoleGroup.allRoles.push(role);

        return acc;
      }, [] as RolesListItemType[]);

      return rolesLiist;
    }

    return [];
  }, [data, intl]);
};

const iconByLevel = {
  origin: <MobileOutlined />,
  partner: <ApartmentOutlined />,
  careProvider: <HO />,
};

const Role = ({ roles }: { roles: RolesListItemType }) => {
  const MAX_ROLES = 2;

  const hasMore = roles.allRoles.length - MAX_ROLES > 0;

  const intl = useIntl();

  const moreRoles = hasMore && (
    <Tooltip title={intl.formatMessage({ id: 'my-profile.see-all-access' })}>
      <Tag>+{roles.allRoles.length - MAX_ROLES}...</Tag>
    </Tooltip>
  );

  return (
    <RoleWrap>
      <Typography.Text>
        <RoleLabel>{roles.label}</RoleLabel>
      </Typography.Text>
      <RoleAt>@</RoleAt>{' '}
      {roles.allRoles.slice(0, MAX_ROLES).map(role => (
        <Tag key={role.at} icon={iconByLevel[role.level]}>
          {role.at}
        </Tag>
      ))}{' '}
      {moreRoles}
    </RoleWrap>
  );
};

const Roles = () => {
  const manageRoles = useMyProfileScopedRolesMapper();

  return (
    <div>
      {manageRoles.map(role => (
        <Role key={role.role} roles={role} />
      ))}
    </div>
  );
};

export const MyProfile = () => {
  const { userDataStore } = useRootStore();

  const { data: me, isSuccess } = useCurrentUserWithRoles();

  const identities = useIdentityOptions();

  const identity = identities.find(i => i.value === userDataStore.externalIdType);

  const history = useHistory();

  const goToEdit = useCallback(() => {
    history.push(`/roles/${userDataStore.id}/edit`);
  }, [history, userDataStore.id]);

  if (!isSuccess) {
    return (
      <ManagePage
        headerProps={{
          title: <FormattedMessage id="my-profile.my-profile" />,
        }}
      >
        <Skeleton avatar paragraph={{ rows: 4 }} />
      </ManagePage>
    );
  }

  return (
    <ManagePage
      headerProps={{
        title: <FormattedMessage id="my-profile.my-profile" />,
      }}
    >
      <Flex gap="large" vertical>
        <Content2>
          <Flex align="start" gap="middle">
            <Flex>
              <StyledAvatar size={64}>
                {getInitials(me.givenName, me.middleAndSurname)}
              </StyledAvatar>
            </Flex>
            <Flex vertical flex="1 1 auto">
              <Title>
                {me.givenName} {me.middleAndSurname}
              </Title>
            </Flex>
            <Flex>
              <Button type="link" onClick={goToEdit}>
                <FormattedMessage id="view-all-user-info" />
              </Button>
            </Flex>
          </Flex>
          <MyRoles align="start" gap="middle">
            {me.extendedRoles && <Roles />}
          </MyRoles>
          <Divider />

          {identity && (
            <MyProfileItem
              label={identity.label}
              text={userDataStore.externalId}
              icon={<UserOutlined />}
            />
          )}
          <Divider />
          <MyProfileItem
            label={<FormattedMessage id="basic-details-form.phone-number-label" />}
            text={me.mobileNumber ?? ''}
            icon={<PhoneOutlined />}
          />
          <Divider />
          <MyProfileItem
            label={<FormattedMessage id="practitioner-data-form.practitioner-email-label" />}
            text={me.email ?? ''}
            icon={<MailOutlined />}
          />
        </Content2>
        <Language />
      </Flex>
    </ManagePage>
  );
};

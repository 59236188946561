import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { PractitionerRolesTreeCareUnitNode } from 'modules/Practitioner/stores/PractitionerRolesStore';

const ExpandRowsContext = createContext<{ expanded: string[]; toggle: (rowId: string) => void }>({
  expanded: [],
  toggle: () => {},
});

/**
 * Reasoning:
 * This hook is used to manage the expanded rows in the ClinicPersonalRolesList component.
 *
 * AntD table has build-in expandable rows,
 * but it has hidden API which is not accessible from the outside.
 * And API provided by AntD is not enough to manage the expanded rows.
 */
export const useGetExpandRows = (data: PractitionerRolesTreeCareUnitNode[]) => {
  const [collapsed, setCollapsed] = useState<string[]>([]);

  const expanded = useMemo(
    () => data.filter(node => !collapsed.includes(node.id)).map(({ id }) => id),
    [data, collapsed]
  );

  const toggleRow = useCallback((rowId: string) => {
    setCollapsed(prev => {
      if (prev.includes(rowId)) {
        return prev.filter(id => id !== rowId);
      }

      return [...prev, rowId];
    });
  }, []);

  const prevData = useRef(data);

  useEffect(() => {
    const pd = prevData.current;
    // if we added a row, automatically expand it
    if (pd !== data) {
      data.forEach(node => {
        const prev = pd.find(({ id }) => id === node.id);
        if (prev && prev.children.length !== node.children.length) {
          setCollapsed(state => state.filter(id => id !== node.id));
        }
      });
    }

    prevData.current = data;
  }, [data]);

  const value = useMemo(() => ({ expanded, toggle: toggleRow }), [expanded, toggleRow]);

  const Provider = useMemo(() => {
    const Prov = ({ children }: React.PropsWithChildren) => {
      return <ExpandRowsContext.Provider value={value}>{children}</ExpandRowsContext.Provider>;
    };

    Prov.displayName = 'ExpandRowsProvider';

    return Prov;
  }, [value]);

  return {
    Provider,
    expanded,
    toggleRow,
  };
};

export const useExpandRows = () => useContext(ExpandRowsContext);

import { TeamOutlined, UserOutlined } from '@ant-design/icons';
import { AppBreadcrumbItem, ManagePage } from '@platform24/admin-ui-utils';
import { Typography, App } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { toJS } from 'mobx';
import { Observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import { Practitioner } from 'api/practitionerApi';
import { ROLE_MENUITEMS } from 'constants/permissions';
import RootStoreContext from 'context/RootStoreContext';
import { getInitials } from 'modules/MyProfile/utils';
import PractitionerData from 'modules/Practitioner/PractitionerData/PractitionerData';
import { PractitionerContext } from 'modules/Roles/context/PractitionerContext';

import { usePartnerScopeCustomization } from '../hooks/usePartnerScopeCustomization';
import PersonalRoles from '../PersonalRoles';

const RolesEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const intl = useIntl();
  const { notification } = App.useApp();

  const {
    practitionerStore,
    practitionerFindStore,
    partnersStore,
    userPermissionsStore,
    practitionerRolesStore,
    userDataStore: userStore,
  } = useContext(RootStoreContext);
  usePartnerScopeCustomization(partnersStore.currentPartner!.id);

  const handleSubmit = async (data: Practitioner) => {
    try {
      const newPractitioner = await practitionerStore.save(data);
      notification.success({
        placement: 'top',
        message: intl.formatMessage({
          id: data.id
            ? 'practitioner.practitioner-updated-message'
            : 'practitioner.new-practitioner-created-message',
        }),
      });

      if (!data.id && newPractitioner.id) {
        history.push(`/roles/${newPractitioner.id}/edit`);
      }
    } catch (error) {
      notification.error({
        placement: 'top',
        duration: 0,
        message: intl.formatMessage({ id: 'fallback.header' }),
      });
    }
  };

  useEffect(() => {
    partnersStore.fetchCountryCallingCodes();
    try {
      if (id) {
        practitionerStore.fetch(id);
      } else {
        practitionerStore.clear();
      }
    } catch (e) {
      notification.error({
        placement: 'top',
        duration: 0,
        message: intl.formatMessage({ id: 'practitioner.errors.not-found' }, { id }),
      });
      history.push('/roles');
    }

    return () => {
      practitionerStore.clear();
    };
  }, [id]);

  const contextValue = React.useMemo(() => ({ practitionerId: id }), [id]);

  const isEditMode = !!id;
  const titleFormatted = isEditMode ? (
    <FormattedMessage id="roles.edit.header" />
  ) : (
    <FormattedMessage id="roles.add.header" />
  );

  const breadcrumbs: AppBreadcrumbItem[] = [
    {
      text: <FormattedMessage id="main-navigation.practitioners" />,
      link: '/roles',
      icon: <TeamOutlined />,
    },
    {
      text: titleFormatted,
    },
  ];

  const initials = getInitials(
    practitionerStore.data?.givenName ?? '',
    practitionerStore.data?.middleAndSurname ?? ''
  );

  return (
    <ManagePage
      headerProps={{
        title: titleFormatted,
        breadcrumbs: { data: breadcrumbs },
        iconBG: 'rgba(0, 0, 0, 0.25)',
        icon: initials ? <span>{initials}</span> : <UserOutlined />,
        iconShape: 'circle',
      }}
    >
      <PractitionerContext.Provider value={contextValue}>
        <div>
          <Observer>
            {() => {
              const practitionerData = toJS(practitionerStore.data) as Practitioner;

              const hasRoleAtUserCareUnit =
                userPermissionsStore.isSuperAdmin ||
                (!!practitionerData &&
                  practitionerRolesStore.allRoles.some(({ careUnitId }) =>
                    userStore.userCareUnits.some(({ id }) => careUnitId === id)
                  ));
              const isClinicUserAdminOrSuperAdminOrConfigAdmin =
                userPermissionsStore.getSideBarAccess(
                  ROLE_MENUITEMS.CLINIC_USER_ADMIN,
                  ROLE_MENUITEMS.CONFIG_ADMIN,
                  ROLE_MENUITEMS.SUPER_ADMIN
                );
              return (
                <PractitionerData
                  initialValues={practitionerData}
                  countryCallingCodes={toJS(partnersStore.countryCallingCodes)}
                  externalIdValidationFunction={practitionerFindStore.findByExternalId}
                  onSubmit={handleSubmit}
                  hasRoleAtUserCareUnit={hasRoleAtUserCareUnit}
                  isFullyEditable={
                    userPermissionsStore.isSuperAdmin ||
                    isEmpty(practitionerData) ||
                    isClinicUserAdminOrSuperAdminOrConfigAdmin
                  }
                  isLoading={practitionerStore.isLoading() || practitionerStore.isSaving()}
                />
              );
            }}
          </Observer>

          {isEditMode && (
            <>
              <Typography.Title level={3}>
                <FormattedMessage id="roles.header" />
              </Typography.Title>
              <PersonalRoles />
            </>
          )}
        </div>
      </PractitionerContext.Provider>
    </ManagePage>
  );
};

export default RolesEdit;

import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Users from 'modules/Users/index';

import RolesEdit from './components/RolesEdit';

const Roles = () => {
  return (
    <Switch>
      <Route exact path="/roles" component={Users} />
      <Route path="/roles/add" component={RolesEdit} />
      <Route path="/roles/:id/edit" component={RolesEdit} />
    </Switch>
  );
};

export default Roles;

import { logJSError, logWarning, sendEventV2 } from '@platform24/analytics';

import { logAPMError } from 'config/ErrorTrackig';

const APP_NAME = 'admin-ui';

export const ErrorType = {
  INVALID_STATE: 'invalid-state',
  ERROR_BOUNDARY: 'error-boundary',
  ERROR_BOX: 'error-box',
} as const;

type ErrorProps = Omit<Parameters<typeof logJSError>[1], 'app' | 'type'> & {
  type: (typeof ErrorType)[keyof typeof ErrorType];
};

export const logError = (error: unknown, props: ErrorProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logAPMError(error as Error, props as any as Record<string, string>);
  logJSError(error, { app: APP_NAME, ...props });
};

type LogProps = Omit<Parameters<typeof logWarning>[0], 'app'>;

export const logShellWarning = (props: LogProps) => logWarning({ app: APP_NAME, ...props });

type EventProps = Omit<Parameters<typeof sendEventV2>[0], 'app'>;

export const sendV2Event = async (props: EventProps) => {
  try {
    await sendEventV2({ app: APP_NAME, ...props });
  } catch (e) {
    console.error(e);
  }
};

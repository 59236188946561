import { Alert, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, FunctionComponent, useContext } from 'react';
import { useIntl } from 'react-intl';

import Breadcrumbs, { AppBreadcrumbItem } from 'components/Breadcrumbs';
import RootStoreContext from 'context/RootStoreContext';
import PartnerStatus from 'modules/PartnerStatus';

import styles from './PageWithSectionsHeader.module.css';

interface Props {
  breadcrumbs?: AppBreadcrumbItem[];
  title: ReactNode;
}

const PageWithSectionsHeader: FunctionComponent<Props> = observer(({ breadcrumbs, title }) => {
  const {
    partnerStatusStore: { isMergeRequestPending, isGitStatusLoaded },
  } = useContext(RootStoreContext);
  const intl = useIntl();
  return (
    <div className={styles.wrapper}>
      <div className={styles.flexWrapper}>
        {breadcrumbs && <Breadcrumbs data={breadcrumbs} />} <PartnerStatus />
      </div>
      <Typography.Title level={2}>{title}</Typography.Title>
      {isMergeRequestPending && isGitStatusLoaded && (
        <div style={{ marginTop: '1rem' }}>
          <Alert
            message={intl.formatMessage({ id: 'partner-status.merge-request-pending-warning' })}
            type="warning"
            className={styles.notification}
            showIcon
          />
        </div>
      )}
    </div>
  );
});

export default PageWithSectionsHeader;

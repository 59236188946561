import { PictureOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { EditorState } from 'draft-js';
import { useState, useCallback, useEffect, FunctionComponent } from 'react';

import { ImageModal } from './ImageModal';

interface AddImageControlProps {
  editorState: EditorState;
  onClick: (imageEntityKey: string) => void;
}

export const AddImageControl: FunctionComponent<AddImageControlProps> = ({
  onClick,
  editorState,
}) => {
  const [imageEntityKey, setImageEntityKey] = useState<string>();

  const isDisabled = imageEntityKey === undefined;
  const disableImageControl = useCallback(() => {
    setImageEntityKey(undefined);
  }, [setImageEntityKey]);

  useEffect(
    function setImageEntityKeyBasedOnSelection() {
      const selection = editorState.getSelection();
      const hasTextSelected = !selection.isCollapsed();
      if (hasTextSelected) {
        disableImageControl();
        return;
      }

      const currentContent = editorState.getCurrentContent();
      const block = currentContent.getBlockForKey(selection.getStartKey());
      const selectionStart = selection.getStartOffset();

      const entityKey = block.getEntityAt(selectionStart);
      if (entityKey) {
        const entity = currentContent.getEntity(entityKey);

        if (entity.getType() !== 'IMAGE') {
          disableImageControl();
          return;
        }

        setImageEntityKey(entityKey);
        return;
      }

      setImageEntityKey('');
    },
    [disableImageControl, editorState, setImageEntityKey]
  );

  const handleClick = () => {
    if (imageEntityKey === undefined) {
      return;
    }

    // Text cursor (selection) is on existing image
    if (imageEntityKey) {
      onClick(imageEntityKey);
    } else {
      // We're creating a new image - please note that this won't be present in editor state
      // as we're not updating that state. In fact, we only want to create a valid entity key.
      const currentContentState = editorState.getCurrentContent();
      const currentContentStateWithEntity = currentContentState.createEntity('IMAGE', 'MUTABLE', {
        src: '',
        alt: '',
      });
      const entityKey = currentContentStateWithEntity.getLastCreatedEntityKey();
      onClick(entityKey);
    }

    setImageEntityKey('');
  };

  return (
    <>
      <Button onMouseDown={handleClick} icon={<PictureOutlined />} disabled={isDisabled} />
      <ImageModal />
    </>
  );
};

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Table, Space, Button, Form } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { addDays, format, subDays } from 'date-fns';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

import DatePicker from 'components/DatePicker';

import { CareUnitSelect } from './components/CareUnitSelect';
import { OpeningHoursDependencyTag } from './components/OpeningHoursDependencyTag';
import { OpeningHourStatusSelect } from './components/OpeningHourStatusSelect';
import { SpecialOpeningHoursInfo } from './components/SpecialOpeningHoursInfo';
import { CARE_UNIT_SELECTED_ALL, OPENING_HOURS_SELECTED_STATUS } from './constants';
import { useCurrentCareProviderId, useOpeningHoursForDate } from './openingHours.hooks';
import { OpeningHoursForDate, OpeningStatus } from './openingHours.types';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

interface InitialFormValues {
  selectedCareUnit: string;
  selectedDate: Date;
  selectedStatus: OPENING_HOURS_SELECTED_STATUS;
}

function useColumns() {
  const { formatMessage } = useIntl();
  const currentCareProviderId = useCurrentCareProviderId();
  const columns: ColumnsType<OpeningHoursForDate> = useMemo(() => {
    return [
      {
        title: formatMessage({ id: 'Care unit' }),
        dataIndex: 'careUnitName',
        key: 'careUnitName',
        sorter: (a, b) => a.careUnitName.localeCompare(b.careUnitName),
        render: (text, record) => (
          <Link
            to={`/my-health-care-organizations/${currentCareProviderId}/care-units/${record.careUnitId}/opening-hours`}
          >
            {text}
          </Link>
        ),
      },
      {
        title: formatMessage({ id: 'Opens' }),
        dataIndex: 'openDate',
        key: 'openDate',
        sorter: (a, b) =>
          a.openDate && b.openDate && a.openDate.getTime() > b.openDate.getTime() ? 1 : -1,
        render: (openDate: Date | null, record: OpeningHoursForDate) => (
          <>
            {record.status == OpeningStatus.OPEN && openDate
              ? format(openDate, 'HH:mm')
              : formatMessage({ id: 'opening-hours.closed' })}
            {record.isSpecial && <SpecialOpeningHoursInfo />}
          </>
        ),
      },
      {
        title: formatMessage({ id: 'Closes' }),
        dataIndex: 'closeDate',
        key: 'closeDate',
        sorter: (a, b) =>
          a.closeDate && b.closeDate && a.closeDate.getTime() > b.closeDate.getTime() ? 1 : -1,
        render: (closeDate: Date | null, record: OpeningHoursForDate) => (
          <>
            {record.status == OpeningStatus.OPEN && closeDate
              ? format(closeDate, 'HH:mm')
              : formatMessage({ id: 'opening-hours.closed' })}
            {record.isSpecial && <SpecialOpeningHoursInfo />}
          </>
        ),
      },
      {
        title: formatMessage({ id: 'Opening hour dependency' }),
        dataIndex: 'isConnected',
        key: 'isConnected',
        sorter: (a, b) => (a.isConnected ? (b.isConnected ? 0 : 1) : -1),
        render: (isConnected: boolean) => <OpeningHoursDependencyTag isConnected={isConnected} />,
      },
    ];
  }, [formatMessage, currentCareProviderId]);

  return columns;
}

export function OpeningHoursOverview() {
  const initialValues: InitialFormValues = {
    selectedCareUnit: CARE_UNIT_SELECTED_ALL,
    selectedDate: new Date(),
    selectedStatus: OPENING_HOURS_SELECTED_STATUS.ALL,
  };
  const [form] = Form.useForm();
  const formValues = Form.useWatch<InitialFormValues>([], form);
  const [pageSize, setPageSize] = useState<number>(10);
  const { formatMessage } = useIntl();
  const currentCareProviderId = useCurrentCareProviderId();
  const { data: openingHours } = useOpeningHoursForDate(
    currentCareProviderId,
    formValues?.selectedDate || initialValues.selectedDate
  );

  const setToday = () => {
    form.setFieldValue('selectedDate', new Date());
  };

  const subtractDay = () => {
    const currentDate = form.getFieldValue('selectedDate');
    const newDate = subDays(currentDate, 1);
    form.setFieldValue('selectedDate', newDate);
  };

  const addDay = () => {
    const currentDate = form.getFieldValue('selectedDate');
    const newDate = addDays(currentDate, 1);
    form.setFieldValue('selectedDate', newDate);
  };

  const columns = useColumns();

  const data = useMemo(() => {
    if (!openingHours) {
      return [];
    }

    if (!formValues) {
      return openingHours;
    }

    const { selectedCareUnit, selectedStatus } = formValues;

    return openingHours.filter(
      // render this care unit if:
      cu =>
        // "All" selected on care unit form selector
        (selectedCareUnit === CARE_UNIT_SELECTED_ALL || cu.careUnitId === selectedCareUnit) &&
        // "ALL" selected on status form selector
        (selectedStatus === OPENING_HOURS_SELECTED_STATUS.ALL ||
          // "Open" selected on status form selector and CU has open and close time
          (selectedStatus === OPENING_HOURS_SELECTED_STATUS.OPEN && cu.openDate && cu.closeDate) ||
          // "Closed" selected on status form selector and CU has no open and close time
          (selectedStatus === OPENING_HOURS_SELECTED_STATUS.CLOSED &&
            !cu.openDate &&
            !cu.closeDate))
    );
  }, [formValues, openingHours]);

  return (
    <>
      <Container>
        <Form form={form} layout="inline" initialValues={initialValues}>
          <Space size={0} align="end">
            <Form.Item
              layout="vertical"
              name="selectedCareUnit"
              label={formatMessage({ id: 'Care unit' })}
            >
              <CareUnitSelect />
            </Form.Item>
            <Form.Item>
              <Button onClick={setToday}>{formatMessage({ id: 'Today' })}</Button>
            </Form.Item>

            <Form.Item>
              <Space>
                <Button icon={<LeftOutlined />} onClick={subtractDay} />

                <Form.Item name="selectedDate" noStyle>
                  <DatePicker format="D MMM" />
                </Form.Item>

                <Button icon={<RightOutlined />} onClick={addDay} />
              </Space>
            </Form.Item>
            <Form.Item
              layout="vertical"
              name="selectedStatus"
              label={formatMessage({ id: 'general.show' })}
            >
              <OpeningHourStatusSelect />
            </Form.Item>
          </Space>
        </Form>
      </Container>
      <Table<OpeningHoursForDate>
        columns={columns}
        dataSource={data}
        pagination={{
          total: data.length,
          pageSize: pageSize,
          showSizeChanger: true,
          onShowSizeChange: (_: number, size: number) => setPageSize(size),
        }}
        rowKey="careUnitId"
      />
    </>
  );
}

import { createStore } from 'jotai';
import intersection from 'lodash/intersection';
import { action, computed, IObservableArray, observable, runInAction } from 'mobx';

import {
  AccessModes,
  AccessScopeRole,
  ACCESS_LEVEL,
  CareProvidersScope,
  fetchMyAccessScopedRoles,
  fetchMyUserPermissions,
  Scope,
  ScopedRoles,
} from 'api/permissionsApi';
import { RESOURCE_TYPES, ROLE_MENUITEMS } from 'constants/permissions';
import { ACCESS_SCOPE_TYPES, LOGIN_ALLOWED_ROLES, ROLES } from 'constants/roles';
import { fetchAccessForMenuItems } from 'modules/Roles/api/rolesApi';
import { userPermissionsPrivateAtoms } from 'state/userPermissions/userPermissions.selectors';
import { extractRolesFromJWT } from 'utils/rolesUtils';
import { getPermissionStringWildCardCombinations } from 'utils/textUtils';
import { getToken } from 'utils/tokenUtils';

import StateManager from './abstractStores/StateManager';
import RootStore from './RootStore';

export type PermissionQueryConfig = {
  resourceType: RESOURCE_TYPES;
  accessLevel?: ACCESS_LEVEL;
  originId?: string;
  careProviderId?: string;
  careUnitId?: string;
  resourceValue?: string;
};

/**
 * @deprecated Use the Jotai atom inside src/state directory instead
 */
class UserPermissionsStore extends StateManager {
  private scope: Map<string, ACCESS_LEVEL> = new Map();
  private menuAccess: Map<string, boolean> = new Map();
  private jotaiStore: ReturnType<typeof createStore>;

  @observable myScopedRoles: ScopedRoles[] = [];
  @observable roles?: IObservableArray<ROLES>;

  constructor(
    private rootStore: RootStore,
    jotaiStore: ReturnType<typeof createStore> = createStore()
  ) {
    super();

    this.jotaiStore = jotaiStore;

    this.jotaiStore.set(userPermissionsPrivateAtoms.scope, this.scope);
    this.jotaiStore.set(userPermissionsPrivateAtoms.menuAccess, this.menuAccess as any);
  }

  // FIXME: utility method meant only for transition period
  get isEnabled() {
    return this.scope.size > 0;
  }

  @computed
  get isAllowedUser() {
    const isAllowedRole =
      !!this.roles && intersection(this.roles.slice(), LOGIN_ALLOWED_ROLES).length > 0;
    const isAllowedPartnerRole = !!this.rootStore.partnersStore.partners.length;

    return isAllowedRole || isAllowedPartnerRole;
  }

  /**
   * determines role higher than care unit level role
   */
  @computed
  get hasStarCareUnitRoleForConfigAdminOrClinicUserAdmin() {
    return this.myScopedRoles.some(scopedRole => {
      return scopedRole?.scopes?.careProviders?.some(careProvider =>
        careProvider?.careUnits?.some(
          careUnit =>
            careUnit?.scopeValue === '*' &&
            careUnit?.roles?.some(
              role =>
                role.name === ROLE_MENUITEMS.CONFIG_ADMIN ||
                role.name === ROLE_MENUITEMS.CLINIC_USER_ADMIN
            )
        )
      );
    });
  }

  @computed
  get hasConfigAdminOrClinicUserAdminInOrigin() {
    const scopedRoles = this.myScopedRoles;

    return (origin: string) => {
      return (
        UserPermissionsStore.hasRoleInScopedOrigin(
          scopedRoles,
          origin,
          ROLE_MENUITEMS.CONFIG_ADMIN
        ) ||
        UserPermissionsStore.hasRoleInScopedOrigin(
          scopedRoles,
          origin,
          ROLE_MENUITEMS.CLINIC_USER_ADMIN
        )
      );
    };
  }

  @computed get isSuperAdmin() {
    return !!this.roles && this.roles.includes(ROLES.SUPER_ADMIN);
  }

  @computed get isNewRoleSuperAdmin() {
    return this.getSideBarAccess(ROLE_MENUITEMS.SUPER_ADMIN);
  }

  @computed get isAdmin() {
    return this.isSuperAdmin || (!!this.roles && this.roles.includes(ROLES.ADMIN));
  }

  @action setUserRolesFromJWT = () => {
    const token = getToken();
    if (token) {
      this.roles = observable.array(extractRolesFromJWT(token));
    }
  };

  @action updateRoles(roles: ROLES[]) {
    this.roles = observable.array(roles);
  }

  @computed
  get hasSuperAdminRole(): boolean {
    return this.isSuperAdmin;
  }

  /**
   * @deprecated
   */
  @computed
  get canEditCurrentPartner(): boolean {
    return this.hasSuperAdminRole && !this.rootStore.partnersStore.isReadOnlyModeEnabled;
  }

  hasRole = (role: ROLES) => !!this.roles && this.roles.includes(role);

  // exposed for testing purpose
  resetMenuAccess = () => {
    this.menuAccess = new Map();
  };

  getManagedOrigins = (partnerId: string): Scope[] => {
    const partnerScopes = this.myScopedRoles.find(
      elem => elem.partnerScope.scopeValue === partnerId
    );
    if (!partnerScopes || !partnerScopes.scopes.origins) return [];

    return partnerScopes.scopes.origins;
  };

  getManagedCareProviders = (partnerId: string): CareProvidersScope[] => {
    const partnerScopes = this.myScopedRoles.find(
      elem => elem.partnerScope.scopeValue === partnerId
    );
    if (!partnerScopes || !partnerScopes.scopes.careProviders) return [];

    return partnerScopes.scopes.careProviders;
  };

  getManagedCareUnits = (partnerId: string, careProviderId: string): Scope[] => {
    const managedCareProviders = this.getManagedCareProviders(partnerId);
    const careProvider = managedCareProviders.find(cp => cp.scopeValue === careProviderId);

    return careProvider ? careProvider.careUnits : [];
  };

  getOriginScopedRoles = (partnerId: string, originId: string): AccessScopeRole[] => {
    const managedOrigins = this.getManagedOrigins(partnerId);
    const origin = managedOrigins.find(o => o.scopeValue === originId);

    if (origin && origin.roles?.length) {
      return origin.roles;
    }

    // If there are no specific roles for origin, check if there are roles set for '*' - all origins
    const allOriginsScope = managedOrigins.find(o => o.scopeValue === ACCESS_SCOPE_TYPES.ALL);
    if (!allOriginsScope) return [];

    return allOriginsScope.roles || [];
  };

  getCareUnitScopedRoles = (
    partnerId: string,
    careProviderId: string,
    careUnitId: string
  ): AccessScopeRole[] => {
    const managedCareUnits = this.getManagedCareUnits(partnerId, careProviderId);

    const careUnit = managedCareUnits.find(cu => cu.scopeValue === careUnitId);
    if (careUnit && careUnit.roles) {
      return careUnit.roles;
    }

    // If there are no specific roles for the care unit, check if there are roles set for '*' - all care units
    const allCareUnitsScopes = managedCareUnits.find(o => o.scopeType === ACCESS_SCOPE_TYPES.ALL);
    if (!allCareUnitsScopes || !allCareUnitsScopes.roles) return [];

    return allCareUnitsScopes.roles;
  };

  fetchMyScopedRoles = async () => {
    this.setLoading();

    try {
      const { data } = await fetchMyAccessScopedRoles();

      if (data.length) {
        console.log('[M24] Using new permission config');
      }

      runInAction(() => {
        this.myScopedRoles = data;
      });

      // This should fail silently
      // eslint-disable-next-line no-empty
    } catch {
    } finally {
      this.setLoaded();
    }
  };

  setScope(scope: Map<string, ACCESS_LEVEL>) {
    this.scope = scope;
    this.jotaiStore.set(userPermissionsPrivateAtoms.scope, scope);
  }

  fetchMyUserPermissions = async () => {
    this.setLoading();

    try {
      const { data } = await fetchMyUserPermissions();
      const { data: access } = await fetchAccessForMenuItems();
      const { scopedRoles = [], partnerRoles = [] } = access;
      for (const menuAccessRole of scopedRoles) {
        this.menuAccess.set(menuAccessRole, true);
      }

      for (const menuAccessRole of partnerRoles) {
        this.menuAccess.set(menuAccessRole, true);
      }

      if (data.length) {
        console.log('[M24] Using new permission config');
      }

      this.setScope(
        new Map(data.map(({ path, permissionString }: AccessModes) => [path, permissionString]))
      );

      // This should fail silently
      // eslint-disable-next-line no-empty
    } catch {
    } finally {
      this.setLoaded();
    }
  };

  private getScopeValue(basePath: string, accessLevel: ACCESS_LEVEL, resourceValue?: string) {
    const isReadAccess = accessLevel === ACCESS_LEVEL.READ;
    const scopeWildcardPaths = getPermissionStringWildCardCombinations(basePath);

    for (let i = 0; i < scopeWildcardPaths.length; i += 1) {
      const path = scopeWildcardPaths[i];
      const resoureWildcardValue = this.scope.get(`/${path}/**`);

      if (!!resoureWildcardValue && (isReadAccess || resoureWildcardValue === ACCESS_LEVEL.WRITE)) {
        return true;
      }

      const value = this.scope.get(`/${path}/${resourceValue}`);

      if (!!value && (isReadAccess || value === ACCESS_LEVEL.WRITE)) {
        return true;
      }
    }

    return false;
  }

  private getSystemScopeValue = (
    resourceType: RESOURCE_TYPES,
    accessLevel: ACCESS_LEVEL,
    resourceValue?: string
  ) => {
    // System scope paths are treated a bit different,
    // that's why getScopeValue is not reused here.
    const isReadAccess = accessLevel === ACCESS_LEVEL.READ;
    // First two work the same, last one is for super admins (access all resources)
    const scopeWildcardPaths = [`/${resourceType}`, `/${resourceType}/**`, `/**`];

    for (let i = 0; i < scopeWildcardPaths.length; i += 1) {
      const path = scopeWildcardPaths[i];
      const resoureWildcardValue = this.scope.get(path);

      if (!!resoureWildcardValue && (isReadAccess || resoureWildcardValue === ACCESS_LEVEL.WRITE)) {
        return true;
      }
    }

    const value = this.scope.get(`/${resourceType}/${resourceValue}`);

    return !!value && (isReadAccess || value === ACCESS_LEVEL.WRITE);
  };

  getSideBarAccess = (...menuRoleAccess: ROLE_MENUITEMS[]) => {
    if (!menuRoleAccess || menuRoleAccess.length < 1) {
      return false;
    }
    return menuRoleAccess.some(role => this.menuAccess.get(role));
  };

  @computed
  get hasConfigAdminInOrigin() {
    return this.myScopedRoles.some(scopedRole => {
      return scopedRole?.scopes?.origins?.some(origin =>
        origin?.roles?.some(role => role.name === ROLE_MENUITEMS.CONFIG_ADMIN)
      );
    });
  }

  @computed
  get hasConfigAdminInCareUnit() {
    return this.myScopedRoles.some(scopedRole => {
      return scopedRole?.scopes?.careProviders?.some(careProvider =>
        careProvider?.careUnits?.some(careUnit =>
          careUnit?.roles?.some(role => role.name === ROLE_MENUITEMS.CONFIG_ADMIN)
        )
      );
    });
  }

  private getPartnerScopeValue = (
    resourceType: RESOURCE_TYPES,
    accessLevel: ACCESS_LEVEL,
    partnerId: string,
    resourceValue?: string
  ) => this.getScopeValue(`partners/${partnerId}/${resourceType}`, accessLevel, resourceValue);

  private getOriginScopeValue = (
    resourceType: RESOURCE_TYPES,
    accessLevel: ACCESS_LEVEL,
    partnerId: string,
    originId: string,
    resourceValue?: string
  ) =>
    this.getScopeValue(
      `partners/${partnerId}/origins/${originId}/${resourceType}`,
      accessLevel,
      resourceValue
    );

  private getCareProviderScopeValue = (
    resourceType: RESOURCE_TYPES,
    accessLevel: ACCESS_LEVEL,
    partnerId: string,
    careProviderId: string,
    resourceValue?: string
  ) =>
    this.getScopeValue(
      `partners/${partnerId}/careproviders/${careProviderId}/${resourceType}`,
      accessLevel,
      resourceValue
    );

  private getCareUnitScopeValue = (
    resourceType: RESOURCE_TYPES,
    accessLevel: ACCESS_LEVEL,
    partnerId: string,
    careProviderId: string,
    careUnitId: string,
    resourceValue?: string
  ) =>
    this.getScopeValue(
      `partners/${partnerId}/careproviders/${careProviderId}/careunits/${careUnitId}/${resourceType}`,
      accessLevel,
      resourceValue
    );

  getPermission = (
    {
      resourceType,
      accessLevel = ACCESS_LEVEL.WRITE,
      originId,
      careProviderId,
      careUnitId,
      resourceValue,
    }: PermissionQueryConfig,
    // utility argument meant only for transition period
    fallback = false
  ) => {
    if (!this.isEnabled) {
      return fallback;
    }

    const systemScopeValue = this.getSystemScopeValue(resourceType, accessLevel, resourceValue);

    if (systemScopeValue) {
      return true;
    }

    const partnerId = this.rootStore.partnersStore.partnerId;

    if (partnerId) {
      const partnerScopeValue = this.getPartnerScopeValue(
        resourceType,
        accessLevel,
        partnerId,
        resourceValue
      );

      if (partnerScopeValue) {
        // console.log('partner scope value');
        return true;
      }

      if (originId) {
        return this.getOriginScopeValue(
          resourceType,
          accessLevel,
          partnerId,
          originId,
          resourceValue
        );
      }

      if (careProviderId) {
        const careProviderScopeValue = this.getCareProviderScopeValue(
          resourceType,
          accessLevel,
          partnerId,
          careProviderId,
          resourceValue
        );

        if (careProviderScopeValue) {
          // console.log('care provider scope value')
          return true;
        }

        if (careUnitId) {
          return this.getCareUnitScopeValue(
            resourceType,
            accessLevel,
            partnerId,
            careProviderId,
            careUnitId,
            resourceValue
          );
        }
      }
    }

    return false;
  };

  static hasRoleInScopedCareUnit(
    scopedRoles: ScopedRoles[],
    careProviderId: string,
    careUnitId: string,
    role: string
  ) {
    return scopedRoles.some(scopedRole => {
      const careProvider = scopedRole.scopes.careProviders?.find(
        cp => cp.scopeValue === careProviderId
      );

      if (!careProvider) {
        return false;
      }

      const wildcardCareUnit = careProvider.careUnits?.find(cu => cu.scopeValue === '*');
      if (wildcardCareUnit?.roles?.some(r => r.name === role)) {
        return true;
      }

      const careUnit = careProvider.careUnits?.find(cu => cu.scopeValue === careUnitId);
      if (!careUnit) {
        return false;
      }

      return careUnit.roles?.some(r => r.name === role);
    });
  }
  static hasRoleInScopedCareUnitWithoutCareProvider(
    scopedRoles: ScopedRoles[],
    careUnitId: string,
    role: string
  ) {
    return scopedRoles.some(scopedRole => {
      return scopedRole.scopes.careProviders?.some(careProvider => {
        const wildcardCareUnit = careProvider.careUnits?.find(cu => cu.scopeValue === '*');
        if (wildcardCareUnit?.roles?.some(r => r.name === role)) {
          return true;
        }

        const careUnit = careProvider.careUnits?.find(cu => cu.scopeValue === careUnitId);
        if (!careUnit) {
          return false;
        }

        return careUnit.roles?.some(r => r.name === role);
      });
    });
  }

  static hasRoleInScopedCareProvider(
    scopedRoles: ScopedRoles[],
    careProviderId: string,
    role: string
  ) {
    return scopedRoles.some(scopedRole => {
      const careProvider = scopedRole.scopes?.careProviders?.find(
        cp => cp.scopeValue === careProviderId
      );

      if (!careProvider) {
        return false;
      }

      const wildcardCareUnit = careProvider.careUnits?.find(cu => cu.scopeValue === '*');

      return wildcardCareUnit?.roles?.some(r => r.name === role);
    });
  }

  static hasRoleInScopedOrigin(scopedRoles: ScopedRoles[], originId: string, role: string) {
    return scopedRoles.some(scopedRole => {
      const origin = scopedRole.scopes.origins?.find(
        o => o.scopeValue === originId || o.scopeValue === '*'
      );
      if (!origin) {
        return false;
      }
      return origin.roles?.some(r => r.name === role);
    });
  }

  /**
   * canEditOrigin replaces userPermissionsStore.canEditCurrentPartner for all care unit items that requires editing
   */
  @computed
  get canViewCareUnit() {
    const scopedRoles = this.myScopedRoles;
    return (careProviderId: string, careUnit: string) => {
      if (this.getSideBarAccess(ROLE_MENUITEMS.SUPER_ADMIN)) {
        return true;
      }

      return UserPermissionsStore.hasRoleInScopedCareUnit(
        scopedRoles,
        careProviderId,
        careUnit,
        ROLE_MENUITEMS.CONFIG_ADMIN
      );
    };
  }

  @computed
  get canEditCareProvider() {
    const scopedRoles = this.myScopedRoles;
    return (careProviderId: string) => {
      if (this.getSideBarAccess(ROLE_MENUITEMS.SUPER_ADMIN)) {
        return true;
      }

      return UserPermissionsStore.hasRoleInScopedCareProvider(
        scopedRoles,
        careProviderId,
        ROLE_MENUITEMS.CONFIG_ADMIN
      );
    };
  }

  /**
   * same as canViewCareUnit minus READ role
   */
  @computed
  get canEditCareUnit() {
    const scopedRoles = this.myScopedRoles;
    return (careProviderId: string, careUnit: string) => {
      if (this.getSideBarAccess(ROLE_MENUITEMS.SUPER_ADMIN)) {
        return true;
      }

      return UserPermissionsStore.hasRoleInScopedCareUnit(
        scopedRoles,
        careProviderId,
        careUnit,
        ROLE_MENUITEMS.CONFIG_ADMIN
      );
    };
  }

  /**
   * canEditOrigin replaces userPermissionsStore.canEditCurrentPartner for all origin items that's editable
   * TODO: may have to add !this.rootStore.partnersStore.isReadOnlyModeEnabled
   */
  @computed
  get canEditOrigin() {
    const scopedRoles = this.myScopedRoles;

    return (origin: string) => {
      if (this.getSideBarAccess(ROLE_MENUITEMS.SUPER_ADMIN)) {
        return true;
      }
      return UserPermissionsStore.hasRoleInScopedOrigin(
        scopedRoles,
        origin,
        ROLE_MENUITEMS.CONFIG_ADMIN
      );
    };
  }

  @computed
  get canViewOrigin() {
    const scopedRoles = this.myScopedRoles;

    return (origin: string) => {
      if (this.getSideBarAccess(ROLE_MENUITEMS.SUPER_ADMIN)) {
        return true;
      }

      return UserPermissionsStore.hasRoleInScopedOrigin(
        scopedRoles,
        origin,
        ROLE_MENUITEMS.CONFIG_ADMIN
      );
    };
  }

  @computed
  get hasClinicUserAdminForCareUnitInQuestion() {
    const scopedRoles = this.myScopedRoles;

    return (careUnitId: string) => {
      if (this.getSideBarAccess(ROLE_MENUITEMS.SUPER_ADMIN)) {
        return true;
      }

      return UserPermissionsStore.hasRoleInScopedCareUnitWithoutCareProvider(
        scopedRoles,
        careUnitId,
        ROLE_MENUITEMS.CLINIC_USER_ADMIN
      );
    };
  }
}

export default UserPermissionsStore;

import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useAtom } from 'jotai';
import { useCallback, useState } from 'react';

import { ROLE_MENUITEMS } from 'constants/permissions';
import { Config } from 'constants/practitioner';
import { useRootStore } from 'context/RootStoreContext';
import { useHasDomainAccess } from 'modules/AccessControl/useHasDomainAccess';
import { EditPractitionerRole } from 'modules/Practitioner/PractitionerRoles/components/AddPractitionerRoles/EditPractitionerRole';
import { PractitionerRolesTreeRoleNode } from 'modules/Practitioner/stores/PractitionerRolesStore';
import { usePractitionerContext } from 'modules/Roles/context/PractitionerContext';

import { partnerScopeCustomizationAtom } from '../../hooks/partnerScopeCustomizationAtom';

export const EditPersonalRole = ({ role }: { role: PractitionerRolesTreeRoleNode }) => {
  const { userDataStore } = useRootStore();
  const { practitionerId } = usePractitionerContext();

  const [customizations] = useAtom(partnerScopeCustomizationAtom);
  const enableCapabilities = !!customizations[Config.ENABLE_ASSIGNING_CAPABILITIES_IN_MANAGE];

  const [isEditModalShown, setEditModalShown] = useState(false);

  const hasAccess = useHasDomainAccess([ROLE_MENUITEMS.CLINIC_USER_ADMIN]);

  const hasCareUnit = !!userDataStore.userCareUnitsAsSelectOptions.find(
    cu => cu.value === role.careUnitId
  );

  const canDelete = hasAccess || hasCareUnit;

  const onEdit = useCallback(() => {
    setEditModalShown(true);
  }, []);

  const cancelEdit = useCallback(() => {
    setEditModalShown(false);
  }, []);

  if (!canDelete || !enableCapabilities) {
    return null;
  }

  return (
    <>
      <Button
        type="link"
        icon={<EditOutlined />}
        size="small"
        data-action="edit"
        data-testid={`edit-btn-${role.roleId}`}
        onClick={onEdit}
      />
      {isEditModalShown && (
        <EditPractitionerRole
          enableCapabilities={enableCapabilities}
          role={role}
          onCancel={cancelEdit}
          practitionerId={practitionerId}
        />
      )}
    </>
  );
};

import { Button, Typography } from 'antd';
import {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  convertFromRaw,
  convertToRaw,
  Editor,
  EditorState,
  getDefaultKeyBinding,
  Modifier,
  RawDraftContentState,
  RichUtils,
  SelectionState,
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import React, { Component, KeyboardEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { css, styled } from 'styled-components';

import { RICH_TEXT_CONTENT_TYPES } from 'constants/text';
import { ErrorType, logError } from 'modules/Analytics/utils';

import { AddImageControl } from './components/AddImageControl';
import { getImageComponent } from './components/Image';
import { ImageData } from './components/ImageModal';
import { showImageModal } from './components/ImageModalButton';
import InlineStyleControls from './components/InlineStyleControls';
import LinkControls from './components/LinkControl';
import Link from './components/LinkControl/Link';
import ListStyleControls from './components/ListStyleControls';
import ParagraphStyleControls from './components/ParagraphStyleControls';
import {
  convertDraftToMd,
  convertMdToDraft,
  draftToHtml,
  getEntityPlacementDataByKey,
  htmlToDraft,
} from './utils';
import { isBlobImageSrc } from '../Image';

const MAX_LIST_INDENT_DEPTH = 4;

const ATOMIC_BLOCK_PLACEHOLDER = '__ATOMIC-BLOCK-PLACEHOLDER__';

const Styled = {
  Editor: styled.div<{ $hasError?: boolean }>`
    background-color: var(--color-white);
    border: 1px solid var(--color-border-dark);
    border-radius: var(--border-radius);
    padding: 11px;
    min-height: 150px;

    ${({ $hasError }) =>
      $hasError &&
      css`
        border-color: var(--color-red);
      `}
  `,
  ControlsContainer: styled.div`
    display: flex;
  `,
  ControlPanel: styled.div`
    display: inline-block;
    margin: 0 11px 11px 0;
  `,
  ErrorText: styled(Typography.Text)`
    color: var(--color-red);
  `,
};

type RichTextEditorProps = {
  initialValue: string;
  onChange: (markdownString: string) => void;
  isDisabled?: boolean;
  contentType?: RICH_TEXT_CONTENT_TYPES;
  // Whether to show paragraph controls
  paragraphControls?: boolean;
  // Whether to show list style controls (unordered, ordered list)
  listStyleControls?: boolean;
  // Whether to show inline style controls (bold, italics)
  inlineStyleControls?: boolean;
  // Whether to show link controls
  linkControls?: boolean;
  required?: boolean;
  forceValidation?: boolean;
} & (
  | {
      imageControls?: false;
      handleUploadedImageNamesChange?: never;
    }
  | {
      // Whether to show image controls (only Markdown content supports images)
      imageControls: true;
      // If imageControls is true, uploading new images is possible.
      // The host component should handle the uploading, so these names are supposed to be used when uploading blobs.
      handleUploadedImageNamesChange: (imageNamesMap: Map<string, string>) => void;
    }
);

interface State {
  editorState: EditorState;
  isEditorDirty: boolean;
  imageEntity?: {
    key: string;
  };
}

class RichTextEditor extends Component<RichTextEditorProps, State> {
  static defaultProps = {
    contentType: RICH_TEXT_CONTENT_TYPES.MARKDOWN,
  };

  editor?: Editor | null;
  editorContainer?: HTMLDivElement | null;
  state: State = {
    editorState: EditorState.createEmpty(),
    isEditorDirty: false,
  };
  uploadedImagesNamesMap = new Map<string, string>();

  constructor(props: RichTextEditorProps) {
    super(props);

    const content = props.initialValue;
    const isMarkdownContent =
      !props.contentType || props.contentType === RICH_TEXT_CONTENT_TYPES.MARKDOWN;
    const rawContentState = isMarkdownContent ? convertMdToDraft(content) : htmlToDraft(content);
    const contentState = convertFromRaw(rawContentState);
    const decorator = new CompositeDecorator([
      {
        strategy: this.findLinkEntities,
        component: Link,
      },
      {
        strategy: this.findImageEntities,
        component: getImageComponent(this.handleImageClick),
      },
    ]);

    this.state = {
      editorState: EditorState.createWithContent(contentState, decorator),
      isEditorDirty: false,
    };
  }

  convertToOutput(content: RawDraftContentState) {
    // atomic blocks are created by DraftJS with "a" as text,
    // and the conversion from draft to html/md does not remove it,
    // even though the atomic blocks are supposed to be images with no text.
    content.blocks.forEach(block => {
      if (block.type === 'atomic') {
        block.text = ATOMIC_BLOCK_PLACEHOLDER;
      }
    });

    const conversionMethod =
      this.props.contentType === RICH_TEXT_CONTENT_TYPES.MARKDOWN ? convertDraftToMd : draftToHtml;

    return conversionMethod(content).replaceAll(ATOMIC_BLOCK_PLACEHOLDER, '');
  }

  findLinkEntities = (
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState
  ) => {
    contentBlock.findEntityRanges(character => {
      const entityKey = character.getEntity();
      return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
    }, callback);
  };

  findImageEntities = (
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState
  ) => {
    contentBlock.findEntityRanges(character => {
      const entityKey = character.getEntity();
      return entityKey !== null && contentState.getEntity(entityKey).getType() === 'IMAGE';
    }, callback);
  };

  handleAddImageControlClick = (imageEntityKey: string) => {
    showImageModal({
      mode: 'select',
      onSelect: this.handleAddImage,
      imageType: 'content',
    });
    this.setState({
      imageEntity: {
        key: imageEntityKey,
      },
    });
  };

  handleImageClick = (imageEntityKey: string) => {
    const { editorState } = this.state;

    const currentContentState = editorState.getCurrentContent();
    const entity = currentContentState.getEntity(imageEntityKey);
    // newly created image has no correspondent entity yet
    const entityData = entity ? entity.getData() : {};

    showImageModal({
      mode: 'edit',
      data: {
        src: entityData.src || '',
        alt: entityData.alt || '',
        imageName: entityData.imageName || '',
      },
      onDelete: this.handleImageDelete,
      onSelect: this.handleAddImage,
      imageType: 'content',
    });
    this.setState({
      imageEntity: {
        key: imageEntityKey,
      },
    });
  };
  handleAddImage = async ({ src, alt, imageName }: ImageData) => {
    if (!this.state.imageEntity) {
      await logError('Image entity is not set when submitting image form', {
        scope: 'RichTextEditor',
        type: ErrorType.INVALID_STATE,
        properties: {},
      });
      return;
    }

    const entityPlacementData = getEntityPlacementDataByKey(
      this.state.imageEntity.key,
      this.state.editorState
    );
    const currentContentState = this.state.editorState.getCurrentContent();
    let newEditorState;

    // We're updating existing content
    if (entityPlacementData) {
      // Generally, it should work with something like:
      // const newContentState = currentContentState.replaceEntityData(imageEntityKey, { src, alt });
      // ...
      // Unfortunately, it does not refresh the editor image content immediately, only after text focus
      // is placed next to the image.
      // Details: https://github.com/facebookarchive/draft-js/issues/1702
      // Because of that, we take the hard way: first remove entity and its corresponding text, then add
      // it again with modified params.
      const newSelection = SelectionState.createEmpty(entityPlacementData.blockKey);
      const updatedSelection = newSelection.merge({
        anchorKey: entityPlacementData.blockKey,
        focusKey: entityPlacementData.blockKey,
        anchorOffset: entityPlacementData.anchor,
        focusOffset: entityPlacementData.focus,
      });

      const currentContentStateWithoutEntity = Modifier.applyEntity(
        currentContentState,
        updatedSelection,
        null
      );
      const currentContentStateWithoutText = Modifier.replaceText(
        currentContentStateWithoutEntity,
        updatedSelection,
        ''
      );
      const newContentStateWithNewEntity = currentContentStateWithoutText.createEntity(
        'IMAGE',
        'MUTABLE',
        {
          src,
          alt,
          imageName,
        }
      );
      const entityKey = newContentStateWithNewEntity.getLastCreatedEntityKey();
      const newContentStateWithNewText = Modifier.insertText(
        newContentStateWithNewEntity,
        newSelection,
        '📷',
        undefined,
        entityKey
      );
      newEditorState = EditorState.set(this.state.editorState, {
        currentContent: newContentStateWithNewText,
      });
      // We're creating new content
    } else {
      const currentSelection = this.state.editorState.getSelection();
      const currentContentStateWithEntity = currentContentState.createEntity('IMAGE', 'MUTABLE', {
        src,
        alt,
        imageName,
      });
      const entityKey = currentContentStateWithEntity.getLastCreatedEntityKey();
      const newContentState = Modifier.insertText(
        currentContentStateWithEntity,
        currentSelection,
        '📷',
        undefined,
        entityKey
      );
      newEditorState = EditorState.set(this.state.editorState, {
        currentContent: newContentState,
      });
    }

    // if the last block is an image, we need to add a newline after it,
    // otherwise the user won't be able to type anything after the image
    // since we disable the Enter key for images due to lack of DraftJS support
    if (this.state.editorState.isSelectionAtEndOfContent()) {
      const newContentState = Modifier.insertText(
        newEditorState.getCurrentContent(),
        newEditorState.getCurrentContent().getSelectionAfter(),
        '\n'
      );
      newEditorState = EditorState.set(newEditorState, {
        currentContent: newContentState,
      });
    }

    const contentState = newEditorState.getCurrentContent();

    const rawValue = convertToRaw(contentState);
    const value = this.convertToOutput(rawValue);

    this.setState({ editorState: newEditorState, imageEntity: undefined });
    this.props.onChange(value);
    if (isBlobImageSrc(src)) {
      this.uploadedImagesNamesMap.set(src, imageName);
      this.props.handleUploadedImageNamesChange?.(this.uploadedImagesNamesMap);
    }
  };

  handleImageDelete = async (src: string) => {
    if (!this.state.imageEntity) {
      await logError('Image entity is not set when deleting image', {
        scope: 'RichTextEditor',
        type: ErrorType.INVALID_STATE,
        properties: {},
      });
      return;
    }

    const entityPlacementData = getEntityPlacementDataByKey(
      this.state.imageEntity.key,
      this.state.editorState
    );
    if (!entityPlacementData) {
      // just exit, as there is no correspondent block with entity in editor state yet (i.e. entity was newly created)
      return;
    }

    const newSelection = SelectionState.createEmpty(entityPlacementData.blockKey);
    const updatedSelection = newSelection.merge({
      anchorKey: entityPlacementData.blockKey,
      focusKey: entityPlacementData.blockKey,
      anchorOffset: entityPlacementData.anchor,
      focusOffset: entityPlacementData.focus,
    });
    const currentContentState = this.state.editorState.getCurrentContent();
    const currentContentStateWithoutEntity = Modifier.applyEntity(
      currentContentState,
      updatedSelection,
      null
    );
    const newContentState = Modifier.replaceText(
      currentContentStateWithoutEntity,
      updatedSelection,
      ''
    );
    const newEditorState = EditorState.set(this.state.editorState, {
      currentContent: newContentState,
    });
    this.handleChangeEditorState(newEditorState);
    this.uploadedImagesNamesMap.delete(src);
  };

  handleChangeEditorState = (editorState: EditorState) => {
    // handleChange is fired on various state changes, not only when content actually changes,
    // so to trigger handleChange prop properly, we have to compare previous and current content.
    const content = editorState.getCurrentContent();
    const previousContent = this.state.editorState.getCurrentContent();
    const rawValue = convertToRaw(content);
    const rawPreviousValue = convertToRaw(previousContent);
    const value = this.convertToOutput(rawValue);
    const previousValue = this.convertToOutput(rawPreviousValue);
    const isContentModified = value !== previousValue;

    this.setState({ editorState });

    if (isContentModified) {
      this.props.onChange(value);
    }
  };

  setEditor = (editor: Editor | null) => {
    this.editor = editor;
  };

  setEditorContainer = (container: HTMLDivElement | null) => {
    this.editorContainer = container;
  };

  handleKeyCommand = (command: string, editorState: EditorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    const imageEntityBlockProhibitedCommands = ['split-block', 'backspace'];

    // Draft.js has buggy image entity block support when it comes to editing it
    // along with "regular" siblings. We need to prevent user from using Backspace
    // and Enter when image entity block is current selection.
    if (imageEntityBlockProhibitedCommands.includes(command)) {
      const selection = editorState.getSelection();
      const block = editorState.getCurrentContent().getBlockForKey(selection.getStartKey());
      const entityKey = block.getEntityAt(selection.getStartOffset());

      if (entityKey) {
        const entity = editorState.getCurrentContent().getEntity(entityKey);

        if (entity.getType() === 'IMAGE') {
          return 'handled';
        }
      }
    }

    if (newState) {
      this.handleChangeEditorState(newState);
      return 'handled';
    }

    return 'not-handled';
  };

  mapKeyToEditorCommand = (event: KeyboardEvent<Element>) => {
    // handle indent change in list with TAB key
    if (event.key === 'Tab') {
      const newEditorState = RichUtils.onTab(event, this.state.editorState, MAX_LIST_INDENT_DEPTH);

      if (newEditorState !== this.state.editorState) {
        this.handleChangeEditorState(newEditorState);
      }

      return null;
    }
    return getDefaultKeyBinding(event);
  };

  handleBlockTypeToggle = (blockType: string) => {
    this.handleChangeEditorState(RichUtils.toggleBlockType(this.state.editorState, blockType));
  };

  handleInlineStyleToggle = (inlineStyle: string) => {
    this.handleChangeEditorState(RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle));
  };

  // This sets focus on editor when empty container is clicked
  handleEditorClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === this.editorContainer) {
      this.editor?.focus();
    }
  };

  handleEditorBlur = () => {
    this.setState({ isEditorDirty: true });
  };

  render() {
    const { editorState, isEditorDirty } = this.state;
    const {
      isDisabled,
      imageControls,
      linkControls,
      inlineStyleControls,
      listStyleControls,
      paragraphControls,
      contentType,
      required,
      forceValidation,
    } = this.props;
    const isMarkdown = contentType === RICH_TEXT_CONTENT_TYPES.MARKDOWN;
    const hasRequiredError =
      required && (isEditorDirty || forceValidation) && !editorState.getCurrentContent().hasText();

    return (
      <>
        <Styled.Editor
          onClick={this.handleEditorClick}
          ref={this.setEditorContainer}
          $hasError={hasRequiredError}
        >
          <Styled.ControlsContainer>
            {paragraphControls && (
              <Styled.ControlPanel>
                <ParagraphStyleControls
                  editorState={editorState}
                  onToggle={this.handleBlockTypeToggle}
                  isDisabled={isDisabled}
                />
              </Styled.ControlPanel>
            )}
            {listStyleControls && (
              <Styled.ControlPanel>
                <ListStyleControls
                  editorState={editorState}
                  onToggle={this.handleBlockTypeToggle}
                  isDisabled={isDisabled}
                />
              </Styled.ControlPanel>
            )}
            {inlineStyleControls && (
              <Styled.ControlPanel>
                <InlineStyleControls
                  editorState={editorState}
                  onToggle={this.handleInlineStyleToggle}
                  isDisabled={isDisabled}
                />
              </Styled.ControlPanel>
            )}
            {(linkControls || (imageControls && isMarkdown)) && (
              <Styled.ControlPanel>
                <Button.Group>
                  {linkControls && (
                    <LinkControls
                      editorState={editorState}
                      onEditLink={this.handleChangeEditorState}
                      isDisabled={isDisabled}
                    />
                  )}
                  {imageControls && isMarkdown && (
                    <AddImageControl
                      onClick={this.handleAddImageControlClick}
                      editorState={editorState}
                    />
                  )}
                </Button.Group>
              </Styled.ControlPanel>
            )}
          </Styled.ControlsContainer>
          <Editor
            ref={this.setEditor}
            handleKeyCommand={this.handleKeyCommand}
            editorState={editorState}
            keyBindingFn={this.mapKeyToEditorCommand}
            onChange={this.handleChangeEditorState}
            onBlur={this.handleEditorBlur}
            readOnly={this.props.isDisabled}
          />
        </Styled.Editor>
        {hasRequiredError ? (
          <Styled.ErrorText>
            <FormattedMessage id="general.required-field" />
          </Styled.ErrorText>
        ) : null}
      </>
    );
  }
}

export default RichTextEditor;

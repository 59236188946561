import { useCallback } from 'react';

import { useRootStore } from 'context/RootStoreContext';
import { PractitionerRolesTreeRoleNode } from 'modules/Practitioner/stores/PractitionerRolesStore';
import { usePractitionerContext } from 'modules/Roles/context/PractitionerContext';

export const useHandleUpdatePersonalRole = () => {
  const { practitionerRolesStore } = useRootStore();
  const { practitionerId } = usePractitionerContext();
  return useCallback(
    async (role: PractitionerRolesTreeRoleNode) => {
      await practitionerRolesStore.updatePractitionerRoleV2(practitionerId, role);
    },
    [practitionerId, practitionerRolesStore]
  );
};

import { SrOnly } from '@platform24/admin-ui-utils';
import { Switch, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { useCallback, useId, useState } from 'react';
import { useIntl } from 'react-intl';

import { useRootStore } from 'context/RootStoreContext';
import { PractitionerRolesTreeRoleNode } from 'modules/Practitioner/stores/PractitionerRolesStore';

import { useHandleUpdatePersonalRole } from '../hooks/useHandleUpdatePersonalRole';

export const LockPersonalRole = observer(({ role }: { role: PractitionerRolesTreeRoleNode }) => {
  const handlePractitionerRole = useHandleUpdatePersonalRole();
  const intl = useIntl();
  const { userPermissionsStore } = useRootStore();
  const hasClinicUserAdminForCareUnitInQuestion =
    userPermissionsStore.hasClinicUserAdminForCareUnitInQuestion(role.careUnitId);

  const [isSaving, setIsSaving] = useState(false);

  const id = useId();

  const onChange = useCallback(async () => {
    setIsSaving(true);
    try {
      const newRole = { ...role, lockedFromAutoManagement: !role.lockedFromAutoManagement };
      await handlePractitionerRole(newRole);
    } finally {
      setIsSaving(false);
    }
  }, [handlePractitionerRole, role]);

  return (
    <Tooltip
      title={
        hasClinicUserAdminForCareUnitInQuestion
          ? null
          : intl.formatMessage({ id: 'roles.edit.role-lock-error-message' })
      }
    >
      <Switch
        loading={isSaving}
        onChange={onChange}
        checked={role.lockedFromAutoManagement}
        disabled={isSaving ? true : !hasClinicUserAdminForCareUnitInQuestion}
        size="small"
        data-testid="lock-role-switch"
        aria-describedby={
          hasClinicUserAdminForCareUnitInQuestion ? undefined : `lock-role-switch-tooltip-${id}`
        }
      />
      {!hasClinicUserAdminForCareUnitInQuestion && (
        <SrOnly id={`lock-role-switch-tooltip-${id}`}>
          {intl.formatMessage({ id: 'roles.edit.role-lock-error-message' })}
        </SrOnly>
      )}
    </Tooltip>
  );
});

import axios from 'axios';

export interface NewsItem {
  subject: string;
  body: string | null;
  link: string | null;
  id: string;
  startDate: string;
  startHour: number;
  startMinute: number;
  endDate: string;
  endHour: number;
  endMinute: number;
  enableEndDate: boolean;
  [key: string]: string | number | boolean | null;
}

export interface NewsItemPayload {
  subject: string;
  body: string | null;
  link: string | null;
  id: string;
  startsAt: number;
  endsAt?: number | null;
}

interface NewsItemOrder {
  newsId: string;
  rank: number;
}

export type PartnerId = string | undefined;

export const NEWS_ITEMS_URI = '/rest/admin/v1/partners';

export const fetchNewsItems = (originId: string, partnerId: PartnerId) =>
  axios.get<NewsItemPayload[]>(`${NEWS_ITEMS_URI}/${partnerId}/origins/${originId}/newsitems`);

export const createNewsItem = async (
  data: NewsItemPayload,
  originId: string,
  partnerId: PartnerId
) => axios.post(`${NEWS_ITEMS_URI}/${partnerId}/origins/${originId}/newsitems`, data);

export const updateNewsItems = (
  data: NewsItemPayload,
  originId: string,
  newsId: string,
  partnerId: PartnerId
) => axios.put(`${NEWS_ITEMS_URI}/${partnerId}/origins/${originId}/newsitems/${newsId}`, data);

export const deleteNewsItem = (newsId: string, originId: string, partnerId: PartnerId) =>
  axios.delete(`${NEWS_ITEMS_URI}/${partnerId}/origins/${originId}/newsitems/${newsId}`);

export const reorderNewsItems = (data: NewsItemOrder[], originId: string, partnerId: PartnerId) =>
  axios.put(`${NEWS_ITEMS_URI}/${partnerId}/origins/${originId}/newsitems/rankings`, data);

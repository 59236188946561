import { computed, toJS } from 'mobx';

import RootStore from 'stores/RootStore';

import {
  traverseOriginsTree,
  passUserPermissionDownOriginTreeGivenUserPermissionDataAndOrigin,
} from '../origin.utils';

export default class UserOriginPermissionListStore {
  constructor(private rootStore: RootStore) {}

  @computed
  get originsWithPermissionsAttached() {
    const { originsListStore, userPermissionsStore } = this.rootStore;
    return passUserPermissionDownOriginTreeGivenUserPermissionDataAndOrigin(
      toJS(originsListStore.originsList),
      userPermissionsStore
    );
  }
  /**
   * Users with only care unit access level, will not be able to assign manage roles
   * someone else.
   * don't show manage tab for such users
   */
  @computed
  get isAllowedToAddManageRoles() {
    const { originsListStore, userPermissionsStore } = this.rootStore;
    // 1. is super admin
    if (userPermissionsStore.isSuperAdmin || userPermissionsStore.isNewRoleSuperAdmin) {
      return true;
    }

    // 2. has role higher than care unit role
    if (userPermissionsStore.hasStarCareUnitRoleForConfigAdminOrClinicUserAdmin) {
      return true;
    }

    // 3. check if origin that has role for config admin or clinic user admin has sub origin(s) under neath
    if (
      traverseOriginsTree(
        originsListStore.originsList,
        userPermissionsStore.hasConfigAdminOrClinicUserAdminInOrigin
      )
    ) {
      return true;
    }

    return false;
  }
}

import { createContext, useContext } from 'react';

export type PractitionerContextType = {
  practitionerId: string;
};

export const PractitionerContext = createContext<PractitionerContextType>({
  get practitionerId() {
    throw new Error(
      'Using practitionerId outside of PractitionerContext which is provided under /roles/:id'
    );
    return '';
  },
});

export const usePractitionerContext = () => useContext(PractitionerContext);

import { useCapabilities } from 'modules/Practitioner/PractitionerRoles/components/AddPractitionerRoles/useCapabilities';
import { PractitionerRolesTreeRoleNode } from 'modules/Practitioner/stores/PractitionerRolesStore';

export const CapabilitiesColumn = ({ role }: { role: PractitionerRolesTreeRoleNode }) => {
  const roleCapabilities = role.capabilities;
  // todo: normalise capabilities maybe. put it in hash map for easy access.
  const { data: capabilities, isLoading } = useCapabilities();
  if (isLoading || !capabilities || !roleCapabilities) {
    return null;
  }

  const string =
    roleCapabilities && roleCapabilities.length > 0
      ? roleCapabilities
          .map(cId => {
            const cap = capabilities.find(c => c.value === cId);
            return cap?.label ?? cap?.value ?? cId;
          })
          .join(', ')
      : '';

  return <span>{string}</span>;
};

import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ORIGIN_RULES_CATEGORIES } from 'constants/origins';
import { useRootStore } from 'context/RootStoreContext';

const ANY = 0;
const ONLY_SUPER_ADMIN = 1;

const PatientAppSubMenuItems: Array<[key: string, access: typeof ONLY_SUPER_ADMIN | typeof ANY]> = [
  ['basic-details', ANY],
  ['alerts', ANY],
  ['offline-flow', ONLY_SUPER_ADMIN],
  ['customizations', ONLY_SUPER_ADMIN],
  ['text-content', ANY],
  // TODO: enable when backend for https://platform24.atlassian.net/browse/AX-7711 is ready
  // ['consents', ANY],
  ['search-terms', ANY],
  ['shutdown-menu', ANY],
];

const RulesSubmenu = [
  {
    key: ORIGIN_RULES_CATEGORIES.OPENING_HOURS,
    nameId: 'origin.rules.opening-hours',
  },
  {
    key: ORIGIN_RULES_CATEGORIES.CARE_UNIT,
    nameId: 'origin.rules.care-unit',
  },
  {
    key: ORIGIN_RULES_CATEGORIES.PRICE,
    nameId: 'origin.rules.prices',
  },
  {
    key: ORIGIN_RULES_CATEGORIES.RESOURCE,
    nameId: 'origin.rules.resource',
  },
  {
    key: ORIGIN_RULES_CATEGORIES.OTHER,
    nameId: 'origin.rules.other',
  },
];

// const accessibleOnlyBySuperAdmin = new Set(['offline-flow', 'customizations']);
const isAllowedToSeeTabs =
  (condition: boolean) => (item: [string, typeof ONLY_SUPER_ADMIN | typeof ANY]) =>
    condition || item[1] === ANY;

export const useSubMenu = ({ basePath }: { basePath: string }) => {
  const { userPermissionsStore, originStore } = useRootStore();
  const intl = useIntl();

  const { rules } = originStore.rulesStore;

  return useMemo(() => {
    const allowedToSeeTab =
      userPermissionsStore.isSuperAdmin || userPermissionsStore.isNewRoleSuperAdmin;

    const items = PatientAppSubMenuItems.filter(isAllowedToSeeTabs(allowedToSeeTab))
      .map(item => item[0])
      .map(itemName => ({
        url: `${basePath}/${itemName}`,
        id: `${basePath}/${itemName}`,
        label: intl.formatMessage({ id: `origins.${itemName}` }),
        key: itemName,
        'data-testid': itemName,
      }));

    if (rules.size && userPermissionsStore.isSuperAdmin) {
      return [
        ...items,
        {
          label: intl.formatMessage({ id: 'origin.rules' }),
          'data-testid': 'rules',
          id: `${basePath}/rules`,
          children: RulesSubmenu.filter(rule => rules.has(rule.key)).map(({ nameId, key }) => ({
            label: intl.formatMessage({ id: nameId }),
            id: `${basePath}/rules/${key}`,
            url: `${basePath}/rules/${key}`,
          })),
        },
      ];
    }

    return items;
  }, [
    basePath,
    intl,
    rules,
    userPermissionsStore.isNewRoleSuperAdmin,
    userPermissionsStore.isSuperAdmin,
  ]);
};

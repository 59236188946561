import { InfoCircleTwoTone } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useIntl } from 'react-intl';

export function SpecialOpeningHoursInfo() {
  const { formatMessage } = useIntl();
  return (
    <Tooltip title={formatMessage({ id: 'Special opening hours' })}>
      <InfoCircleTwoTone style={{ marginLeft: '.5rem' }} />
    </Tooltip>
  );
}

import { Select } from 'antd';
import { memo } from 'react';

import { OPENING_HOURS_SELECTED_STATUS } from '../constants';
import { useOpeningHourOptions } from '../openingHours.hooks';

interface OpeningHourStatusSelectProps {
  value?: OPENING_HOURS_SELECTED_STATUS;
  onChange?: (value: OPENING_HOURS_SELECTED_STATUS) => void;
}

export const OpeningHourStatusSelect = memo(function OpeningHourStatusSelectNotMemoized({
  onChange,
  value,
}: OpeningHourStatusSelectProps) {
  const options = useOpeningHourOptions();

  return (
    <Select
      allowClear={false}
      options={options}
      value={value}
      style={{ width: '6rem' }}
      onChange={onChange}
    />
  );
});

import { PlusOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Component, ContextType, Fragment } from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps as IntlComponentProps,
} from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import withPractitionerRolesOptions, {
  PractitionerRolesOptionsProps,
} from 'components/HOC/withPractitionerRolesOptions';
import withRootStoreProp, { RootStoreProps } from 'components/HOC/withRootStoreProp';
import { ROLE_MENUITEMS } from 'constants/permissions';
import { Config } from 'constants/practitioner';
import {
  DEFAULT_ADMINISTRATIVE_ROLE,
  DEFAULT_PRACTITIONER_RECEIVING_ROLE,
  ROLES,
} from 'constants/roles';
import RootStoreContext from 'context/RootStoreContext';
import AddPractitionerRoles from 'modules/Practitioner/PractitionerRoles/components/AddPractitionerRoles';
import { RoleInCareUnit } from 'modules/Practitioner/stores/PractitionerRolesStore';
import { displayCareUnitWithProviderName } from 'utils/role.utils';

import styles from './ClinicPersonalRoles.module.css';
import { ClinicPersonalRolesTree } from './ClinicPersonalRolesTree';
import { PractitionerRoleDefinition } from '../types';

interface Props
  extends RootStoreProps,
    PractitionerRolesOptionsProps,
    IntlComponentProps,
    RouteComponentProps<{ id: string }> {}

@observer
class ClinicPersonalRoles extends Component<Props> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  @observable newRoleDefinition?: PractitionerRoleDefinition;
  @observable activePractitionerRole?: RoleInCareUnit;

  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.initialize();
    }
  }

  async initialize() {
    const {
      match: { params },
    } = this.props;
    const { careUnitsStore, practitionerRolesStore, practitionerStore } = this.context;

    if (
      (isEmpty(practitionerStore.data) || params.id !== practitionerStore.data.id) &&
      !practitionerStore.isLoading()
    ) {
      await practitionerStore.fetch(params.id);
    }

    await careUnitsStore.fetchAllCareUnits();
    await practitionerRolesStore.initializeRoles(this.props.match.params.id);
  }

  @action
  handleClearActivePractitionerRole = () => {
    this.activePractitionerRole = undefined;
  };

  @computed
  get rolesSelectOptions() {
    const { rolesStore } = this.context;
    const role = this.newRoleDefinition?.role || this.activePractitionerRole?.role;

    if (!role) {
      return [];
    } else if (rolesStore.administrativeRoles.includes(role)) {
      return this.props.administrativeRolesOptions;
    } else {
      return this.props.resourceTypesOptions;
    }
  }

  @computed
  get careUnitSelectOptions() {
    const {
      userDataStore: userStore,
      rolesStore,
      careUnitsStore,
      userPermissionsStore,
    } = this.context;
    const role = this.newRoleDefinition?.role || this.activePractitionerRole?.role;

    // Practitioner can have only one patient receiving role in care unit.
    // Practitioner can have multiple administrative roles in the same CU, but it cannot be the same role.

    if (!role) {
      return [];
    } else if (rolesStore.administrativeRoles.includes(role)) {
      return userPermissionsStore.isSuperAdmin
        ? careUnitsStore.allCareUnitsAsSelectOptions
        : userStore.userCareUnitsAsSelectOptions;
    } else {
      const patientReceivingRoles = [
        ...this.userCareUnitsWithoutPatientReceivingRoleAssignedAsSelectOptions,
      ];

      if (this.activePractitionerRole) {
        const { careUnitId, careUnitName } = this.activePractitionerRole;
        const insertIndex = patientReceivingRoles.findIndex(
          ({ label }) => label.toLowerCase().localeCompare(careUnitName.toLowerCase()) > 0
        );
        patientReceivingRoles.splice(insertIndex, 0, {
          value: careUnitId,
          label: careUnitName,
        });
      }

      return patientReceivingRoles;
    }
  }

  @computed
  get userCareUnitsWithoutPatientReceivingRoleAssignedAsSelectOptions() {
    const { userDataStore: userStore, practitionerRolesStore } = this.context;

    return userStore.userCareUnitsAsSelectOptions.filter(
      cu => !practitionerRolesStore.patientReceivingRoles.some(role => role.careUnitId === cu.value)
    );
  }

  @computed
  get roleBasedCareUnitSelectOptions() {
    return this.context.userDataStore.roleBasedCareUnits.map(careUnit => ({
      value: careUnit.id,
      label: displayCareUnitWithProviderName(careUnit),
    }));
  }

  @computed
  get notAvailableCareUnitRoles() {
    // Practitioner can have multiple roles in the same CU, but it cannot be the same role
    const { rolesStore, practitionerRolesStore } = this.context;
    const role = this.newRoleDefinition?.role || this.activePractitionerRole?.role;

    if (!role || !rolesStore.administrativeRoles.includes(role)) {
      return [];
    }

    return practitionerRolesStore.administrativeRoles;
  }

  @action
  handleAddNewRole = async (role: ROLES) => {
    this.newRoleDefinition = {
      role,
      careUnitIds: [],
    };

    await this.getSelectableCareUnitsForClinicUserAdmin();
  };

  getSelectableCareUnitsForClinicUserAdmin = async () => {
    // Check if user has clinic_user_admin scoped role and then fetch care units from the new endpoint
    // else use the old way for showing  care units.
    const { userPermissionsStore, userDataStore } = this.context;
    const isClinicUserAdminOrAdmin = userPermissionsStore.getSideBarAccess(
      ROLE_MENUITEMS.CLINIC_USER_ADMIN,
      ROLE_MENUITEMS.SUPER_ADMIN
    );

    if (isClinicUserAdminOrAdmin) {
      await userDataStore.fetchCareUnitsBasedOnUserRole(ROLE_MENUITEMS.CLINIC_USER_ADMIN);
    }
  };

  @action
  handleClearNewRoleDefinition = () => {
    this.newRoleDefinition = undefined;
  };

  handleAddNewPractitionerRoles = async (practitionerRole: PractitionerRoleDefinition) => {
    const { practitionerRolesStore } = this.context;

    await practitionerRolesStore.addNewRoleV2(this.props.intl)(
      this.props.match.params.id,
      practitionerRole
    );

    this.handleClearNewRoleDefinition();
  };

  handleEditPractitionerRole = async (data: RoleInCareUnit) => {
    const { practitionerRolesStore } = this.context;
    await practitionerRolesStore.updatePractitionerRoleV2(this.props.match.params.id, data);
    this.handleClearActivePractitionerRole();
  };

  handleMenuClick = ({ key }: { key: string }) => this.handleAddNewRole(key as ROLES);

  render() {
    const { careUnitsStore, practitionerStore, partnersStore, rolesStore, userPermissionsStore } =
      this.context;
    const isLoading =
      careUnitsStore.isLoading() ||
      practitionerStore.isLoading() ||
      practitionerStore.isSaving() ||
      rolesStore.isLoading();
    const showRoleLock = partnersStore.partnerCustomizations.get(
      Config.ADMIN_CAN_LOCK_ROLES_FROM_AUTO_MANAGEMENT
    );
    const isClinicUserAdminORSuperAdmin = userPermissionsStore.getSideBarAccess(
      ROLE_MENUITEMS.CLINIC_USER_ADMIN,
      ROLE_MENUITEMS.SUPER_ADMIN
    );

    return (
      <Fragment>
        <div className={styles.header}>
          {isClinicUserAdminORSuperAdmin && (
            <Dropdown
              menu={{
                items: [
                  {
                    key: DEFAULT_PRACTITIONER_RECEIVING_ROLE,
                    label: (
                      <span data-testid="add-practitioner-role-menu">
                        <FormattedMessage id="add-roles-form.add-btn" /> -{' '}
                        <FormattedMessage id="practitioner-roles-form.practitioner-roles-header" />
                      </span>
                    ),
                    onClick: () =>
                      this.handleMenuClick({ key: DEFAULT_PRACTITIONER_RECEIVING_ROLE }),
                  },
                  {
                    key: DEFAULT_ADMINISTRATIVE_ROLE,
                    label: (
                      <span data-testid="add-administrative-role-menu">
                        <FormattedMessage id="add-roles-form.add-btn" /> -{' '}
                        <FormattedMessage id="practitioner-roles-form.administrative-roles-header" />
                      </span>
                    ),
                    onClick: () => this.handleMenuClick({ key: DEFAULT_ADMINISTRATIVE_ROLE }),
                  },
                ],
              }}
            >
              <Button
                icon={<PlusOutlined />}
                type="primary"
                data-testid="add-practitioner-role-button"
              >
                <FormattedMessage id="roles.add-clinic-role-button" />
              </Button>
            </Dropdown>
          )}
        </div>

        <ClinicPersonalRolesTree />

        <AddPractitionerRoles
          initialValues={this.newRoleDefinition}
          isSaving={isLoading}
          onCancel={this.handleClearNewRoleDefinition}
          onSubmit={this.handleAddNewPractitionerRoles}
          rolesOptions={this.rolesSelectOptions}
          careUnitsOptions={
            isClinicUserAdminORSuperAdmin
              ? this.roleBasedCareUnitSelectOptions
              : this.careUnitSelectOptions
          }
          notAvailableCareUnitRoles={this.notAvailableCareUnitRoles}
          showRoleLock={showRoleLock}
        />
      </Fragment>
    );
  }
}

export default withPractitionerRolesOptions(
  withRootStoreProp(injectIntl(withRouter(ClinicPersonalRoles)))
);

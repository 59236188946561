import { useQuery, useQueryClient } from '@tanstack/react-query';
import { App } from 'antd';
import { useFormikContext } from 'formik';
import { useAtomValue } from 'jotai';
import { useCallback, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT } from 'constants/general';

import { CARE_UNIT_SELECTED_ALL, OPENING_HOURS_SELECTED_STATUS } from './constants';
import {
  CareProviderInheritanceStatus,
  INHERITANCE_STATE,
  MODE,
  OnSubmitType,
  OpeningHoursForDate,
  OpeningHoursRegular,
  OpeningHoursSpecial,
  OpeningHoursWrappers,
} from './openingHours.types';
import { getClonedDataForUpdate, isSpecialHours } from './openingHours.utils';
import {
  fetchOpeningHours,
  fetchOpeningHoursForDate,
  fetchOpeningHoursInheritanceStatus,
  setOpeningHours,
} from '../../../api/openingHoursApi';
import RootStoreContext from '../../../context/RootStoreContext';
import { partnersAtoms } from '../../../state/partners';

function getQueryKey(mode: MODE, id: string) {
  return mode === MODE.CARE_UNIT ? ['careUnitOpeningHours', id] : ['careProviderOpeningHours', id];
}

function useCurrentIds(mode: MODE, id: string) {
  const careProviderId = useCurrentCareProviderId();
  const partnerId = useCurrentPartnerId();
  return useMemo(
    () =>
      mode === MODE.CARE_UNIT
        ? { careUnitId: id, careProviderId, partnerId }
        : { careProviderId: id, partnerId },
    [mode, id, careProviderId, partnerId]
  );
}

export const useInheritanceStatusQuery = (id: string) => {
  const ids = useCurrentIds(MODE.CARE_PROVIDER, id);
  return useQuery<CareProviderInheritanceStatus>({
    queryKey: ['careProviderInheritanceStatus', id],
    queryFn: () => fetchOpeningHoursInheritanceStatus(ids),
  });
};

export const useOpeningHoursQuery = (mode: MODE, id: string) => {
  const ids = useCurrentIds(mode, id);
  return useQuery({
    queryKey: getQueryKey(mode, id),
    queryFn: () => fetchOpeningHours(ids),
    retry: 1,
  });
};

export const useCurrentCareUnitId = () => {
  const { careUnitStore } = useContext(RootStoreContext);
  return careUnitStore.careUnit?.id || '';
};

export const useCurrentPartnerId = () => {
  return useAtomValue(partnersAtoms.currentPartner)!.id;
};

export const useCurrentCareProviderId = () => {
  const { careProviderId } = useParams<{ careProviderId: string }>();
  return careProviderId!;
};

export const useCurrentId = (mode: MODE) => {
  const careUnitId = useCurrentCareUnitId();
  const careProviderId = useCurrentCareProviderId();
  return mode === MODE.CARE_UNIT ? careUnitId : careProviderId;
};

export const useOpeningHoursQueryForCurrentId = (mode: MODE) => {
  const id = useCurrentId(mode);
  return useOpeningHoursQuery(mode, id);
};

export const useCurrentInheritanceState = <T extends OpeningHoursRegular | OpeningHoursSpecial>(
  mode: MODE
) => {
  const { data } = useOpeningHoursQueryForCurrentId(mode);
  const { values } = useFormikContext<T>();
  const isSpecial = isSpecialHours(values);
  const isInheritingFromQuery = isSpecial
    ? data?.release.detailedSpecialHourPeriodsWrapper.specialHoursInheritedByParent
    : data?.release.detailedRegularHoursWrapper.regularHoursInheritedByParent;
  const isInheritingInForm = isSpecial
    ? values.specialHoursInheritedByParent
    : values.regularHoursInheritedByParent;

  if (isInheritingFromQuery && isInheritingInForm) {
    return INHERITANCE_STATE.INHERITED_FROM_CARE_PROVIDER;
  }

  if (isInheritingFromQuery && !isInheritingInForm) {
    return INHERITANCE_STATE.SPECIFIC_PENDING;
  }

  return INHERITANCE_STATE.SPECIFIC_SAVED;
};

export const useOpeningHoursOnSubmit = (mode: MODE, id: string) => {
  const { notification } = App.useApp();
  const intl = useIntl();
  const queryClient = useQueryClient();
  const { data } = useOpeningHoursQuery(mode, id);
  const ids = useCurrentIds(mode, id);

  return useCallback<OnSubmitType>(
    async values => {
      const isSpecial = isSpecialHours(values);
      const openingHoursNewValues: OpeningHoursWrappers = {
        ...(data!.candidate || data!.release),
        ...(isSpecial
          ? { detailedSpecialHourPeriodsWrapper: values }
          : { detailedRegularHoursWrapper: values }),
      };
      try {
        await setOpeningHours(ids, getClonedDataForUpdate(openingHoursNewValues));
        notification.success({
          placement: 'top',
          message: isSpecial
            ? intl.formatMessage({ id: 'opening-hours.special.saved' })
            : intl.formatMessage({ id: 'opening-hours.regular.saved' }),
        });
        return queryClient.invalidateQueries(getQueryKey(mode, id));
      } catch (e: unknown) {
        if (e instanceof Error) {
          notification.error({
            placement: 'top',
            duration: DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT,
            message: e.message,
          });
        }
        throw e;
      }
    },
    [data, id, notification, queryClient, mode, ids]
  );
};

export const useOpeningHoursForDate = (careProviderId: string, date: Date) => {
  const ids = useCurrentIds(MODE.CARE_PROVIDER, careProviderId);
  return useQuery<OpeningHoursForDate[]>({
    queryKey: ['openingHoursForDate', ids, date],
    queryFn: () => fetchOpeningHoursForDate(ids, date),
  });
};

export function useCurrentCareUnits() {
  const currentCareProviderId = useCurrentCareProviderId();
  const careProviders = useAtomValue(partnersAtoms.careProviders);
  const careProvider = careProviders.find(cp => cp.id === currentCareProviderId);
  return careProvider?.careUnits || [];
}

export function useCurrentCareUnitsAsSelectOptions() {
  const { formatMessage } = useIntl();
  const careUnits = useCurrentCareUnits();
  return [
    {
      label: formatMessage({ id: 'general.all' }),
      value: CARE_UNIT_SELECTED_ALL,
    },
    ...careUnits.map(cu => ({ label: cu.name, value: cu.id })),
  ];
}

export function useOpeningHourOptions() {
  const { formatMessage } = useIntl();

  return [
    {
      label: formatMessage({ id: 'general.all' }),
      value: OPENING_HOURS_SELECTED_STATUS.ALL,
    },
    {
      label: formatMessage({ id: 'opening-hours.open' }),
      value: OPENING_HOURS_SELECTED_STATUS.OPEN,
    },
    {
      label: formatMessage({ id: 'opening-hours.closed' }),
      value: OPENING_HOURS_SELECTED_STATUS.CLOSED,
    },
  ];
}

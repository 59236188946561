import { LoadingOutlined, PicLeftOutlined } from '@ant-design/icons';
import { ManagePage } from '@platform24/admin-ui-utils';
import { Spin, Tabs } from 'antd';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { useAtomValue } from 'jotai';
import { observer } from 'mobx-react';
import { Fragment, useCallback, useEffect, useMemo, useRef } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import { AppBreadcrumbItem } from 'components/Breadcrumbs';
import withOnUnloadGuard, { OnUnloadGuardProps } from 'components/HOC/withOnUnloadGuard';
import RichTextEditor from 'components/RichTextEditor';
import { LANGS } from 'constants/enums';
import { RICH_TEXT_CONTENT_TYPES } from 'constants/text';
import { useRootStore } from 'context/RootStoreContext';
import StatusTag from 'modules/Content24/components/StatusTag';
import PartnerStatus from 'modules/PartnerStatus';

import styles from './EditSelfcare.module.css';
import { EditSelfCareControls } from './EditSelfCareControls';
import { textsHaveBlobUrls } from '../../../../components/RichTextEditor/utils';
import { content24Atoms } from '../../../../state/content24';
import { logError } from '../../../Analytics/utils';
import { useUploadNewContentImages } from '../../../Articles/api/articlesApi.hooks';
import { SelfCareAdvice } from '../../stores/SelfcareStore';
import { SELF_CARE_STATUS } from '../api/selfcareApi';
import { RenderPreview } from '../components/RenderPreview/RenderPreview';
import { wrapWithHtmlDocumentMeta } from '../utils/html';

interface EditSelfcareProps
  extends WrappedComponentProps,
    RouteComponentProps<{ id: string }>,
    OnUnloadGuardProps {}

const EditSelfcare = observer((props: EditSelfcareProps) => {
  const { selfcareStore } = useRootStore();
  const uploadNewContentImages = useUploadNewContentImages();
  const uploadedImagesNamesMapRef = useRef<Map<string, string> | undefined>(undefined);

  const breadcrumbs: AppBreadcrumbItem[] = useMemo(() => {
    return [
      {
        icon: <PicLeftOutlined />,
        text: <FormattedMessage id="main-navigation.content24" />,
      },
      {
        text: <FormattedMessage id="selfcare.list.header" />,
        link: '/content24/selfcare',
      },
      {
        text: selfcareStore.current?.id,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selfcareStore.current?.id]);

  useEffect(() => {
    const {
      match: {
        params: { id },
      },
      history,
    } = props;

    async function fetchSelfcareAdvice() {
      await selfcareStore.fetchSelfcareAdvice(id);

      if (!selfcareStore.current) {
        history.replace('/content24/selfcare');
      }
    }
    fetchSelfcareAdvice();

    return () => {
      selfcareStore.clearCurrentSelfCareAdvice();
    };
  }, [props.match.params.id, props.history, props, selfcareStore]);

  const setIsDraft = useCallback(
    (isDraft: boolean) => {
      props.updateIsDirty(isDraft);
    },
    [props]
  );

  const handleSubmit = useCallback(
    (values: SelfCareAdvice) => {
      async function submit() {
        const content =
          selfcareStore.selfCareLocalizedFile.type === RICH_TEXT_CONTENT_TYPES.HTML
            ? wrapWithHtmlDocumentMeta(values.text)
            : values.text;

        if (!selfcareStore.lang) {
          const error = 'Language is not set';
          logError(error, { type: 'invalid-state' });
          throw new Error(error);
        }

        let texts = {
          [selfcareStore.lang as string]: content,
        };

        if (textsHaveBlobUrls(texts)) {
          texts = await uploadNewContentImages(texts, uploadedImagesNamesMapRef.current);
        }
        await selfcareStore.handleSelfcareAdviceUpdate(
          texts[selfcareStore.lang as string],
          props.intl.formatMessage({ id: 'selfcare.data-saved' })
        );

        setIsDraft(false);
      }
      submit();
    },
    [selfcareStore, setIsDraft, uploadNewContentImages]
  );

  const handleSelfcareAdviceDelete = useCallback(() => {
    async function deleteFunc() {
      await selfcareStore.handleSelfcareAdviceDelete({
        successMessage: props.intl.formatMessage({ id: 'selfcare.data-deleted' }),
      });

      if (!selfcareStore.current) {
        props.history.replace('/content24/selfcare');
      }
    }
    deleteFunc();
  }, [props.history, selfcareStore]);

  const getContentStatusTag = useCallback(
    (lang: LANGS) => {
      const current = selfcareStore.current;

      if (!current) {
        return null;
      }

      const isLocalCopy =
        current.languageToStatus[lang] === SELF_CARE_STATUS.LOCAL &&
        current.status === SELF_CARE_STATUS.LOCAL;
      const isModified =
        current.languageToStatus[lang] === SELF_CARE_STATUS.LOCAL &&
        current.status !== SELF_CARE_STATUS.LOCAL;

      if (isLocalCopy) {
        return 'localCopy';
      }

      if (isModified) {
        return 'modified';
      }

      return null;
    },
    [selfcareStore]
  );

  const onUploadedImageNamesChange = useCallback(
    (uploadedImagesNamesMap: Map<string, string> | undefined) =>
      (uploadedImagesNamesMapRef.current = uploadedImagesNamesMap),
    []
  );

  const getOnEditorContentChangeCallback = useCallback(
    (setFieldValue: (fieldName: string, content: string) => void) => (content: string) => {
      setIsDraft(true);
      setFieldValue('text', content);
    },
    [setIsDraft]
  );

  const canEditSelfcareAdvice = useAtomValue(content24Atoms.canEditSelfcareAdvice);
  const availableLanguages = useAtomValue(content24Atoms.availableLanguages);

  const current = selfcareStore.current;
  const isLoading = selfcareStore.isLoading;
  const isSaving = selfcareStore.isSaving;
  const showPreview = selfcareStore.isPreviewMode;
  const isEditModeEnabled = canEditSelfcareAdvice && selfcareStore.lang;
  const isDeleteContentEnabled =
    isEditModeEnabled && current?.languageToStatus[selfcareStore.lang] === SELF_CARE_STATUS.LOCAL;

  return (
    <ManagePage
      headerProps={{
        title: <FormattedMessage id="selfcare.edit.header" />,
        breadcrumbs: { data: breadcrumbs },
        topRight: <PartnerStatus />,
      }}
    >
      <Fragment>
        {!selfcareStore.lang && <Spin size="large" indicator={<LoadingOutlined spin />} />}
        {!!selfcareStore.lang && (
          <Tabs
            defaultActiveKey={selfcareStore.lang}
            onChange={key => selfcareStore.handleLangTabChange(key as LANGS)}
            className={styles.tabs}
            tabBarExtraContent={
              // These controls have to be included twice, here and in the Formik form. That's because they:
              // a) depend on form state when editing the content (see Formik Form below),
              // b) are form independent when previewing content (see here).
              // In each of these cases they have a bit different config.
              isEditModeEnabled && showPreview ? (
                <EditSelfCareControls
                  onDelete={isDeleteContentEnabled ? handleSelfcareAdviceDelete : undefined}
                />
              ) : undefined
            }
            items={availableLanguages.map(lang => {
              const contentStatus = getContentStatusTag(lang);
              return {
                key: lang,
                disabled: isLoading || isSaving,
                label: (
                  <div className={styles.langTab}>
                    <FormattedMessage id={`general.language-${lang}`} />
                    {contentStatus && (
                      <StatusTag status={contentStatus} className={styles.statusTag} />
                    )}
                  </div>
                ),
                children: (
                  <>
                    {isLoading && <Spin size="large" indicator={<LoadingOutlined spin />} />}
                    {!isLoading && showPreview && (
                      <RenderPreview
                        type={selfcareStore.selfCareLocalizedFile.type}
                        text={selfcareStore.selfCareLocalizedFile.text}
                      />
                    )}
                    {!isLoading && !showPreview && (
                      <Formik
                        enableReinitialize
                        initialValues={selfcareStore.selfCareLocalizedFile}
                        onSubmit={handleSubmit}
                      >
                        {({ isValid, setFieldValue, values, dirty }) => (
                          <Form layout="vertical">
                            <EditSelfCareControls
                              onDelete={
                                isDeleteContentEnabled ? handleSelfcareAdviceDelete : undefined
                              }
                              isValid={isValid && dirty}
                            />
                            <Form.Item name="text">
                              <RichTextEditor
                                isDisabled={isSaving}
                                initialValue={values.text}
                                onChange={getOnEditorContentChangeCallback(setFieldValue)}
                                contentType={selfcareStore.selfCareLocalizedFile.type}
                                paragraphControls
                                imageControls
                                handleUploadedImageNamesChange={onUploadedImageNamesChange}
                                listStyleControls
                                inlineStyleControls
                                linkControls
                              />
                            </Form.Item>
                          </Form>
                        )}
                      </Formik>
                    )}
                  </>
                ),
              };
            })}
          />
        )}
      </Fragment>
    </ManagePage>
  );
});

export default withOnUnloadGuard(injectIntl(EditSelfcare));

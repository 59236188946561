import { apm } from '@elastic/apm-rum';
import errorParser from 'error-stack-parser';

export const logAPMError = (error: Error, customMeta?: Record<string, string>) => {
  if (apm.isActive()) {
    try {
      // since most of errors originate from axios and since apm-rum does not yet support "cause"
      // we need to manually parse the stack trace and remove the first line

      // "named export parse" is not working here because it uses "this" inside
      // eslint-disable-next-line import-x/no-named-as-default-member
      const p = errorParser.parse(new Error(''));
      const pp = p.slice(1).map(frame => frame.toString());
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (error as any).localStackTrace = pp.join('\n');

      Object.assign(error, customMeta);
    } catch (e) {
      console.log('e', e);
      console.error(e);
    }
    // apm.captureError(error);
    apm.captureError(error);
  }
};

import { Flex } from 'antd';

import { ItemIcon, ItemText } from './MyProfile.styled';

export const MyProfileItem = ({
  label,
  text,
  icon,
  className,
}: {
  label: string | JSX.Element;
  text?: string | JSX.Element;
  icon?: JSX.Element;
  className?: string;
}) => (
  <Flex className={className}>
    <Flex flex="0 0 200px" align="center">
      {icon && <ItemIcon>{icon}</ItemIcon>}
      <ItemText>{label}</ItemText>
    </Flex>
    <Flex align="center">
      <ItemText>{text}</ItemText>
    </Flex>
  </Flex>
);

import { notification } from 'antd';
import { AxiosResponse } from 'axios';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import { observable, computed, action, IObservableArray, when } from 'mobx';
import { IntlShape } from 'react-intl';

import {
  CreatePractionerRoleIT,
  ExtendedRole,
  createPractitionerRoleV2,
  editPractitionerRoleV2,
  archivePractitionerRoleV3,
  editPractitionerRoleCapabilities,
} from 'api/practitionerApi';
import { logAPMError } from 'config/ErrorTrackig';
import { DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT } from 'constants/general';
import { ROLES_KEYS, Config } from 'constants/practitioner';
import { ROLES } from 'constants/roles';
import { PractitionerRoleDefinition } from 'modules/Roles/components/types';
import { intlNotification } from 'state/notification';
import RootStore from 'stores/RootStore';
import { getPartnerIdByCareUnitIdGivenAllCareUnits } from 'utils/role.utils';
import { sortWithLocale } from 'utils/textUtils';

export interface RoleInCareUnit {
  role: ROLES;
  isPrimaryRole: boolean;
  lockedFromAutoManagement?: boolean;
  careUnitId: string;
  careUnitName: string;
  rowType: ClinicRoleRowType;
  roleId: string;
  capabilities?: string[];
  'data-testid'?: string;
}

interface CustomError extends AxiosResponse {
  response: {
    status: number;
    data: {
      type: string;
    };
  };
}

export enum ClinicRoleRowType {
  careUnit = 'careUnit',
  rolesGroup = 'rolesGroup',
  role = 'role',
}

export interface PractitionerRolesTreeRoleNode {
  id: string;
  role: ROLES;
  isPrimaryRole: boolean;
  capabilities?: string[];
  lockedFromAutoManagement?: boolean;
  careUnitId: string;
  careUnitName: string;
  rowType: ClinicRoleRowType.role;
  isAdministrative: boolean;
  roleId: string;
}

export interface PractitionerRolesTreeCareUnitNode {
  id: string;
  label: string;
  rowType: ClinicRoleRowType.careUnit;
  children: PractitionerRolesTreeRoleNode[];
}

export const isRoleNode = (node: PractitionerRoleTreeNode): node is PractitionerRolesTreeRoleNode =>
  node.rowType === ClinicRoleRowType.role;

export const isCareUnitNode = (
  node: PractitionerRoleTreeNode
): node is PractitionerRolesTreeCareUnitNode => node.rowType === ClinicRoleRowType.careUnit;

export type PractitionerRoleTreeNode =
  | PractitionerRolesTreeCareUnitNode
  | PractitionerRolesTreeRoleNode;

export interface StructuredRoleInCareUnit {
  id: string;
  label: string;
  rowType: ClinicRoleRowType;
  'data-testid'?: string;
  children: Array<StructuredRoleInCareUnit | RoleInCareUnit>;
}

class PractitionerRolesStore {
  @observable allRoles: IObservableArray<RoleInCareUnit> = observable.array([]);

  constructor(private rootStore: RootStore) {}

  @computed
  get patientReceivingRoles() {
    return this.allRoles.filter(role => this.filterRoles(role.role, ROLES_KEYS.RESOURCE_TYPES));
  }

  @computed
  get administrativeRoles() {
    return this.allRoles.filter(role =>
      this.filterRoles(role.role, ROLES_KEYS.ADMINISTRATIVE_ROLES)
    );
  }

  /**
   * function generates a map of extended roles for a practitioner with just care unit id
   * because one can not have more than one practitioner role in the same care unit
   * ie. if one has Physician role in care unit doctor24, one can not have another role say Nurse in the same care unit.
   */
  @computed
  get extendedPractitionerRolesMap() {
    const { practitionerStore, rolesStore } = this.rootStore;

    if (!practitionerStore.data.extendedRoles) {
      return {};
    }
    return practitionerStore.data.extendedRoles.reduce(
      (acc: Record<string, boolean>, role: ExtendedRole) => {
        if (!rolesStore.administrativeRoles.includes(role.role)) {
          acc[role.careUnitId] = true;
        }
        return acc;
      },
      {}
    );
  }

  /**
   * function generates a map of extended roles for an adminstrative role with just care unit id and role
   * because one can have more than one role different roles in the same care unit.
   * ie. one can have both scheduler and admin role in the same care unit say doctor24.
   * NB. One can not have scheduler and scheduler in the same care unit say doctor24.
   */
  @computed
  get extendedAdministrativeRolesMap() {
    const { practitionerStore, rolesStore } = this.rootStore;
    if (!practitionerStore.data.extendedRoles) {
      return {};
    }
    return practitionerStore.data.extendedRoles.reduce(
      (acc: Record<string, boolean>, role: ExtendedRole) => {
        if (rolesStore.administrativeRoles.includes(role.role)) {
          acc[`${role.role}:${role.careUnitId}`] = true;
        }
        return acc;
      },
      {}
    );
  }

  @computed
  get groupedByCareUnit(): StructuredRoleInCareUnit[] {
    const groupedByCareUnitId = groupBy(this.allRoles, 'careUnitId');

    return Object.entries(groupedByCareUnitId).map(([careUnitId, data]) => {
      const patientReceivingRoles = data
        .filter(role => this.filterRoles(role.role, ROLES_KEYS.RESOURCE_TYPES))
        .map(role => ({ ...role, 'data-testid': role.roleId }));
      const administrativeRoles = data
        .filter(role => this.filterRoles(role.role, ROLES_KEYS.ADMINISTRATIVE_ROLES))
        .map(role => ({ ...role, 'data-testid': role.roleId }));
      return {
        id: careUnitId,
        'data-testid': careUnitId,
        label: data[0].careUnitName,
        rowType: ClinicRoleRowType.careUnit,
        children: [
          ...(patientReceivingRoles.length
            ? [
                {
                  id: `practitioner-roles-${careUnitId}`,
                  label: 'practitioner-roles-form.practitioner-roles-header',
                  rowType: ClinicRoleRowType.rolesGroup,
                  children: patientReceivingRoles,
                },
              ]
            : []),
          ...(administrativeRoles.length
            ? [
                {
                  id: `administrative-roles-${careUnitId}`,
                  label: 'practitioner-roles-form.administrative-roles-header',
                  rowType: ClinicRoleRowType.rolesGroup,
                  children: administrativeRoles,
                },
              ]
            : []),
        ],
      };
    });
  }

  @computed
  get rolesGroupedByCareUnit(): PractitionerRolesTreeCareUnitNode[] {
    const groupedByCareUnitId = groupBy(this.allRoles, 'careUnitId');

    return Object.entries(groupedByCareUnitId).map(([careUnitId, data]) => {
      const careUnit: PractitionerRolesTreeCareUnitNode = {
        id: careUnitId,
        label: data[0].careUnitName,
        rowType: ClinicRoleRowType.careUnit,
        children: data.map(el => ({
          ...el,
          id: el.roleId,
          rowType: ClinicRoleRowType.role,
          isAdministrative: this.filterRoles(el.role, ROLES_KEYS.ADMINISTRATIVE_ROLES),
        })), // [...patientReceivingRoles, ...administrativeRoles],
      };

      return careUnit;
    });
  }

  initializeRoles = (practitionerId: string) => {
    const { practitionerStore } = this.rootStore;
    when(
      () => !!practitionerStore.data.id && practitionerStore.data.id === practitionerId,
      () => {
        this.allRoles = practitionerStore.data.extendedRoles
          .map(this.mapRoleInCareUnit)
          .sort((a: RoleInCareUnit, b: RoleInCareUnit) => sortWithLocale(a, b, 'careUnitName'));
      }
    );
  };

  @action
  disposeRoles = () => {
    this.allRoles.replace([]);
  };

  private getPartnerIdByCareUnitId = (careUnitId: string) => {
    const { careUnitsStore } = this.rootStore;
    return getPartnerIdByCareUnitIdGivenAllCareUnits(careUnitsStore.allCareUnits, careUnitId);
  };

  /**
   * At the moment can only edit capabilities.
   */
  editPractitionerRoleCapabilities = async (
    practitionerId: string,
    extendedRole: RoleInCareUnit
  ) => {
    const { practitionerStore } = this.rootStore;
    const { careUnitId, role, isPrimaryRole, lockedFromAutoManagement } = extendedRole;
    const payload = {
      careUnitId,
      role,
      isPrimaryRole,
      lockedFromAutoManagement,
    };

    try {
      console.log('fgvhbjnkdfkkddkkdkdkdjdolskdf', practitionerId, extendedRole);
      if (!extendedRole.capabilities) {
        throw new Error('Capabilities are required');
      }
      await editPractitionerRoleCapabilities(
        practitionerId,
        role,
        extendedRole.careUnitId,
        extendedRole.capabilities
      );
      // await editPractitionerRoleV2(practitionerId, role, payload, careUnitId);
      await practitionerStore.refetchPractitioner(practitionerId);
      intlNotification.success.top({
        frmMessage: { id: 'practitioner.roles-saved-message' },
      });
    } catch (e) {
      logAPMError(e as Error);
      const partnerId = this.getPartnerIdByCareUnitId(careUnitId);
      intlNotification.error.top(
        {
          frmMessage: {
            id: 'practitioner.errors.principal-has-access-by-switching-partner',
          },
          values: {
            id: partnerId,
          },
        },
        {
          duration: DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT,
        }
      );
    } finally {
      this.initializeRoles(practitionerId);
      practitionerStore.isLoaded();
    }
  };

  /**
   * For clinician (personal) roles use "editPractitionerClinicianRole"
   */
  updatePractitionerRoleV2 = async (practitionerId: string, extendedRole: RoleInCareUnit) => {
    const { practitionerStore } = this.rootStore;
    const { careUnitId, role, isPrimaryRole, lockedFromAutoManagement } = extendedRole;
    const payload = {
      careUnitId,
      role,
      isPrimaryRole,
      lockedFromAutoManagement,
    };

    try {
      practitionerStore.isLoading();
      await editPractitionerRoleV2(practitionerId, role, payload, careUnitId);
      await practitionerStore.refetchPractitioner(practitionerId);
      intlNotification.success.top({
        frmMessage: { id: 'practitioner.roles-saved-message' },
      });
    } catch (e) {
      logAPMError(e as Error);
      const partnerId = this.getPartnerIdByCareUnitId(careUnitId);
      intlNotification.error.top(
        {
          frmMessage: {
            id: 'practitioner.errors.principal-has-access-by-switching-partner',
          },
          values: {
            id: partnerId,
          },
        },
        {
          duration: DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT,
        }
      );
    } finally {
      this.initializeRoles(practitionerId);
      practitionerStore.isLoaded();
    }
  };

  archivePractitionerExtendedRoleV3 = async (
    practitionerId: string,
    role: string,
    careUnitId: string
  ) => {
    const { practitionerStore } = this.rootStore;
    try {
      practitionerStore.isLoading();
      await archivePractitionerRoleV3(practitionerId, role, careUnitId);
      await practitionerStore.refetchPractitioner(practitionerId);
      intlNotification.success.top({
        frmMessage: {
          id: 'roles.role-removed',
        },
      });
    } catch (e) {
      const error = e as unknown as CustomError;
      if (error.response.status === 400 && error.response.data.type === '1006') {
        // user has access to delete care unit by switching to the care unit in question.
        intlNotification.error.top(
          {
            frmMessage: {
              id: 'practitioner.errors.principal-has-access-by-switching-partner',
            },
            values: {
              id: this.getPartnerIdByCareUnitId(careUnitId),
            },
          },
          {
            duration: DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT,
          }
        );
      } else if (error.response.status === 403 && error.response.data.type === '1007') {
        // user does not have access to delete role in the care unit in question
        intlNotification.error.top(
          {
            frmMessage: {
              id: 'practitioner.errors.principal-does-not-have-access',
            },
          },
          {
            duration: DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT,
          }
        );
      } else {
        intlNotification.error.top(
          {
            frmMessage: {
              id: 'general.error',
            },
          },
          {
            duration: DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT,
          }
        );
      }
    } finally {
      this.initializeRoles(practitionerId);
      practitionerStore.isLoaded();
    }
  };

  displayDeletedCareUnitErrorMessage = (careUnitIds: string[], intl: IntlShape) => {
    // 1. get deleted care unit name and id from extendedRole/allRoles
    // 2. show the error message displaying the names of care units and id as per previous implementation.

    // will save id of care unit whose error message has been displayed to prevent duplicate messages for same care unit id.
    const deletedCareUnitIds = new Set();

    for (const role of this.allRoles) {
      if (careUnitIds.includes(role.careUnitId) && !deletedCareUnitIds.has(role.careUnitId)) {
        deletedCareUnitIds.add(role.careUnitId);
        notification.error({
          message: intl.formatMessage({
            id: 'practitioner.errors.no-care-unit-found',
          }),
          duration: 0,
          placement: 'top',
        });
      }
    }
  };

  addNewRoleV2 =
    (intl: IntlShape) =>
    async (practitionerId: string, practitionerRole: PractitionerRoleDefinition) => {
      const { partnersStore, practitionerStore } = this.rootStore;
      const { careUnitIds, role, lockedFromAutoManagement, capabilities } = practitionerRole;
      if (!role) {
        return;
      }
      const isAutoLock = partnersStore.partnerCustomizations.get(
        Config.ADMIN_CAN_LOCK_ROLES_FROM_AUTO_MANAGEMENT
      );
      const payload: CreatePractionerRoleIT = {
        careUnitIds,
        role,
        isPrimaryRole: false,
        lockedFromAutoManagement: isAutoLock ? lockedFromAutoManagement : undefined,
        capabilities,
      };

      try {
        await createPractitionerRoleV2(practitionerId, payload);
        await practitionerStore.refetchPractitioner(practitionerId);
        notification.success({
          placement: 'top',
          message: intl.formatMessage({
            id: 'roles.role-added',
          }),
        });
      } catch (error) {
        const err = error as unknown as CustomError;
        if (err.response.status === 400 && err.response.data.type === '1000') {
          notification.error({
            placement: 'top',
            message: intl.formatMessage({
              id: 'practitioner.capabilies-saved-error-message',
            }),
          });
        } else {
          practitionerStore.manageException(error);
        }
      } finally {
        this.initializeRoles(practitionerId);
        practitionerStore.isLoaded();
      }
    };

  getFilteredRoles = (type: ROLES_KEYS) => {
    switch (type) {
      case ROLES_KEYS.ADMINISTRATIVE_ROLES:
        return this.administrativeRoles;
      case ROLES_KEYS.RESOURCE_TYPES:
        return this.patientReceivingRoles;
      default:
        return [];
    }
  };

  mapRoleInCareUnit = (extendedRole: ExtendedRole): RoleInCareUnit => ({
    role: extendedRole.role,
    rowType: ClinicRoleRowType.role,
    isPrimaryRole: extendedRole.isPrimaryRole,
    lockedFromAutoManagement: extendedRole.lockedFromAutoManagement,
    careUnitId: get(extendedRole, 'careUnitId', ''),
    careUnitName: get(extendedRole, 'careUnitName', ''),
    roleId: extendedRole.id,
    capabilities: extendedRole.capabilities,
  });

  filterRoles = (role: ROLES, type: ROLES_KEYS) => this.rootStore.rolesStore[type].includes(role);
}

export default PractitionerRolesStore;

import { Select } from 'antd';
import { type BaseOptionType } from 'antd/es/select';
import { memo, useCallback } from 'react';

import { useCurrentCareUnitsAsSelectOptions } from '../openingHours.hooks';

interface CareUnitSelectProps {
  value?: string;
  onChange?: (value: string) => void;
}

export const CareUnitSelect = memo(function CareUnitSelectNotMemoized({
  value,
  onChange,
}: CareUnitSelectProps) {
  const options = useCurrentCareUnitsAsSelectOptions();
  const handleFilterSort = useCallback((optionA: BaseOptionType, optionB: BaseOptionType) => {
    const labelA: string = optionA?.label ?? '';
    const labelB: string = optionB?.label ?? '';
    const lowerA = labelA.toLowerCase();
    const lowerB = labelB.toLowerCase();

    return lowerA.localeCompare(lowerB);
  }, []);

  return (
    <Select
      options={options}
      value={value}
      style={{ width: '13rem' }}
      onChange={onChange}
      optionFilterProp="label"
      filterSort={handleFilterSort}
      showSearch
    />
  );
});

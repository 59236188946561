import { useQuery } from '@tanstack/react-query';

import { fetchPractitioner } from 'api/practitionerApi';
import { fetchUser } from 'api/userApi';

export const useCurrentUser = () => {
  return useQuery({
    queryKey: ['currentUser'],
    queryFn: () => fetchUser().then(res => res.data),
  });
};

export const useCurrentUserWithRoles = () => {
  const currentUser = useCurrentUser();

  const roles = useQuery({
    queryKey: ['currentUserRoles'],
    queryFn: () => fetchPractitioner(currentUser.data?.id || '').then(res => res.data),
    enabled: !!currentUser.data,
  });

  return roles;
};

import { MedicineBoxOutlined } from '@ant-design/icons';
import { ManagePage, useRouterSubmenu } from '@platform24/admin-ui-utils';
import { Observer } from 'mobx-react';
import { Fragment, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams, Switch, Route, Redirect } from 'react-router-dom';

import { useProviderAndUnitBreadcrambs } from 'modules/CareUnits/hooks/useProviderAndUnitBreadcrambs';
import PartnerStatus from 'modules/PartnerStatus';

import { Spinner } from '../../components/Spinner';
import { useRootStore } from '../../context/RootStoreContext';
import { CareProviderAnnouncements } from '../Announcements/careProvider/CareProviderAnnouncements';
import { OpeningHoursCareProvider } from '../CareUnits/OpeningHours';

export const CareProvider = () => {
  const { careProviderId } = useParams<{ careProviderId: string }>();
  const { careUnitsStore, partnersStore } = useRootStore();
  const intl = useIntl();

  const subRoutes = useMemo(() => {
    const routes = ['announcements'];
    if (partnersStore.partnerCustomizations.get('ENABLE_OPENING_HOURS_IN_ADMIN')) {
      routes.push('opening-hours');
    }
    return routes;
  }, [partnersStore.partnerCustomizations]);

  useEffect(() => {
    if (careProviderId && careUnitsStore.currentCareProvider?.id !== careProviderId) {
      careUnitsStore.fetchCareProvider(careProviderId);
    }
  }, [
    careUnitsStore.currentCareProvider,
    careUnitsStore.fetchCareProvider,
    careProviderId,
    careUnitsStore,
  ]);

  const links = useMemo(
    () =>
      subRoutes.map(route => ({
        url: `/my-health-care-organizations/${careProviderId}/${route}`,
        id: `/my-health-care-organizations/${careProviderId}/${route}`,
        label: intl.formatMessage({ id: `care-units.${route}` }),
      })),
    [careProviderId, intl, subRoutes]
  );

  const breadcrumbs = useProviderAndUnitBreadcrambs(
    careUnitsStore.currentCareProvider?.id,
    careUnitsStore.currentCareProvider?.name
  );

  const subMenu = useRouterSubmenu({ items: links });

  return (
    <Observer>
      {() => {
        if (careUnitsStore.isLoading()) {
          return <Spinner />;
        }

        return (
          <Fragment>
            <ManagePage
              headerProps={{
                title: (
                  <span>
                    <FormattedMessage id="Care provider" />:{' '}
                    {careUnitsStore.currentCareProvider?.name}
                  </span>
                ),
                icon: <MedicineBoxOutlined />,
                iconBG: 'var(--clinic-old-colors-do-not-use-magenta-7, #C41D7F)',
                breadcrumbs,
                topRight: <PartnerStatus />,
              }}
              subMenu={subMenu}
            >
              <>
                <Switch>
                  <Route
                    path="/my-health-care-organizations/:careProviderId/opening-hours"
                    component={OpeningHoursCareProvider}
                  />
                  <Route
                    path="/my-health-care-organizations/:careProviderId/announcements"
                    component={CareProviderAnnouncements}
                  />
                  <Redirect
                    from="/my-health-care-organizations/:careProviderId"
                    to="/my-health-care-organizations/:careProviderId/announcements"
                  />
                </Switch>
              </>
            </ManagePage>
          </Fragment>
        );
      }}
    </Observer>
  );
};

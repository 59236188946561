import { PullRequestOutlined } from '@ant-design/icons';
import { Button, Badge, Alert } from 'antd';
import { FunctionComponent, useContext } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import RootStoreContext from 'context/RootStoreContext';

import styles from './PartnerStatusBar.module.css';

interface Props extends WrappedComponentProps {
  onLogHistoryClick: () => void;
  onChangesClick: () => void;
}

const PartnerStatusBar: FunctionComponent<Props> = ({ intl, onChangesClick }) => {
  const {
    partnerStatusStore: { partnerGitStatus, isMergeRequestPending, isGitStatusLoaded },
  } = useContext(RootStoreContext);

  return (
    <div>
      <div className={styles.wrapper}>
        <div className={styles.flexWrapper}>
          <div>
            {isMergeRequestPending && isGitStatusLoaded && (
              <Alert
                className={styles.customAlert}
                message={intl.formatMessage({ id: 'partner-status.tag.under-review' })}
                type="warning"
                showIcon
              />
            )}
          </div>
          <Button
            style={{ marginLeft: 10 }}
            icon={<PullRequestOutlined />}
            className={styles.button}
            onClick={onChangesClick}
          >
            <span>
              <FormattedMessage id="partner-status.changes" />
            </span>
            <Badge count={partnerGitStatus.changesCount} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(PartnerStatusBar);

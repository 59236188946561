import { observable, action, computed } from 'mobx';
import { IntlShape } from 'react-intl';

import RootStore from 'stores/RootStore';

import {
  MergeRequest,
  PARTNER_STATUS,
  ConfigChange,
  UpdateMergeRequestDTO,
} from '../api/partnerStatusApi';

export enum PARTNER_STATUS_TABS {
  LOG_HISTORY = 'LOG_HISTORY',
  CHANGES = 'CHANGES',
}

export interface CurrentPartnerStatus {
  when?: string;
  status: PARTNER_STATUS;
  mergeRequestId?: number;
  description?: string;
  changes: ConfigChange[];
}

export default class PartnerStatusViewStore {
  @observable isStatusDrawerVisible = false;
  @observable activeTab = PARTNER_STATUS_TABS.LOG_HISTORY;
  @observable isApprovalModalVisible = false;
  @observable activeLogItem: MergeRequest | null = null;

  constructor(private rootStore: RootStore) {}

  @computed
  get currentPartnerStatus(): CurrentPartnerStatus | null {
    const { partnerStatusStore, mergeRequestStore } = this.rootStore;

    if (partnerStatusStore.isMergeRequestPending && mergeRequestStore.currentMergeRequest) {
      return {
        status: PARTNER_STATUS.PENDING,
        changes: mergeRequestStore.currentMergeRequest.changes,
        description: mergeRequestStore.currentMergeRequest.description,
      };
    } else if (partnerStatusStore.partnerStatus) {
      return {
        status: partnerStatusStore.partnerStatus.status,
        changes: partnerStatusStore.partnerStatus.changes,
      };
    } else {
      return null;
    }
  }

  handleSaveMergeRequest = (intl: IntlShape) => async (data: CurrentPartnerStatus) => {
    const saveData = {
      description: data.description || '',
    };

    if (this.rootStore.partnerStatusStore.isMergeRequestPending) {
      this.updateMergeRequest(saveData, intl);
    } else {
      this.createMergeRequest(saveData, intl);
    }
  };

  handleTabChange = (activeKey: string) => {
    const { mergeRequestStore } = this.rootStore;

    if (activeKey === PARTNER_STATUS_TABS.LOG_HISTORY) {
      mergeRequestStore.fetchMergeRequestsHistory();
    }
    if (activeKey === PARTNER_STATUS_TABS.CHANGES) {
      this.initChangesTab();
    }
  };

  handleShowDrawerOnLogHistoryTab = () => {
    this.showStatusDrawer(PARTNER_STATUS_TABS.LOG_HISTORY);
    this.rootStore.mergeRequestStore.fetchMergeRequestsHistory();
  };

  handleShowDrawerOnChangesTab = () => {
    this.showStatusDrawer(PARTNER_STATUS_TABS.CHANGES);
    this.initChangesTab();
  };

  initChangesTab = async () => {
    const { mergeRequestStore, partnerStatusStore } = this.rootStore;

    await partnerStatusStore.fetchPartnerStatus();

    if (
      partnerStatusStore.isMergeRequestPending &&
      partnerStatusStore.partnerStatus &&
      !mergeRequestStore.currentMergeRequest
    ) {
      mergeRequestStore.fetchMergeRequest(
        partnerStatusStore.partnerStatus.mergeRequestId as number
      );
    }
  };

  createMergeRequest = async (data: UpdateMergeRequestDTO, intl: IntlShape) => {
    await this.rootStore.mergeRequestStore.createMergeRequest(data, intl);
    this.hideApprovalModal();
  };

  updateMergeRequest = async (data: UpdateMergeRequestDTO, intl: IntlShape) => {
    await this.rootStore.mergeRequestStore.updateCurrentMergeRequest(data, intl);
    this.hideApprovalModal();
  };

  @action
  showStatusDrawer = (key: PARTNER_STATUS_TABS) => {
    this.activeTab = key;
    this.isStatusDrawerVisible = true;
  };

  @action
  hideStatusDrawer = () => {
    this.isStatusDrawerVisible = false;
  };

  @action
  setActiveTab = (key: PARTNER_STATUS_TABS) => {
    this.activeTab = key;
  };

  @action
  showApprovalModal = () => {
    this.isApprovalModalVisible = true;
  };

  @action
  hideApprovalModal = () => {
    this.isApprovalModalVisible = false;
  };

  @action
  showLogItemDetails = (mergeRequest: MergeRequest) => {
    this.activeLogItem = mergeRequest;
  };

  @action
  hideLogItemDetails = () => {
    this.activeLogItem = null;
  };
}

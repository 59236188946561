import { Tag } from 'antd';
import { useIntl } from 'react-intl';

interface OpeningHoursDependencyTagProps {
  isConnected: boolean;
}

export function OpeningHoursDependencyTag({ isConnected }: OpeningHoursDependencyTagProps) {
  const { formatMessage } = useIntl();
  return (
    <Tag color={isConnected ? 'blue' : 'gold'}>
      {isConnected ? formatMessage({ id: 'Connected' }) : formatMessage({ id: 'Standalone' })}
    </Tag>
  );
}

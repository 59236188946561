import { DownOutlined, SolutionOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Flex, Space, Table, Tag } from 'antd';
import { useAtom } from 'jotai';
import { observer } from 'mobx-react';
import { TdHTMLAttributes, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as HospitalOutlined } from 'assets/svg/HospitalOutlined.svg';
import { ReactComponent as Stetoscope } from 'assets/svg/Stetoscope.svg';
import { Config } from 'constants/practitioner';
import { ROLES_DESCRIPTION_KEY } from 'constants/roles';
import { useRootStore } from 'context/RootStoreContext';
import {
  isCareUnitNode,
  isRoleNode,
  PractitionerRolesTreeCareUnitNode,
  PractitionerRoleTreeNode,
} from 'modules/Practitioner/stores/PractitionerRolesStore';

import styles from './ClinicPersonalRolesTree.module.css';
import { useExpandRows, useGetExpandRows } from './hooks/useExpandRows';
import { CapabilitiesColumn } from './parts/CapabilitiesColumn';
import { DeletePersonalRole } from './parts/DeletePersonalRole';
import { EditPersonalRole } from './parts/EditPersonalRole';
import { LockPersonalRole } from './parts/LockPersonalRole';
import { partnerScopeCustomizationAtom } from '../hooks/partnerScopeCustomizationAtom';

const columns = [
  {
    title: <FormattedMessage id="roles.role" />,
    dataIndex: 'careUnitName',
    render: (_: string, record: PractitionerRoleTreeNode) => {
      if (isCareUnitNode(record)) {
        return (
          <Space align="center">
            <HospitalOutlined
              className={styles.careUnitIconHospital}
              width={16}
              viewBox="0 0 24 25"
            />
            {record.label}
          </Space>
        );
      }

      return (
        <Space align="center">
          <div className={styles.roleGhostIcon} />
          {ROLES_DESCRIPTION_KEY[record.role] ? (
            <FormattedMessage id={ROLES_DESCRIPTION_KEY[record.role]} />
          ) : (
            record.role
          )}
        </Space>
      );
    },
  },
  {
    title: <FormattedMessage id="practitioner-roles-form.capabilities-label" />,
    dataIndex: 'capabilities',
    render: (_: string, record: PractitionerRoleTreeNode) => {
      if (isCareUnitNode(record)) {
        return null;
      }

      return <CapabilitiesColumn role={record} />;
    },
  },
  {
    title: <FormattedMessage id="Type" />,
    dataIndex: 'role',
    minWidth: 200,
    render: (_: string, record: PractitionerRoleTreeNode) => {
      if ('role' in record) {
        const isAdministrative = record.isAdministrative;

        if (isAdministrative) {
          return (
            <Tag icon={<SolutionOutlined />}>
              <FormattedMessage id="practitioner.administrator" />
            </Tag>
          );
        }

        return (
          <Tag
            icon={
              <span className="anticon">
                <Stetoscope width={12} height={12} viewBox="0 0 12 12" />
              </span>
            }
          >
            <FormattedMessage id="practitioner.practitioner" />
          </Tag>
        );
      }
      return null;
    },
  },
  {
    title: <FormattedMessage id="practitioner-roles-form.exemption-label" />,
    dataIndex: 'exemptFromAutomaticHandling',
    width: 140,
    render: (_: string, record: PractitionerRoleTreeNode) => {
      if (isCareUnitNode(record)) {
        return null;
      }
      return <LockPersonalRole role={record} />;
    },
  },
  {
    title: <FormattedMessage id="general.actions" />,
    dataIndex: 'actions',
    width: 130,
    render: (_: boolean, record: PractitionerRoleTreeNode) => {
      if (isRoleNode(record)) {
        return (
          <Space>
            {!record.isAdministrative && <EditPersonalRole role={record} />}
            <DeletePersonalRole role={record} />
          </Space>
        );
      }

      return (
        <div style={{ textAlign: 'right' }}>
          <Expand role={record} />
        </div>
      );
    },
  },
];

const Expand = ({ role }: { role: PractitionerRolesTreeCareUnitNode }) => {
  const { expanded, toggle } = useExpandRows();
  const isExpanded = expanded.includes(role.id);
  const onExpand = useCallback(() => {
    toggle(role.id);
  }, [role.id, toggle]);
  return (
    <Button type="link" icon={isExpanded ? <UpOutlined /> : <DownOutlined />} onClick={onExpand} />
  );
};

const rowStyle = (record: PractitionerRoleTreeNode) => {
  if (isCareUnitNode(record)) {
    return styles['care-unit-row'];
  }

  return styles['role-row'];
};

const onRow = (record: PractitionerRoleTreeNode) => {
  if (isCareUnitNode(record)) {
    return {
      'data-testid': `care-unit-row-${record.id}`,
      'data-care-unit-id': record.id,
      'data-row-type': 'care-unit',
    } as TdHTMLAttributes<HTMLElement>;
  }

  return {
    'data-testid': `role-row-${record.roleId}`,
    'data-role-id': record.id,
    'data-role-type': record.isAdministrative ? 'administrative' : 'practitioner',
    'data-role': record.role,
    'data-care-unit-id': record.careUnitId,
    'data-row-type': 'role',
  } as TdHTMLAttributes<HTMLElement>;
};

export const ClinicPersonalRolesTreeComponent = () => {
  const { practitionerRolesStore, partnersStore } = useRootStore();

  const { Provider: ExpandProvider, expanded } = useGetExpandRows(
    practitionerRolesStore.rolesGroupedByCareUnit
  );

  const [customizations] = useAtom(partnerScopeCustomizationAtom);
  const enableCapabilities = !!customizations[Config.ENABLE_ASSIGNING_CAPABILITIES_IN_MANAGE];

  const visibleColumns = useMemo(() => {
    const skipColumns: string[] = [];
    if (
      !partnersStore.partnerCustomizations.get(Config.ADMIN_CAN_LOCK_ROLES_FROM_AUTO_MANAGEMENT)
    ) {
      skipColumns.push('exemptFromAutomaticHandling');
    }

    if (!enableCapabilities) {
      skipColumns.push('capabilities');
    }

    return columns.filter(column => !skipColumns.includes(column.dataIndex));
  }, [enableCapabilities, partnersStore.partnerCustomizations]);

  return (
    <ExpandProvider>
      <Table<PractitionerRoleTreeNode>
        columns={visibleColumns}
        expandable={{
          expandedRowKeys: expanded,
          showExpandColumn: false,
        }}
        rowKey="id"
        onRow={onRow}
        data-testid="practitioner-role-table"
        dataSource={practitionerRolesStore.rolesGroupedByCareUnit}
        rowHoverable={false}
        pagination={false}
        rowClassName={rowStyle}
      />
    </ExpandProvider>
  );
};

export const ClinicPersonalRolesTree = observer(ClinicPersonalRolesTreeComponent);

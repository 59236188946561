import { registerEventProperties, DEFAULT_SCOPE } from '@platform24/analytics';
import { computed, observable, action, IObservableArray, runInAction, autorun } from 'mobx';

import { CareUnit } from 'api/careUnitsApi';
import { PractitionerCareUnit } from 'api/practitionerApi';
import { User, fetchUser, fetchUserCareUnits, fetchUserCareUnitsV2 } from 'api/userApi';
import { ROLE_MENUITEMS } from 'constants/permissions';
import { IDENTITIES } from 'constants/practitioner';
import { sortWithLocale } from 'utils/textUtils';

import StateManager from './abstractStores/StateManager';

export default class UserDataStore extends StateManager {
  @observable
  givenName?: string;

  @observable
  middleAndSurname?: string;

  @observable
  careUnit?: CareUnit;

  @observable
  id?: string;

  @observable
  externalId?: string;

  @observable
  externalIdType?: IDENTITIES;

  @observable
  userCareUnits: IObservableArray<PractitionerCareUnit> = observable.array([]);

  @observable
  roleBasedCareUnits: IObservableArray<PractitionerCareUnit> = observable.array([]);

  @computed
  get initials() {
    const givenName = this.givenName || '';
    const middleAndSurname = this.middleAndSurname || '';
    return `${givenName.charAt(0)}${middleAndSurname.charAt(0)}`.toUpperCase();
  }

  constructor() {
    super();

    autorun(() => {
      const id = this.id;
      if (id) {
        registerEventProperties(DEFAULT_SCOPE, { userId: id });
      }
    });
  }

  @computed
  get fullName() {
    return `${this.givenName} ${this.middleAndSurname}`;
  }

  @computed
  get careUnitId() {
    return this.careUnit && this.careUnit.id;
  }

  @computed get isNorwegianUser() {
    return this.externalIdType === IDENTITIES.NORWEGIAN_PERSONAL_IDENTITY_NUMBER;
  }

  fetchUser = async () => {
    this.setLoading();

    try {
      const { data } = await fetchUser();

      this.setUserProps(data);

      this.setLoaded();
    } catch (e) {
      this.setError();
      throw e;
    }
  };

  fetchUserCareUnits = async ({ force } = { force: false }) => {
    if (!force && this.userCareUnits.length > 0) {
      return;
    }

    this.setLoading();

    try {
      const { data: careUnits } = await fetchUserCareUnits();

      runInAction(() => {
        this.userCareUnits.replace(careUnits);
      });

      this.setLoaded();
    } catch {
      this.setError();
    }
  };

  fetchCareUnitsBasedOnUserRole = async (role: ROLE_MENUITEMS) => {
    this.setLoading();

    try {
      const { data: careUnits } = await fetchUserCareUnitsV2(role);

      runInAction(() => {
        this.roleBasedCareUnits.replace(careUnits);
      });

      this.setLoaded();
    } catch {
      this.setError();
    }
  };

  @computed
  get userCareUnitsAsSelectOptions() {
    return this.userCareUnits
      .map(careUnit => ({
        value: careUnit.id,
        label: careUnit.name || '',
      }))
      .sort((a, b) => sortWithLocale(a, b, 'label'));
  }

  @action
  setUserProps = (user: User) => {
    this.givenName = user.givenName;
    this.middleAndSurname = user.middleAndSurname;
    this.externalIdType = user.externalIdType;
    this.externalId = user.externalId;
    this.careUnit = user.careUnit;
    this.id = user.id;
  };
}

import { Tabs } from 'antd';
import { FormattedMessage } from 'react-intl';

import ClinicPersonalRoles from '../ClinicPersonalRoles';
import ManagePersonalRoles from '../ManagePersonalRoles';

const PersonalRoles = () => {
  const tabs = [
    {
      key: '1',
      label: (
        <span data-testid="clinic-tab">
          <FormattedMessage id="roles.clinic24" />
        </span>
      ),
      children: <ClinicPersonalRoles />,
    },
    {
      key: '2',
      label: (
        <span data-testid="manage-tab">
          <FormattedMessage id="roles.manage24" />
        </span>
      ),
      children: <ManagePersonalRoles />,
    },
  ];

  return <Tabs data-testid="rolesTabs" items={tabs} />;
};

export default PersonalRoles;

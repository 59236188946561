import { ApartmentOutlined, MedicineBoxOutlined } from '@ant-design/icons';
import { ManagePageProps } from '@platform24/admin-ui-utils';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { ReactComponent as HealthcareOutlined } from 'assets/svg/HealthcareOutlined.svg';
import { ReactComponent as HospitalOutlined } from 'assets/svg/HospitalOutlined.svg';
import { useRootStore } from 'context/RootStoreContext';

const HO = styled(HospitalOutlined).attrs({ viewBox: '0 0 24 24' })`
  width: 1rem;
  height: 1rem;
  margin-bottom: -3px;
`;

export const useProviderAndUnitBreadcrambs = (
  careProviderId?: string,
  careProviderName?: string,
  careUnitName?: string
): ManagePageProps['headerProps']['breadcrumbs'] => {
  const { partnersStore } = useRootStore();
  return useMemo(
    () => ({
      data: [
        {
          icon: (
            <HealthcareOutlined
              style={{ marginBottom: -2, transform: 'scale(1.4)', marginRight: '0.125rem' }}
            />
          ),
          text: <FormattedMessage id="health-care" />,
        },
        {
          icon: <ApartmentOutlined />,
          text: partnersStore.currentPartner?.id ?? '',
          link: '/my-health-care-organizations',
        },
        careProviderName &&
          careProviderId && {
            icon: <MedicineBoxOutlined />,
            text: careProviderName,
            link: `/my-health-care-organizations/${careProviderId}`,
          },
        careUnitName && {
          icon: <HO />,
          text: careUnitName,
        },
      ].filter(Boolean) as any,
    }),
    [careProviderId, careProviderName, careUnitName, partnersStore.currentPartner?.id]
  );
};

import { format, getHours, getMinutes } from 'date-fns';
import { observable, action, runInAction, IObservableArray, toJS } from 'mobx';
import { IntlShape } from 'react-intl';

import { notification } from 'services/NotificationService';
import RootStore from 'stores/RootStore';
import { DATE_INPUT_FORMAT, getDateTimestamp, getTimestampParts } from 'utils/dateUtils';

import {
  NewsItem,
  NewsItemPayload,
  PartnerId,
  fetchNewsItems,
  updateNewsItems,
  createNewsItem,
  deleteNewsItem,
  reorderNewsItems,
} from '../api/newsItemsApi';

export const MAX_TIME_VALUES = {
  HOURS: 23,
  MINUTES: 59,
};

const transformToNewsItems = (data: NewsItemPayload[]) => {
  return data.map(({ subject, body, link, id, startsAt, endsAt }) => {
    const { date: startDate, hour: startHour, minute: startMinute } = getTimestampParts(startsAt);

    const {
      date: endDate,
      hour: endHour,
      minute: endMinute,
    } = endsAt
      ? getTimestampParts(endsAt)
      : { date: startDate, hour: startHour, minute: startMinute };

    return {
      subject,
      body,
      link,
      id,
      startDate,
      startHour,
      startMinute,
      endDate,
      endHour,
      endMinute,
      enableEndDate: !!endsAt,
    };
  });
};

const createNewsItemPayload = (newsItem: NewsItem) => {
  const {
    startDate,
    startHour,
    startMinute,
    endDate,
    endHour,
    endMinute,
    enableEndDate,
    subject,
    body,
    link,
    id,
  } = newsItem;

  return {
    subject,
    body,
    link,
    id,
    startsAt: getDateTimestamp(startDate, startHour, startMinute),
    ...(enableEndDate && endDate ? { endsAt: getDateTimestamp(endDate, endHour, endMinute) } : {}),
  };
};

export default class AlertsStore {
  @observable isLoading = false;
  newsItems: IObservableArray<NewsItem> = observable.array([]);

  @observable activeItem?: NewsItem;

  constructor(
    private rootStore: RootStore,
    private intl: IntlShape,
    private originId: string,
    private partnerId: PartnerId = rootStore.partnersStore.currentPartner?.id
  ) {}

  @action
  fetchNewsItems = async () => {
    try {
      this.isLoading = true;
      const { data } = await fetchNewsItems(this.originId, this.partnerId);
      const newsItem = transformToNewsItems(data);

      runInAction(() => {
        this.newsItems.replace(newsItem);
      });
      /* eslint-disable no-empty */
    } catch {
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  handleReordering = async (newsItems: NewsItem[]) => {
    runInAction(() => {
      this.isLoading = true;
    });

    try {
      runInAction(() => {
        this.newsItems.replace(newsItems);
      });

      const data = newsItems.map((item, index) => {
        return {
          newsId: item.id,
          rank: index + 1,
        };
      });

      await reorderNewsItems(data, this.originId, this.partnerId);
      notification.success({
        placement: 'top',
        message: this.intl.formatMessage({ id: 'origin.alerts.alerts-updated' }),
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  handleCreateData = async (newsItem: NewsItemPayload) => {
    runInAction(() => {
      this.isLoading = true;
    });

    try {
      await createNewsItem(newsItem, this.originId, this.partnerId);
      const { data } = await fetchNewsItems(this.originId, this.partnerId);
      const fetchedNewsItem = transformToNewsItems(data);

      runInAction(() => {
        this.newsItems.replace(fetchedNewsItem);
      });
      notification.success({
        placement: 'top',
        message: this.intl.formatMessage({ id: 'patient-app.alert.new-alert-added' }),
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  handleEditData = async (newsItem: NewsItem) => {
    runInAction(() => {
      this.isLoading = true;
    });

    try {
      const newNewsItems = toJS(this.newsItems);
      const newNewsItemIndex = this.newsItems.findIndex(item => item.id === newsItem.id);

      if (newNewsItemIndex === -1) {
        throw new Error(`No item with index ${newNewsItemIndex}`);
      }

      const payload = createNewsItemPayload(newsItem);
      await updateNewsItems(payload, this.originId, newsItem.id, this.partnerId);

      newNewsItems[newNewsItemIndex] = newsItem;

      runInAction(() => {
        this.newsItems.replace(newNewsItems);
      });

      notification.success({
        placement: 'top',
        message: this.intl.formatMessage({ id: 'patient-app.alert.alert-edit' }),
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  handleDeleteData = async (id: string) => {
    runInAction(() => {
      this.isLoading = true;
    });

    try {
      const newNewsItems = this.newsItems.filter(item => item.id !== id);

      await deleteNewsItem(id, this.originId, this.partnerId);

      runInAction(() => {
        this.newsItems.replace(newNewsItems);
      });

      notification.success({
        placement: 'top',
        message: this.intl.formatMessage({ id: 'patient-app.alert.alert-removed' }),
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action
  handleEdit = (id: string) => {
    this.activeItem = this.newsItems.find(item => item.id === id);
  };

  @action
  handleAdd = () => {
    const now = new Date();
    const today = format(now, DATE_INPUT_FORMAT);
    const startHour = getHours(now);
    const startMinute = getMinutes(now);

    let endDate = today;
    let endHour = startHour;
    let endMinute = startMinute + 1;

    if (startMinute === MAX_TIME_VALUES.MINUTES) {
      endMinute = 0;
      if (endHour < MAX_TIME_VALUES.HOURS) {
        endHour = startHour + 1;
      } else {
        endHour = 0;
        endDate = format(new Date(now.setDate(now.getDate() + 1)), DATE_INPUT_FORMAT);
      }
    }

    this.activeItem = {
      subject: '',
      body: '',
      link: '',
      id: '',
      startDate: today,
      startHour: startHour,
      startMinute: startMinute,
      endDate: endDate,
      endHour: endHour,
      endMinute: endMinute,
      enableEndDate: false,
    };
  };

  @action
  handleCancel = () => {
    this.activeItem = undefined;
  };

  handleSubmit = async (newNewsItem: NewsItem, formType: string) => {
    Object.entries(newNewsItem).forEach(([key, value]) => {
      if (value === '') {
        newNewsItem[key] = null;
      }
    });

    try {
      if (formType === 'add') {
        const payload = createNewsItemPayload(newNewsItem);
        await this.handleCreateData(payload);
      } else {
        await this.handleEditData(newNewsItem);
      }
      runInAction(() => {
        this.activeItem = undefined;
      });
      /* eslint-disable no-empty */
    } catch {}
  };
}

import {
  BugOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  InfoCircleOutlined,
  LogoutOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { styled } from 'styled-components';

import { useRootStore } from 'context/RootStoreContext';

import { MainNavigationMenuItem } from './MainNavigationMenuItem';
import { useIsMainNavigationCollapsed, useToggleMainNavigation } from '../MainNavigationContext';

const PushDown = styled.div`
  margin-top: auto;
`;

const Avatar = styled(UserOutlined)`
  background-color: var(--clinic-old-colors-do-not-use-cyan-8);
  color: white !important;
  border-radius: 100%;
  padding: 0.3rem;
  font-size: 0.9rem;
  margin: 0 -2px 0 -3px;
  svg {
    transform: scale(0.9);
  }
`;

export const BottomMenu = () => {
  const { userDataStore } = useRootStore();
  const { authStore } = useRootStore();
  const history = useHistory();

  const location = useLocation();

  const logout = useCallback(() => {
    authStore.logout();
    history.push('/login');
  }, [authStore, history]);

  const toggleCollapsed = useToggleMainNavigation();

  const isCollapsed = useIsMainNavigationCollapsed();

  const crashApp = useCallback(() => {
    throw new Error('Crash app');
  }, []);

  const goToAbout = useCallback(() => {
    history.push('/about');
  }, [history]);

  return (
    <>
      <PushDown tabIndex={-1} />
      <MainNavigationMenuItem
        linkTo="/my-profile"
        tabIndex={-1}
        icon={<Avatar />}
        label={userDataStore.fullName}
      />

      <MainNavigationMenuItem
        onClick={goToAbout}
        onEnterPress={goToAbout}
        tabIndex={0}
        role="menuitem"
        selected={location.pathname === '/about'}
        icon={<InfoCircleOutlined />}
        label={<FormattedMessage id="main-navigation.about" />}
      />

      <MainNavigationMenuItem
        onClick={toggleCollapsed}
        onEnterPress={toggleCollapsed}
        tabIndex={0}
        role="menuitem"
        icon={isCollapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
        label={
          isCollapsed ? (
            <FormattedMessage id="main-navigation.expand" />
          ) : (
            <FormattedMessage id="main-navigation.collapse" />
          )
        }
      />

      <MainNavigationMenuItem
        onClick={logout}
        onEnterPress={logout}
        tabIndex={0}
        role="menuitem"
        icon={<LogoutOutlined />}
        label={<FormattedMessage id="main-navigation.logout" />}
        data-testid="main-navigation-logout"
      />

      {localStorage.getItem('enable_crash_button') && (
        <MainNavigationMenuItem onClick={crashApp} icon={<BugOutlined />} label="crash app" />
      )}
    </>
  );
};

import {
  CloseOutlined,
  DownloadOutlined,
  ExpandAltOutlined,
  LoadingOutlined,
  PicLeftOutlined,
} from '@ant-design/icons';
import { ManagePage } from '@platform24/admin-ui-utils';
import { Button, Col, List, Row, Spin, Alert, notification } from 'antd';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component, ContextType, Fragment, RefObject } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import Breadcrumbs, { AppBreadcrumbItem } from 'components/Breadcrumbs';
import LanguageSwitcher from 'components/LanguageSwitcher';
import { DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT } from 'constants/general';
import RootStoreContext from 'context/RootStoreContext';
import { withNavigationCollapseControl } from 'stores/NavigationCollapse';

import Diagram from './components/Diagram';
import Exits from './components/Exits';
import FullScreenVisualization from './components/FullScreenVisualization/FullScreenVisualization';
import Hexagon from './components/shapes/Hexagon';
import Rectangle from './components/shapes/Rectangle';
import styles from './ConditionVisualization.module.css';
import { MARGIN } from './constants';
import { NodeData } from './stores/ConditionVisualizationStore';

/**
 * @notExported
 */
interface ConditionDiagramProps extends RouteComponentProps<{ id: string }>, WrappedComponentProps {
  collapseNavigation: () => void;
}

@observer
class ConditionDiagram extends Component<ConditionDiagramProps> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  @observable isExpanded = false;

  diagramRef: RefObject<HTMLDivElement>;
  diagramForPngDownloadRef: RefObject<SVGSVGElement>;

  private get group() {
    const { conditionStore, content24Store } = this.context;
    const { condition } = conditionStore;
    const { groupCategories } = content24Store;
    return groupCategories.get(condition.metadata.level1id) || '';
  }

  private get breadcrumbs() {
    const { conditionStore, content24Store } = this.context;
    const { condition } = conditionStore;
    const { groupCategories } = content24Store;
    const basePath: AppBreadcrumbItem[] = [
      {
        icon: <PicLeftOutlined />,
        text: <FormattedMessage id="main-navigation.content24" />,
      },
      {
        text: <FormattedMessage id="condition-list.header" />,
      },
    ];

    if (!condition.metadata.conditionId || !groupCategories.size) {
      return basePath;
    }

    return basePath.concat([
      {
        text: (
          <FormattedMessage
            id={this.group ? `condition-list.category-${this.group}` : 'general.unknown'}
          />
        ),
        link: this.group ? `/content24/questionnaires?category=${this.group}` : undefined,
      },
      {
        text: this.context.conditionVisualizationStore.getTitle(this.props.intl.locale),
        link: `/content24/questionnaires/${condition.metadata.conditionId}`,
      },
      {
        text: <FormattedMessage id="condition.visualization.title" />,
      },
    ]);
  }

  constructor(props: ConditionDiagramProps) {
    super(props);
    this.diagramRef = React.createRef();
    this.diagramForPngDownloadRef = React.createRef();
  }

  componentDidMount() {
    this.context.conditionVisualizationStore.initalize(this.props.match.params.id);
  }

  @action
  handleExpand = () => {
    this.isExpanded = !this.isExpanded;

    if (this.isExpanded) {
      this.props.collapseNavigation();
    }
  };

  handleClearSelectionClick = () => {
    this.context.conditionVisualizationStore.clearFocusedElementId();
    this.context.conditionVisualizationStore.clearSelectedNodeId();
    this.context.conditionVisualizationStore.setNodeNavigation(false);
  };

  handleExitClick = (exit: NodeData) => {
    const { setSelectedNodeId, setFocusedElementId } = this.context.conditionVisualizationStore;
    setSelectedNodeId(exit.id);
    this.context.conditionVisualizationStore.setFocusedExit(exit);

    if (exit.dependencies && exit.dependencies.length > 0) {
      const firstDependency = exit.dependencies[0];
      if (firstDependency) {
        setFocusedElementId(firstDependency.questionId);
      }
    }
  };

  handleDependencyClick = (id: string) => {
    this.context.conditionVisualizationStore.setFocusedElementId(id);
  };

  handlePngDownload = () => {
    if (this.diagramForPngDownloadRef.current?.getBBox()) {
      const { x, y, width, height } = this.diagramForPngDownloadRef.current.getBBox();
      const imageOptions = {
        encoderOptions: 1,
        scale: 1.5,
        backgroundColor: 'white',
        width: width + MARGIN.left + MARGIN.right,
        height: height + MARGIN.top + MARGIN.bottom,
        left: x - MARGIN.left,
        top: y - MARGIN.top,
      };

      import('save-svg-as-png')
        .then(({ saveSvgAsPng }) =>
          saveSvgAsPng(
            this.diagramForPngDownloadRef.current,
            `diagram-${this.context.conditionStore.conditionId || ''}.png`,
            imageOptions
          )
        )
        .catch(err => {
          console.error(err);
          notification.error({
            message: this.props.intl.formatMessage({
              id: 'condition.visualization.png-download-error',
            }),
            duration: DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT,
            placement: 'top',
          });
        });
    }
  };

  render() {
    const { conditionVisualizationStore, content24Store } = this.context;
    const isLoading =
      this.context.conditionStore.isLoading() || conditionVisualizationStore.isLoading;
    const isVisualizationDataAvailable = conditionVisualizationStore.questionsAndGotos?.length > 0;
    const originLanguagesOptions = content24Store.availableLanguages.map(lang => ({
      value: lang,
      label: (this.props.intl.messages[`general.language-${lang}`] || lang).toString(),
    }));
    const { couldNotBuildNonFormulaCount } = conditionVisualizationStore.conditionVisualization;

    return (
      <ManagePage
        headerProps={{
          breadcrumbs: { data: this.breadcrumbs },
          topRight: (
            <div className={styles.headerButtons}>
              <LanguageSwitcher
                activeLanguage={conditionVisualizationStore.activeLanguage}
                languages={originLanguagesOptions}
                onChange={conditionVisualizationStore.handleLanguageChange}
                className={styles.languageSwitcher}
              />
              <Button
                type="primary"
                shape="round"
                onClick={this.handlePngDownload}
                loading={isLoading}
                disabled={!isVisualizationDataAvailable}
              >
                <DownloadOutlined />
                <span>
                  <FormattedMessage id="condition.visualization.download-png" />
                </span>
              </Button>
            </div>
          ),
        }}
      >
        <Fragment>
          <Row gutter={16}>
            <Col span={6}>
              {!this.isExpanded && !isLoading && isVisualizationDataAvailable && (
                <Exits
                  exits={conditionVisualizationStore.exits}
                  height={window.innerHeight}
                  onExitClick={this.handleExitClick}
                  onDependencyClick={this.handleDependencyClick}
                  selectedNodeId={conditionVisualizationStore.selectedNodeId}
                  conditionId={this.context.conditionStore.conditionId}
                />
              )}
            </Col>
            <Col span={14}>
              {!isLoading && couldNotBuildNonFormulaCount > 0 && (
                <Alert
                  message={this.props.intl.formatMessage({
                    id: 'condition.visualization.information',
                  })}
                  description={this.props.intl.formatMessage(
                    { id: 'condition.visualization.statements-not-shown' },
                    { count: couldNotBuildNonFormulaCount }
                  )}
                  type="warning"
                  showIcon
                  className={styles.notification}
                />
              )}

              <div ref={this.diagramRef} className={styles.diagramContainer}>
                {isLoading ? (
                  <div className={styles.spinContainer}>
                    <Spin size="large" indicator={<LoadingOutlined spin />} />
                  </div>
                ) : (
                  !this.isExpanded &&
                  this.diagramRef.current &&
                  isVisualizationDataAvailable && (
                    <Fragment>
                      <div className={styles.diagramTopRight}>
                        {conditionVisualizationStore.selectedNodeId && (
                          <Button
                            shape="round"
                            onClick={this.handleClearSelectionClick}
                            icon={<CloseOutlined />}
                            loading={isLoading}
                            disabled={!isVisualizationDataAvailable}
                            className={styles.clearSelectionBtn}
                          >
                            <span>
                              <FormattedMessage id="condition.visualization.clear-selection" />
                            </span>
                          </Button>
                        )}
                        <div className={styles.expandBtn} onClick={this.handleExpand}>
                          <ExpandAltOutlined className={styles.expandBtnIcon} />
                        </div>
                      </div>
                      <Diagram
                        width={this.diagramRef.current.offsetWidth}
                        height={window.innerHeight}
                        conditionId={this.context.conditionStore.conditionId}
                        selectedNodeId={conditionVisualizationStore.selectedNodeId}
                        focusedElementId={conditionVisualizationStore.focusedElementId}
                        showMiniMap
                      />
                    </Fragment>
                  )
                )}
              </div>
            </Col>
            <Col span={4}>
              <List
                className={styles.listSection}
                header={
                  <div>
                    <FormattedMessage id="condition.visualization.legend.title" />
                  </div>
                }
                dataSource={[
                  {
                    shape: <Hexagon width={59} height={20} type="goto" />,
                    text: <FormattedMessage id="condition.visualization.legend.goto" />,
                  },
                  {
                    shape: <Rectangle width={59} height={20} type="question" />,
                    text: <FormattedMessage id="condition.visualization.legend.question" />,
                  },
                  {
                    shape: <Rectangle width={59} height={20} type="answerOption" />,
                    text: <FormattedMessage id="condition.visualization.legend.answer-option" />,
                  },
                  {
                    shape: <Hexagon width={59} height={20} type="breakingExit" />,
                    text: <FormattedMessage id="condition.visualization.legend.breaking-exit" />,
                  },
                  {
                    shape: <Hexagon width={59} height={20} type="exit" />,
                    text: <FormattedMessage id="condition.visualization.legend.exit" />,
                  },
                  // TODO: Hidden because not ready yet
                  // { shape: <Arrow width={59} type="positive" />, text: 'True' },
                  // { shape: <Arrow width={59} type="negative" />, text: 'False' },
                ]}
                renderItem={item => (
                  <List.Item className={styles.listItem}>
                    <div>{item.text}</div> {item.shape}
                  </List.Item>
                )}
              />
            </Col>
          </Row>
          <FullScreenVisualization
            handleExpand={this.handleExpand}
            handleClearSelectionClick={this.handleClearSelectionClick}
            handleExitClick={this.handleExitClick}
            handleDependencyClick={this.handleDependencyClick}
            isExpanded={this.isExpanded}
          />
          {!isLoading && isVisualizationDataAvailable && (
            <div className={styles.diagramForPngDownload}>
              <Diagram
                width={1000}
                height={1000}
                conditionId={this.context.conditionStore.conditionId}
                showExits
                innerRef={this.diagramForPngDownloadRef}
              />
            </div>
          )}
        </Fragment>
      </ManagePage>
    );
  }
}

export default injectIntl(withNavigationCollapseControl(ConditionDiagram));

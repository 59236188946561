import { useAtomValue } from 'jotai';

import { ROLE_MENUITEMS } from 'constants/permissions';
import { userPermissionsAtoms } from 'state/userPermissions';
import {
  userPermissionsPrivateAtoms,
  userPermissionsSelectors,
} from 'state/userPermissions/userPermissions.selectors';

export const useHasDomainAccess = (menuRoleAccess: ROLE_MENUITEMS[]) => {
  // const { userPermissionsStore } = useRootStore();
  const menuAccess = useAtomValue(userPermissionsPrivateAtoms.menuAccess);
  const isSuperAdmin = useAtomValue(userPermissionsAtoms.isSuperAdmin);

  return userPermissionsSelectors.checkForDomainAccess(menuRoleAccess, menuAccess, isSuperAdmin);
};

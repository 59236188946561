import { GlobalOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from 'styled-components';

import { LANGS } from 'constants/enums';
import { useLang } from 'context/IntlContext';
import { getValueOfFeatureFlag } from 'utils/appUtils';

import { Content2, CustomSelect, Title } from './MyProfile.styled';
import { MyProfileItem } from './ProfileItem';

const CustomProfileItem = styled(MyProfileItem)`
  height: 56px;
`;

export const Language = () => {
  const { switchLang, lang } = useLang();

  const isDanishEnabled = getValueOfFeatureFlag('FF_ENABLE_DANISH_TRANSLATION');
  const languageOptions = useMemo(
    () =>
      Object.values(LANGS).filter(language =>
        !isDanishEnabled && language === LANGS.DA ? false : true
      ),
    [isDanishEnabled]
  );

  return (
    <Content2>
      <Title>
        <FormattedMessage id="basic-details-form.settings" />
      </Title>
      <CustomProfileItem
        label={<FormattedMessage id="basic-details-form.language" />}
        text={
          <CustomSelect tabIndex={0} value={lang} onChange={switchLang}>
            {languageOptions.map(option => (
              <Select.Option key={option} value={option}>
                <FormattedMessage id={`general.language-original-${option}`} />
              </Select.Option>
            ))}
          </CustomSelect>
        }
        icon={<GlobalOutlined />}
      />
    </Content2>
  );
};

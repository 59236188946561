import { PlusOutlined, TeamOutlined } from '@ant-design/icons';
import { ManagePage } from '@platform24/admin-ui-utils';
import { Layout, Button } from 'antd';
import type { TablePaginationConfig, SorterResult, FilterValue } from 'antd/es/table/interface';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import PartnerStatus from 'modules/PartnerStatus';

import Filters from './components/Filters';
import UsersTable from './components/UsersTable';
import { useFilterStorage } from './hooks/useFilterStorage';
import { normalizeUsers } from './hooks/usersNormaliser';
import { useUserFilter } from './hooks/useUserFilter';
import { getPayload, isEmptyFilters, mapFilterToQuery } from './hooks/utils';
import { FilterProps, UserFilter, UserTableDataType } from './types';
import styles from './users.module.css';

const { Content } = Layout;

const defaultPaginationValues = { pageSize: 20, total: 20 };

const Users = () => {
  const history = useHistory();
  const intl = useIntl();
  const [pagination, setPagination] = useState<TablePaginationConfig>({ current: 1, pageSize: 20 });
  const [sorter, setSorter] = useState<SorterResult<UserTableDataType>>({});
  const { setFilters, clearFilters, selectedFilters } = useFilterStorage();
  const { mutateAsync, isLoading, data, isError } = useUserFilter();

  const goToCreateUser = () => {
    history.push('/roles/add');
  };

  const handleFilter = React.useCallback(
    async (filterProps: FilterProps) => {
      const mappedFilters = mapFilterToQuery(filterProps);
      setFilters(mappedFilters);
      const payload = getPayload(mappedFilters, sorter, pagination.pageSize);
      const emptyFilters = isEmptyFilters(mappedFilters);
      if (!emptyFilters) {
        await mutateAsync(payload);
        setPagination({ ...pagination, current: 1 });
      }
    },
    [mutateAsync, getPayload, sorter, pagination.pageSize, isEmptyFilters]
  );

  const onRow = React.useCallback((record: UserTableDataType) => {
    return {
      'data-testid': record.key,
      'data-row-key': record.key,
    };
  }, []);

  const handlePagination = React.useCallback(
    async (
      currentPagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      currentSorter: SorterResult<UserTableDataType> | SorterResult<UserTableDataType>[]
    ) => {
      const { current, pageSize } = currentPagination;
      const filterProps = {
        ...selectedFilters,
        pagination: {
          limit: pageSize,
          offset: ((current || 1) - 1) * (pageSize || defaultPaginationValues.pageSize),
        },
      } as UserFilter;

      if (isEmptyFilters(filterProps)) {
        return;
      }

      if (pageSize !== pagination.pageSize) {
        await mutateAsync(getPayload(filterProps, sorter, pageSize));
        setPagination({ ...currentPagination, current: 1 });
      } else if (current !== pagination.current) {
        await mutateAsync(
          getPayload(filterProps, sorter, pageSize, filterProps?.pagination?.offset)
        );
        setPagination(currentPagination);
      } else if (
        !Array.isArray(currentSorter) &&
        JSON.stringify(currentSorter) !== JSON.stringify(sorter)
      ) {
        await mutateAsync(getPayload(filterProps, currentSorter, pageSize));
        setSorter(currentSorter as SorterResult<UserTableDataType>);
      }
    },
    [mutateAsync, pagination, sorter]
  );

  const handleClear = React.useCallback(() => {
    clearFilters();
  }, []);

  const isEmptyFilter = isEmptyFilters(selectedFilters);
  const isEmptyData = data && data?.slice?.length < 1;
  const isEmptyState = isError || isEmptyFilter || isEmptyData;
  return (
    <ManagePage
      headerProps={{
        title: <FormattedMessage id="main-navigation.practitioners" />,
        topRight: <PartnerStatus />,
      }}
    >
      <Content>
        <Button
          data-testid="add-new-user"
          className={styles.addUserButton}
          icon={<PlusOutlined />}
          type="primary"
          onClick={goToCreateUser}
        >
          <FormattedMessage id="Add new user" />
        </Button>
        <Filters onFilter={handleFilter} onClear={handleClear} initialValues={selectedFilters} />
        <UsersTable
          data={isEmptyState ? [] : normalizeUsers(data?.slice)}
          loading={isLoading}
          onChange={handlePagination}
          isNetworkError={isError}
          isEmptyData={isEmptyData}
          isEmptyFilter={isEmptyFilter}
          meta={{
            total: isEmptyState ? 0 : data?.totalCount || 0,
            pageSize: pagination.pageSize || defaultPaginationValues.pageSize,
            current: isEmptyState ? undefined : pagination.current || 1,
            showTotal: () => intl.formatMessage({ id: 'total-items' }, { total: data?.totalCount }),
          }}
          onRow={onRow}
        />
      </Content>
    </ManagePage>
  );
};
export default Users;

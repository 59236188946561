import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { ROLE_MENUITEMS } from 'constants/permissions';
import { useRootStore } from 'context/RootStoreContext';
import { useHasDomainAccess } from 'modules/AccessControl/useHasDomainAccess';
import { PractitionerRolesTreeRoleNode } from 'modules/Practitioner/stores/PractitionerRolesStore';
import { usePractitionerContext } from 'modules/Roles/context/PractitionerContext';

const useDeleteRole = (role: PractitionerRolesTreeRoleNode) => {
  const { practitionerId } = usePractitionerContext();
  const { practitionerRolesStore } = useRootStore();

  return useCallback(async () => {
    await practitionerRolesStore.archivePractitionerExtendedRoleV3(
      practitionerId,
      role.role,
      role.careUnitId
    );
  }, [practitionerId, practitionerRolesStore, role.careUnitId, role.role]);
};

export const DeletePersonalRole = ({ role }: { role: PractitionerRolesTreeRoleNode }) => {
  const { userDataStore } = useRootStore();

  const hasAccess = useHasDomainAccess([ROLE_MENUITEMS.CLINIC_USER_ADMIN]);

  const hasCareUnit = !!userDataStore.userCareUnitsAsSelectOptions.find(
    cu => cu.value === role.careUnitId
  );

  const canDelete = hasAccess || hasCareUnit;

  const onDelete = useDeleteRole(role);

  if (!canDelete) {
    return null;
  }

  return (
    <div>
      <Popconfirm
        title={<FormattedMessage id="general.sure-to-delete" />}
        cancelText={<FormattedMessage id="general.cancel" />}
        onConfirm={onDelete}
      >
        <Button
          type="link"
          icon={<DeleteOutlined style={{ color: 'red' }} />}
          size="small"
          data-action="delete"
          data-testid={`delete-btn-${role.roleId}`}
        />
      </Popconfirm>
    </div>
  );
};

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

interface Capability {
  name: string;
  id: string;
  capabilities?: Capability[];
}

export const useCapabilities = ({ enabled = true }: { enabled?: boolean } = {}) => {
  return useQuery({
    queryKey: ['capabilities'],
    cacheTime: 1000 * 60 * 60,
    // capabilities do not change, so we do not need to refetch them
    staleTime: 1 / 0,
    queryFn: () =>
      axios.get('/rest/admin/config/capabilities').then(({ data }) => {
        return data?.capabilities?.map((capability: Capability) => ({
          label: capability.name,
          value: capability.id,
        }));
      }) as Promise<{ label?: string; value: string }[]>,
    enabled,
  });
};

import {
  eachDayOfInterval,
  endOfWeek,
  format as formatFns,
  parse,
  startOfWeek,
  parseISO,
  setHours,
  setMinutes,
  getTime,
  getHours,
  getMinutes,
} from 'date-fns';
import { zipObject } from 'lodash';

const now = new Date();

export const TIME_FORMAT_LONG = 'HH:mm:ss';
export const TIME_FORMAT_SHORT = 'HH:mm';
export const DATE_DISPLAY_FORMAT = 'dd/MM/yy';
export const DATE_INPUT_FORMAT = 'yyyy-MM-dd';

const weekDayNames = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
] as const;

export function getWeekDays() {
  return eachDayOfInterval({
    start: startOfWeek(now),
    end: endOfWeek(now),
  }).map(d => formatFns(d, 'EEEE'));
}

export function getWeekDayName(day: string) {
  const weekDaysMap = zipObject(weekDayNames, getWeekDays());
  return weekDaysMap[day];
}

export function hourToDate(hour: string | null, format = TIME_FORMAT_LONG): Date | null {
  return hour ? parse(hour, format, new Date()) : null;
}

export function dateToHour(date: Date | null, format = TIME_FORMAT_SHORT): string | null {
  return date ? formatFns(date, format) : date;
}

export function longHourToShort(hour: string | null): string | null {
  return dateToHour(hourToDate(hour));
}

export function longDateToShort(date: string | null): string {
  return date ? formatFns(parse(date, DATE_INPUT_FORMAT, new Date()), DATE_DISPLAY_FORMAT) : '';
}

// get timestamp (in milliseconds since the Unix epoch)
export function getDateTimestamp(date: string, hour: number = 0, minute: number = 0): number {
  return getTime(setMinutes(setHours(parseISO(date), hour), minute));
}

export function getTimestampParts(timestamp: number): {
  date: string;
  hour: number;
  minute: number;
} {
  const dateObj = new Date(timestamp);

  return {
    date: formatFns(dateObj, DATE_INPUT_FORMAT),
    hour: getHours(dateObj),
    minute: getMinutes(dateObj),
  };
}

export function transformNumberToDoubleDigit(value: string | number | undefined) {
  return value !== undefined && value !== null ? value.toString().padStart(2, '0') : '';
}

export function formatDate(date: Date) {
  return formatFns(date, DATE_INPUT_FORMAT);
}
